import PropTypes from 'prop-types';
import extractBackgroundOverlays from '@activebrands/core-web/libs/storyblok/utils/extract-background-overlays';
import { transformButton } from '@activebrands/core-web/libs/storyblok/utils/transform-buttons';
import transformFlexibleBackgrounds from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds';
import transformFlexibleBackgroundsFactors from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds-factors';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import transformLayout from '@activebrands/core-web/libs/storyblok/utils/transform-layout';
import ShopTheLook from 'components/content-components/ShopTheLook';

const ShopTheLookDataTransformerBlock = ({
    content = {},
    isFourColumns = false,
    isThreeColumns = false,
    isTwoColumns = false,
}) => {
    const {
        anchorTag = [],
        backgroundDesktop = [],
        backgroundMobile = [],
        button = {},
        heading = [],
        tag = [],
        paragraph = [],
        productSelection = [],
        textColor,
    } = content;

    const backgroundOverlays = extractBackgroundOverlays([backgroundMobile[0], null, null, null, backgroundDesktop[0]]);

    const layout = transformLayout({ isTwoColumns, isThreeColumns, isFourColumns });

    const transformedBackgrounds = transformFlexibleBackgrounds(
        [backgroundMobile[0], null, null, null, backgroundDesktop[0]],
        transformFlexibleBackgroundsFactors(layout)
    );

    const transformedButton = button?.[0] ? transformButton(button?.[0]) : {};

    const headingSettings = transformHeading({
        type: heading?.[0]?.type,
        defaultType: 'h3',
    });

    return (
        <ShopTheLook
            background={transformedBackgrounds}
            backgroundOverlay={backgroundOverlays}
            buttonLabel={transformedButton?.label}
            buttonTheme={transformedButton?.theme}
            heading={heading?.[0]?.text}
            headingType={headingSettings.type}
            id={anchorTag?.[0]?.anchorTag}
            products={productSelection?.[0]?.products}
            tag={tag?.[0]?.tag}
            text={paragraph?.[0]?.richText}
            textColor={textColor}
        />
    );
};

ShopTheLookDataTransformerBlock.propTypes = {
    content: PropTypes.object.isRequired,
    isFourColumns: PropTypes.bool,
    isThreeColumns: PropTypes.bool,
    isTwoColumns: PropTypes.bool,
};

export default ShopTheLookDataTransformerBlock;
