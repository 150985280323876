import PropTypes from 'prop-types';
import useSiteQuery from 'queries/useSiteQuery';
import Image from '@activebrands/core-web/components/Image';
import AnimatedOverlay from '@activebrands/core-web/components/overlays/AnimatedOverlay';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import loadable from '@loadable/component';
import SiteSelectorButton from 'components/Footer/SiteSelectorButton';
import StateIcon from 'components/icons/StateIcon';
import Paragraph from 'components/text/Paragraph';

const SiteSelectorOverlay = loadable(() => import('components/overlays/SiteSelectorOverlay'));

const Wrapper = styled('div', {
    position: 'relative',
    padding: '0 20px 120px 20px',
    backgroundColor: 'var(--color-contrast-lowest)',

    [media.min['desktop.sm']]: {
        padding: '0 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative',
    },
});

const SiteSelectorRowMobile = styled('div', {
    display: 'flex',
    width: '100%',
    backgroundColor: 'var(--color-contrast-low)',
    alignItems: 'center',
    padding: '4px 12px',
    borderRadius: '78px',
    marginBottom: '8px',

    [media.min['tablet.sm']]: {
        display: 'none',
    },
});

const BottomRow = styled('div', {
    backgroundColor: 'var(--color-contrast-low)',
    width: '100%',
    padding: '4px 12px',
    borderRadius: '100px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',

    [media.min['tablet.sm']]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: '12px',
        padding: '0 24px',
        marginBottom: '28px',
    },
});

const SiteSelectorWrapperTablet = styled('div', {
    display: 'none',
    gridColumn: '6 / span 2',
    justifyContent: 'center',

    [media.min['tablet.sm']]: {
        display: 'flex',
        gridColumn: '6 / span 3',
    },

    [media.min['tablet.lg']]: {
        gridColumn: '6 / span 2',
    },
});

const PaymentWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginLeft: '24px',

    [media.min['tablet.sm']]: {
        gridColumn: '1 / span 3',
        justifyContent: 'unset',
        marginLeft: 'unset',
    },
});

const FooterBottom = ({ alternateHrefs = [], copywriteText }) => {
    const [css] = useStyletron();
    const year = new Date().getFullYear();
    const { paymentIcons } = useSiteQuery();

    return (
        <Wrapper>
            <SiteSelectorRowMobile>
                <SiteSelectorButton alternateHrefs={alternateHrefs} />
                <StateIcon size="12px" state="plus" />
            </SiteSelectorRowMobile>
            <AnimatedOverlay
                lockScroll
                $style={{
                    height: '100%',
                    width: ['var(--width-overlays)'],
                    left: '4px',
                    right: '4px',
                }}
                alternateHrefs={alternateHrefs}
                animation="slideLTR"
                component={SiteSelectorOverlay}
                id="site-selector"
            />
            <BottomRow>
                {paymentIcons?.length > 0 ? (
                    <PaymentWrapper>
                        {paymentIcons.map(({ iconUrl }, index) => (
                            <div
                                key={`${iconUrl}-${index}`}
                                className={css({
                                    height: '32px',
                                    margin: ['0, 12px', null, '0 24px 0 0'],
                                })}
                            >
                                <Image src={iconUrl} />
                            </div>
                        ))}
                    </PaymentWrapper>
                ) : null}
                <SiteSelectorWrapperTablet>
                    <div
                        className={css({
                            border: 'var(--color-border-site-selector) solid',
                            borderRadius: '78px',
                            margin: '6px 0',
                        })}
                    >
                        <SiteSelectorButton alternateHrefs={alternateHrefs} />
                    </div>
                </SiteSelectorWrapperTablet>
                {copywriteText && (
                    <Paragraph
                        $style={{
                            color: 'var(--color-text)',
                            display: ['none', 'none', 'none', 'flex'],
                            gridColumn: '11 / span 2',
                            justifyContent: 'flex-end',
                        }}
                        fontKeys={['Miscellaneous/12_100', null, null, 'Miscellaneous/14_100']}
                    >
                        © {year} {copywriteText}
                    </Paragraph>
                )}
            </BottomRow>
            {copywriteText && (
                <Paragraph
                    $style={{
                        color: 'var(--color-text)',
                        display: ['flex', null, null, 'none'],
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '42px',
                        padding: '0 8px',
                    }}
                    fontKeys={['Miscellaneous/12_100', null, null, 'Miscellaneous/14_100']}
                >
                    © {year} {copywriteText}
                </Paragraph>
            )}
        </Wrapper>
    );
};

FooterBottom.propTypes = {
    alternateHrefs: PropTypes.arrayOf,
    copywriteText: PropTypes.string,
};

export default FooterBottom;
