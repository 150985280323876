import extractBackgroundOverlay from '@activebrands/core-web/libs/storyblok/utils/extract-background-overlay';
import transformFlexibleBackground from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-background';
import { ArticleCard } from '@activebrands/core-web/types/article-card';

const transformArticleCardData = (item): ArticleCard => {
    const horizontalMedia = item.cardMediaHorizontal?.[0]
        ? transformFlexibleBackground(item.cardMediaHorizontal[0], { targetBreakpoint: 'mobile.sm' })
        : undefined;
    const horizontalOverlay = item.cardMediaHorizontal?.[0]
        ? extractBackgroundOverlay(item.cardMediaHorizontal[0])
        : undefined;

    const verticalMedia = item.cardMediaVertical?.[0]
        ? transformFlexibleBackground(item.cardMediaVertical[0], { targetBreakpoint: 'mobile.sm' })
        : undefined;
    const verticalOverlay = item.cardMediaVertical?.[0]
        ? extractBackgroundOverlay(item.cardMediaVertical[0])
        : undefined;

    return {
        categories: item.categories,
        cardHeadingLabel: item?.cardHeadingLabel,
        entryText: item.cardPreamble?.[0]?.richText,
        horizontalMedia,
        horizontalOverlay,
        id: item?.id,
        media: verticalMedia,
        publishedAt: item.publishedAt,
        quantity: item?.quantity,
        title: item.title,
        to: item.path,
        verticalMedia,
        verticalOverlay,
    };
};

export default transformArticleCardData;
