import { useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Image from '@activebrands/core-web/components/Image';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { setSiteSelectorCookie } from '@activebrands/core-web/utils/handle-site-selector-cookie';
import ArrowButton from 'components/buttons/ArrowButton';
import ThemeButton from 'components/buttons/ThemeButton';
import JohaugIcon from 'components/icons/JohaugIcon';
import StateIcon from 'components/icons/StateIcon';
import Checkbox from 'components/inputs/CheckboxInput';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', ({ $isOpen }) => ({
    position: 'absolute',
    top: '0',
    bottom: 'calc(var(--height-header) + 8px)',
    transform: $isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(-120%, 0, 0)',
    borderRadius: '8px',
    backgroundColor: 'var(--color-bg-secondary)',
    width: '100%',
    zIndex: 1,
    padding: '16px',
    transition: 'transform var(--transition-fast)',
    overflow: 'scroll',
    height: 'calc(100% - 8px)',

    // Hide scrollbar in safari and chrome
    '::-webkit-scrollbar': {
        display: 'none',
    },

    // Hide scrollbar in firefox
    scrollbarWidth: 'none',
}));

const OverlayHeading = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '8px',
    paddingRight: '6px',

    [media.min['desktop.sm']]: {
        display: 'none',
    },
});

const SiteSelector = ({
    $style,
    alternateHrefs = [],
    isFooter,
    isOverlay,
    isSiteSelectorOpen,
    setIsSiteSelectorOpen,
}) => {
    const [css] = useStyletron();

    const {
        siteSelectorExternalHeading,
        siteSelectorExternalHeadingType,
        siteSelectorExternalLinks,
        siteSelectorExternalParagraph,
        siteSelectorHeading,
        siteSelectorHeadingType,
        siteSelectorLinks,
        siteSelectorParagraph,
        siteSelectorRedirectLabel,
    } = useSiteSelectorQuery();

    const currentSiteId = useSelector(state => state.application.aliasId);
    const [isAlwaysRedirected, setIsAlwaysRedirected] = useState(true);

    const handleChange = () => {
        setIsAlwaysRedirected(!isAlwaysRedirected);
    };

    return (
        <Wrapper $isOpen={isOverlay ? true : isSiteSelectorOpen} $style={$style}>
            <div className={css({ display: 'flex', flexDirection: 'column', gap: '24px' })}>
                <OverlayHeading>
                    <ArrowButton
                        direction="left"
                        state="arrow-left"
                        onClick={() =>
                            isFooter ? overlay.close('site-selector') : setIsSiteSelectorOpen(!isSiteSelectorOpen)
                        }
                    >
                        <Paragraph fontKeys="Miscellaneous/14_100">{fm('Back')}</Paragraph>
                    </ArrowButton>
                    <JohaugIcon width="80px" />
                    <StateIcon
                        $style={{ display: ['block', null, null, null, null, 'none'] }}
                        size="16px"
                        state="reset"
                        onClick={() => (isFooter ? overlay.close('site-selector') : overlay.close('navigation'))}
                    />
                </OverlayHeading>
                {siteSelectorLinks?.length && (
                    <div className={css({ display: 'flex', flexDirection: 'column' })}>
                        {siteSelectorHeading && (
                            <Heading as={siteSelectorHeadingType} fontKeys="Primary/20-120_-1">
                                {siteSelectorHeading}
                            </Heading>
                        )}
                        {siteSelectorParagraph?.content?.length && (
                            <div className={css({ marginTop: '16px' })}>
                                <RichText data={siteSelectorParagraph} fontKeys="Secondary/16_130" />
                            </div>
                        )}
                        {siteSelectorRedirectLabel && (
                            <div className={css({ marginTop: '16px', ...textStyles['Miscellaneous/12_100'] })}>
                                <Checkbox
                                    checked={isAlwaysRedirected}
                                    label={siteSelectorRedirectLabel}
                                    onChange={handleChange}
                                />
                            </div>
                        )}

                        <div
                            className={css({ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '16px' })}
                        >
                            {siteSelectorLinks.map((site, index) => (
                                <ThemeButton
                                    $style={{ width: '100%', paddingLeft: '8px', paddingRight: '14px' }}
                                    as="a"
                                    href={
                                        alternateHrefs.find(href => href.aliasId === Number(site.id))?.uri || site.path
                                    }
                                    key={`${site.countryCode}-${index}`}
                                    theme={site.id === currentSiteId ? 'inverted' : 'default'}
                                    onClick={() => (!!isAlwaysRedirected ? setSiteSelectorCookie(site.path) : null)}
                                >
                                    <div
                                        className={css({
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: 'auto',
                                            alignSelf: 'center',
                                            gridColumn: '1 / span 2',
                                        })}
                                    >
                                        {site.icon && (
                                            <div
                                                className={css({
                                                    marginRight: '16px',
                                                    borderRadius: '60px',
                                                    width: '24px',
                                                    height: '24px',
                                                    overflow: 'hidden',
                                                })}
                                                src={site.icon}
                                            >
                                                <Image
                                                    sizes={['24px']}
                                                    src={{ url: site?.icon, width: [24, 48, 72] }}
                                                />
                                            </div>
                                        )}
                                        <Paragraph
                                            className={css({ textAlign: 'left' })}
                                            fontKeys="Miscellaneous/16_100_-1"
                                        >
                                            {site.label}
                                        </Paragraph>
                                    </div>
                                </ThemeButton>
                            ))}
                        </div>
                    </div>
                )}
                {siteSelectorExternalLinks?.length ? (
                    <div className={css({ display: 'flex', flexDirection: 'column', marginTop: '24px' })}>
                        {siteSelectorExternalHeading && (
                            <Heading
                                $style={{ marginTop: '16px' }}
                                as={siteSelectorExternalHeadingType}
                                fontKeys="Primary/20-120_-1"
                            >
                                {siteSelectorExternalHeading}
                            </Heading>
                        )}
                        {siteSelectorExternalParagraph?.content?.length && (
                            <div className={css({ marginTop: '16px' })}>
                                <RichText data={siteSelectorExternalParagraph} fontKeys="Secondary/16_130" />
                            </div>
                        )}

                        <div
                            className={css({ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '16px' })}
                        >
                            {siteSelectorExternalLinks.map((site, index) => (
                                <ThemeButton
                                    $style={{ width: '100%', paddingLeft: '8px', paddingRight: '14px' }}
                                    as="a"
                                    href={site.path}
                                    key={`${site.countryCode}-${index}`}
                                    theme={site.id === currentSiteId ? 'inverted' : 'default'}
                                >
                                    <div
                                        className={css({
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: 'auto',
                                            alignSelf: 'center',
                                            gridColumn: '1 / span 2',
                                        })}
                                    >
                                        {site.icon && (
                                            <div
                                                className={css({
                                                    marginRight: '16px',
                                                    borderRadius: '60px',
                                                    width: '24px',
                                                    height: '24px',
                                                    overflow: 'hidden',
                                                })}
                                                src={site.icon}
                                            >
                                                <Image
                                                    sizes={['24px']}
                                                    src={{ url: site?.icon, width: [24, 48, 72] }}
                                                />
                                            </div>
                                        )}
                                        <Paragraph
                                            className={css({ textAlign: 'left' })}
                                            fontKeys="Miscellaneous/16_100_-1"
                                        >
                                            {site.label}
                                        </Paragraph>
                                    </div>
                                </ThemeButton>
                            ))}
                        </div>
                    </div>
                ) : null}
            </div>
        </Wrapper>
    );
};

SiteSelector.propTypes = {
    $style: PropTypes.object,
    alternateHrefs: PropTypes.arrayOf(
        PropTypes.exact({
            aliasId: PropTypes.number,
            app: PropTypes.string,
            appId: PropTypes.number,
            hrefLang: PropTypes.string,
            locale: PropTypes.string,
            uri: PropTypes.string,
        })
    ),
    isFooter: PropTypes.bool,
    isOverlay: PropTypes.bool,
    isSiteSelectorOpen: PropTypes.bool,
    setIsSiteSelectorOpen: PropTypes.func,
};

export default SiteSelector;
