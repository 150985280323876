import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import ArrowButton from 'components/buttons/ArrowButton';

const ButtonsList = ({
    $style = {},
    buttons = [],
    buttonStyling = {},
    fontKeys = ['Miscellaneous/18_100_-1', null, null, null, null, 'Miscellaneous/24_100_-2'],
}) => {
    const [css] = useStyletron();

    if (!buttons.length) {
        return null;
    }

    return (
        <div className={css({ display: 'flex', gap: '8px', ...$style })}>
            {buttons.map((button, index) => (
                <ArrowButton
                    isVolatile
                    $style={{ textDecoration: 'none', ...buttonStyling }}
                    as={Link}
                    fontKeys={fontKeys}
                    key={`${button.label}-${index}`}
                    rel={button.rel}
                    theme={button.theme}
                    to={button?.url}
                >
                    {button.label}
                </ArrowButton>
            ))}
        </div>
    );
};

ButtonsList.propTypes = {
    $style: PropTypes.object,
    buttonStyling: PropTypes.object,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
            rel: PropTypes.string,
        })
    ).isRequired,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default ButtonsList;
