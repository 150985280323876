import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import media from '@activebrands/core-web/config/media';
import useEventListener from '@activebrands/core-web/hooks/useEventListener';
import useNotifications from '@activebrands/core-web/hooks/useNotifications';
import Events from '@activebrands/core-web/libs/Events';
import { SearchEvents } from '@activebrands/core-web/libs/algolia/searchInsights';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import { addBasketItem } from '@activebrands/core-web/state/basket/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import { logger } from '@activebrands/core-web/utils/logger';
import ThemeButton from 'components/buttons/ThemeButton';
import AddToBasketIcon from 'components/icons/AddToBasketIcon';
import AddedToBasket from 'components/notifications/AddedToBasket';

const QuickshopFooter = styled('div', {
    display: 'none',

    [media.min['tablet.sm']]: {
        borderRadius: '0 0 4px 4px',
        paddingTop: '12px',
        display: 'flex',
        gap: '4px',
        flexWrap: 'wrap',
    },
});

const QuickshopMiniDesktop = ({
    $style = {},
    addedToBasketAlgoliaData,
    addedToBasketData,
    setButtonState,
    setIsLoading,
    setIsQuickshopOpen,
    variations = [],
}) => {
    const quickshopRef = useRef();
    const dispatch = useTypedDispatch();
    const listener = useEventListener('window');
    const notifications = useNotifications();

    // Close on click if element isn't button or overlay child.
    useEffect(() => {
        let handler = null;

        if (setIsQuickshopOpen) {
            handler = listener.subscribe('click', e => {
                const overlay = quickshopRef.current;
                e.stopPropagation();

                if (overlay && e.target !== overlay && overlay.contains(e.target)) {
                    return;
                }

                setIsQuickshopOpen(false);
            });
        }

        return () => handler && listener.unsubscribe('click', handler);
    }, []);

    const handleAddToBasket = async (e, variation) => {
        e.preventDefault();
        setButtonState('loading');
        setIsLoading(true);

        try {
            // TODO: Control tracking and functionality works when related products and search works again
            await dispatch(addBasketItem(variation.id, variation.trackingMeta));
            setButtonState('success');

            notifications.push(AddedToBasket, {
                ...addedToBasketData,
                size: variations.filter(item => item.id === variation.id)?.[0]?.name || '',
            });

            if (addedToBasketAlgoliaData) {
                Events.trigger(SearchEvents.PRODUCT_ADDED, addedToBasketAlgoliaData);
            }
        } catch (error) {
            logger.error(error);
            setButtonState('error');
        } finally {
            setIsLoading(false);
            setTimeout(() => setButtonState('default'), 2800);
        }
    };

    const handleQuickShop = (e, variation) => {
        if (variation.inStock) {
            handleAddToBasket(e, variation);
        } else {
            overlay.open('notify-me', { id: variation.id });
        }
    };

    return (
        <QuickshopFooter $style={$style}>
            {variations.map(variation => (
                <ThemeButton
                    $style={{
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: '70px',
                        justifyContent: 'space-between',

                        ':disabled': {
                            textDecoration: 'line-through',
                        },
                    }}
                    disabled={!variation.inStock}
                    fontKeys="Miscellaneous/12_100"
                    key={variation.name}
                    onClick={e => handleQuickShop(e, variation)}
                >
                    {variation.name}
                    <AddToBasketIcon width="14px" />
                </ThemeButton>
            ))}
        </QuickshopFooter>
    );
};

QuickshopMiniDesktop.propTypes = {
    $style: PropTypes.object,
    addedToBasketAlgoliaData: PropTypes.object,
    addedToBasketData: PropTypes.shape({
        imageSrc: PropTypes.string,
        link: PropTypes.string,
        name: PropTypes.string,
        priceWithCurrency: PropTypes.string,
        salePriceWithCurrency: PropTypes.string,
    }),
    setButtonState: PropTypes.func,
    setIsLoading: PropTypes.func,
    setIsQuickshopOpen: PropTypes.func,
    variations: PropTypes.array,
};

export default QuickshopMiniDesktop;
