import PropTypes from 'prop-types';
import extractBackgroundOverlays from '@activebrands/core-web/libs/storyblok/utils/extract-background-overlays';
import transformFlexibleBackgrounds from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import transformLayout from '@activebrands/core-web/libs/storyblok/utils/transform-layout';
import Newsletter from 'components/content-components/Newsletter';

const NewsletterDataTransformerBlock = ({
    content = {},
    isFourColumns = false,
    isPopup = false,
    isThreeColumns = false,
    isTwoColumns = false,
}) => {
    const {
        anchorTag = [],
        backgroundDesktop = [],
        backgroundMobile = [],
        newsletter = [],
        heading = [],
        paragraph = [],
        tag = [],
        textColor = '',
    } = content;

    const backgroundOverlays = extractBackgroundOverlays([backgroundMobile[0], null, null, null, backgroundDesktop[0]]);

    const transformedBackgrounds = transformFlexibleBackgrounds(
        [backgroundMobile[0], null, null, null, backgroundDesktop[0]],
        1
    );

    const headingSettings = transformHeading({
        type: heading?.[0]?.type,
        defaultType: isFourColumns || isPopup || isThreeColumns || isTwoColumns ? 'h4' : 'h3',
    });

    const layout = transformLayout({ isTwoColumns, isThreeColumns, isFourColumns });

    return (
        <Newsletter
            background={transformedBackgrounds}
            backgroundLoading="eager"
            backgroundOverlay={backgroundOverlays}
            contentLayout={layout === 'oneColumn' && !isPopup ? 'default' : 'vertical'}
            heading={heading?.[0]?.text}
            headingType={headingSettings.type}
            id={anchorTag?.[0]?.anchorTag}
            isPopup={isPopup}
            newsletter={newsletter?.[0]}
            paragraph={paragraph?.[0]?.richText}
            tag={tag?.[0]?.tag}
            textColor={textColor}
        />
    );
};

NewsletterDataTransformerBlock.propTypes = {
    content: PropTypes.object.isRequired,
    isFourColumns: PropTypes.bool,
    isPopup: PropTypes.object,
    isThreeColumns: PropTypes.bool,
    isTwoColumns: PropTypes.bool,
};

export default NewsletterDataTransformerBlock;
