import PropTypes from 'prop-types';
import withSlides from '@activebrands/core-web/libs/slider/withSlides';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import asArray from '@grebban/utils/array/asArray';

const Slides = withSlides(
    styled('div', {
        width: '100%',
        display: 'flex',
        scrollSnapType: 'x mandatory',
        userSelect: 'none',
        '-webkit-touch-callout': 'none',
        '-webkit-tap-highlight-color': 'transparent',

        '--is-touch-device': 'false',
        '@media (hover: none) and (pointer: coarse)': {
            '--is-touch-device': 'true',
        },

        // Remove scrollbar
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        '::-webkit-scrollbar': {
            display: 'none',
        },
    })
);

const SlideWrapper = styled('div', {
    height: '100%',
});

const Slider = ({
    $style,
    allSlideItemsStyle = {},
    bundleUpdateIndex,
    children,
    isDraggable,
    isInfinite,
    slider,
    slidesPerView,
    snapAlign,
    spaceBetween,
    ...rest
}) => {
    const [css] = useStyletron();

    slidesPerView = asArray(slidesPerView);
    spaceBetween = asArray(spaceBetween);
    snapAlign = asArray(snapAlign);

    const wrapperStyle = css({
        '--spv': slidesPerView,
        '--sb': spaceBetween,
        '--infinite': asArray(isInfinite).map(x => (x === false ? 'false' : x === true ? 'true' : x)),
        '--snap-align': snapAlign,
        overflow: 'hidden',
        margin: spaceBetween && ['0 0 0 calc(var(--sb) * -1)', null, null, '0 calc((var(--sb) * -1) / 2)'],
        height: '100%',
    });

    //@todo: add support for scrollsnapalign on infinite sliders in mobile
    const allSlidesStyle = {
        position: 'relative',
        scrollSnapAlign: 'var(--snap-align)',
        minWidth: 'calc(100% / var(--spv))',
        padding: spaceBetween && ['0 0 0 var(--sb)', null, null, '0 calc(var(--sb) / 2)'],
        flexGrow: '0',
        flexShrink: '0',
        flexBasis: '0%',
        ...allSlideItemsStyle,
    };

    return (
        <div className={wrapperStyle}>
            <Slides
                {...rest}
                $style={{
                    ...$style,
                    overflowX: asArray(isDraggable).map(x => (x === false ? 'hidden' : x === true ? 'scroll' : x)),
                }}
                bundleUpdateIndex={bundleUpdateIndex}
                slider={slider}
            >
                {children.map((child, i) => (
                    <SlideWrapper $style={allSlidesStyle} key={i}>
                        {child}
                    </SlideWrapper>
                ))}
            </Slides>
        </div>
    );
};

Slider.propTypes = {
    $style: PropTypes.object,
    allSlideItemsStyle: PropTypes.object,
    bundleUpdateIndex: PropTypes.func,
    children: PropTypes.node,
    customSlideStyle: PropTypes.object,
    isDraggable: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.bool), PropTypes.bool]),
    isInfinite: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.bool), PropTypes.bool]),
    slider: PropTypes.object.isRequired,
    slidesPerView: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
    snapAlign: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOf(['start', 'center', 'end'])),
        PropTypes.oneOf(['start', 'center', 'end', 'none']),
    ]),
    spaceBetween: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

Slider.defaultProps = {
    $style: {},
    isDraggable: false,
    isInfinite: false,
    snapAlign: 'start',
    spaceBetween: '0px',
};

export default Slider;
