import { Component } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import { logger } from '@activebrands/core-web/utils/logger';

class ErrorCatcher extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
        });

        logger.warn(error);
        logger.warn(info);
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            return <p>{fm('Something went wrong...')}</p>;
        }

        return children;
    }
}

export default ErrorCatcher;
