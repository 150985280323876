// @TODO FontWeights should depend on the fonts
export const fontWeights = [200, 300, 400, 500, 600, 700, 800, 900];

// @TODO Style should depends on the font
// https://stackoverflow.com/questions/56949513/typescript-type-of-a-property-dependent-on-another-property-within-the-same-obj
export interface TextStyle {
    fontFamily: string;
    fontSize: number;
    fontStyle: string;
    fontWeight?: string | number;
    letterSpacing: string;
    lineHeight: string;
    textDecoration?: string;
    textTransform: string;
}

const textStyles: Record<string, TextStyle> = {
    // Primary font styles
    'Primary/96_100_-3': {
        fontFamily: 'Axiforma Book',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 96,
        letterSpacing: '-3%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/72_100_-3': {
        fontFamily: 'Axiforma Book',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 72,
        letterSpacing: '-3%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/60_100_-3': {
        fontFamily: 'Axiforma Book',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 60,
        letterSpacing: '-3%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/48_100_-3': {
        fontFamily: 'Axiforma Book',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 48,
        letterSpacing: '-3%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/40_100_-2': {
        fontFamily: 'Axiforma Book',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 40,
        letterSpacing: '-2%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/32_110_-2': {
        fontFamily: 'Axiforma Book',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 32,
        letterSpacing: '-2%',
        lineHeight: '110%',
        textTransform: 'none',
    },
    'Primary/24_120_-2': {
        fontFamily: 'Axiforma Book',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 24,
        letterSpacing: '-2%',
        lineHeight: '120%',
        textTransform: 'none',
    },
    'Primary/20-120_-1': {
        fontFamily: 'Axiforma Book',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 20,
        letterSpacing: '-1%',
        lineHeight: '120%',
        textTransform: 'none',
    },
    'Primary/18_120_-1': {
        fontFamily: 'Axiforma Book',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 18,
        letterSpacing: '-1%',
        lineHeight: '120%',
        textTransform: 'none',
    },
    'Primary/16_120_-1': {
        fontFamily: 'Axiforma Book',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-1%',
        lineHeight: '120%',
        textTransform: 'none',
    },

    // Secondary font styles
    'Secondary/18_140': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 18,
        letterSpacing: 'normal',
        lineHeight: '140%',
        textTransform: 'none',
    },
    'Secondary/16_130': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: 'normal',
        lineHeight: '130%',
        textTransform: 'none',
    },
    'Secondary/14_130': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: 'normal',
        lineHeight: '130%',
        textTransform: 'none',
    },
    'Secondary/14_130_500': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: 'normal',
        lineHeight: '130%',
        textTransform: 'none',
    },
    'Secondary/12_130': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 12,
        letterSpacing: 'normal',
        lineHeight: '130%',
        textTransform: 'none',
    },
    'Secondary/12_100': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 12,
        letterSpacing: 'normal',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Secondary/10_120': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 10,
        letterSpacing: 'normal',
        lineHeight: '120%',
        textTransform: 'none',
    },

    // Misc font styles
    'Miscellaneous/32_100_-3': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 32,
        letterSpacing: '-3%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Miscellaneous/24_100_-2': {
        fontFamily: 'Helvetica Now Display',
        fontStyle: 'normal',
        fontSize: 24,
        fontWeight: 400,
        letterSpacing: '-2%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Miscellaneous/20_120-2_500': {
        fontFamily: 'Helvetica Now Display',
        fontStyle: 'normal',
        fontSize: 20,
        fontWeight: 500,
        letterSpacing: '-2%',
        lineHeight: '120%',
        textTransform: 'none',
    },
    'Miscellaneous/18_140': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 18,
        letterSpacing: 'normal',
        lineHeight: '140%',
        textTransform: 'none',
    },
    'Miscellaneous/18_100_-1': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 18,
        letterSpacing: '-1%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Miscellaneous/16_100_-1': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-1%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Miscellaneous/16_120_-1_500': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-1%',
        lineHeight: '120%',
        textTransform: 'none',
    },
    'Miscellaneous/14_100_LT': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: 'normal',
        lineHeight: '100%',
        textTransform: 'none',
        textDecoration: 'line-through',
    },
    'Miscellaneous/14_100_500': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: 'normal',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Miscellaneous/14_100': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: 'normal',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Miscellaneous/12_100_LT': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 12,
        letterSpacing: 'normal',
        lineHeight: '100%',
        textTransform: 'none',
        textDecoration: 'line-through',
    },
    'Miscellaneous/12_100_500': {
        fontFamily: 'Helvetica Now Display',
        fontStyle: 'normal',
        fontSize: 12,
        letterSpacing: 'normal',
        lineHeight: '100%',
        fontWeight: 500,
        textTransform: 'none',
    },
    'Miscellaneous/12_100': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 12,
        letterSpacing: 'normal',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Miscellaneous/10_100_500': {
        fontFamily: 'Helvetica Now Display',
        fontStyle: 'normal',
        fontSize: 10,
        letterSpacing: 'normal',
        lineHeight: '100%',
        fontWeight: 500,
        textTransform: 'none',
    },
    'Miscellaneous/10_100': {
        fontFamily: 'Helvetica Now Display',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 10,
        letterSpacing: 'normal',
        lineHeight: '100%',
        textTransform: 'none',
    },
};

export default textStyles;
