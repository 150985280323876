import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const JohaugIcon = ({
    $style = {},
    color = 'currentColor',
    height = 'auto',
    viewBox = '0 0 162 48',
    width = '162px',
    ...rest
}) => {
    const style = { width, height, ...$style };

    return (
        <SVG {...rest} $style={style} fill={color} viewBox={viewBox}>
            <path d="M10.263 0c-1.79 0-3.067 1.283-3.067 3.08 0 1.797 1.278 3.08 3.067 3.08 1.79 0 3.068-1.283 3.068-3.08 0-1.797-1.278-3.08-3.068-3.08Zm116.411 25.818c0 3.506-2.379 6.015-5.608 6.06l-.707.004c-3.657 0-5.755-2.56-5.755-6.064V11.665h-5.035v14.153c0 6.571 3.833 10.341 9.818 10.615.635.03 1.755.028 2.502 0 5.852-.212 9.818-4.044 9.818-10.615V11.665h-5.035v14.153h.002Zm-108.247-2.1c0-6.824 5.79-12.637 12.586-12.637 6.797 0 12.587 5.813 12.587 12.637 0 6.824-5.79 12.637-12.587 12.637-6.796 0-12.586-5.813-12.586-12.637Zm5.035-.002c0 4.55 3.524 8.088 7.551 8.088 4.028 0 7.552-3.538 7.552-8.088 0-4.549-3.524-8.087-7.552-8.087-4.027 0-7.552 3.538-7.552 8.087Zm38.513-12.635c-4.53 0-7.3 3.033-7.3 3.033V.466h-5.034v35.381h5.034V22.705c0-4.549 2.518-7.076 6.294-7.076 3.272 0 5.79 2.527 5.79 6.065v14.153h5.034V21.694c0-6.57-4.028-10.615-9.818-10.615v.002Zm28.028 0c4.783 0 7.803 3.286 7.803 3.286v-2.802h5.035V35.85h-5.035v-2.78s-3.02 3.286-7.803 3.286c-6.293 0-11.831-5.56-11.831-12.637 0-7.077 5.538-12.637 11.83-12.637Zm-6.797 12.637c0 4.55 3.524 8.087 7.3 8.087s7.3-3.538 7.3-8.087c0-4.55-3.524-8.088-7.3-8.088s-7.3 3.539-7.3 8.088ZM156 11.68v2.638l-.053-.204s-2.769-3.033-7.3-3.033c-6.042 0-11.328 5.308-11.328 12.131 0 6.824 5.286 12.132 11.328 12.132 4.357 0 7.058-2.779 7.261-2.994-.455 6.506-3.714 10.186-10.219 10.52-.326.017-1.015.025-1.359.025V48l1.455-.02c9.629-.412 15.304-6.427 15.304-16.938V11.68h-5.089Zm-6.85 19.114c-3.524 0-6.796-3.285-6.796-7.582 0-4.296 3.272-7.582 6.796-7.582 3.524 0 6.797 3.286 6.797 7.582 0 4.297-3.273 7.582-6.797 7.582ZM.322 42.358c5.03-1.28 7.404-4.184 7.404-11.688V11.585h4.996v19.363c0 9.442-3.768 15.398-12.4 16.546v-5.137Z" />
        </SVG>
    );
};

JohaugIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default JohaugIcon;
