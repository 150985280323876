import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const AddToBasketIcon = ({ color = 'currentColor', width = '16px', height = 'auto', $style = {}, ...rest }) => {
    const style = { width, height, ...$style };
    return (
        <SVG $style={style} fill="none" viewBox="0 0 16 16" {...rest}>
            <path
                d="M1.464 3h13.072l.806 10.89a1.5 1.5 0 0 1-1.496 1.61H2.154a1.5 1.5 0 0 1-1.496-1.61L1.464 3ZM5 3V.5h6V3M8 5.5v8M12 9.5H4"
                stroke={color}
            />
        </SVG>
    );
};

AddToBasketIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default AddToBasketIcon;
