import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import { styled } from '@activebrands/core-web/libs/styletron';

const STORYBLOK_URL_SOURCE_PREFIX = process.env.REACT_APP_STORYBLOK_URL_SOURCE_PREFIX;
const STORYBLOK_URL_TARGET_PREFIX = '';

/**
 * Links inside the RichText-fields will need to be transformed to match the data provided to base/Link
 */

const StyledLink = styled(Link, {
    textDecoration: 'underline',
});

const LinkResolver = ({
    children = undefined,
    anchor = undefined,
    href = undefined,
    linktype = undefined,
    ...rest
}) => {
    const hash = anchor ? `#${anchor}` : '';
    let to;

    switch (linktype) {
        case 'story': {
            let url = href.replace(STORYBLOK_URL_SOURCE_PREFIX, STORYBLOK_URL_TARGET_PREFIX);
            url = url.replace('//', '/');
            to = `${url}${hash}`;
            break;
        }
        case 'email':
            to = `mailto:${href}`;
            break;

        case 'asset':
        case 'url':
            to = `${href}${hash}`;
            break;

        default:
            break;
    }

    return (
        <StyledLink isVolatile to={to} {...rest}>
            {children}
        </StyledLink>
    );
};

LinkResolver.propTypes = {
    anchor: PropTypes.string,
    children: PropTypes.node,
    href: PropTypes.string,
    linktype: PropTypes.string,
    uuid: PropTypes.string,
};

export default LinkResolver;
