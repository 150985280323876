import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const SearchIcon = ({ $style = {}, color = 'currentColor', height = 'auto', width = '16px', ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox="0 0 24 24" {...rest}>
            <path
                d="m23 23-5.202-5.195c-.39-.39-1.02-.385-1.449-.038a9.39 9.39 0 0 1-5.92 2.09 9.429 9.429 0 1 1 9.063-6.82"
                stroke={color}
                strokeLinecap="square"
            />
        </SVG>
    );
};

SearchIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default SearchIcon;
