import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import { styled } from '@activebrands/core-web/libs/styletron';

const Wrapper = styled('div', {
    color: 'var(--color-text-badge)',
    backgroundColor: 'var(--color-bg-badge-brand-primary)',
    padding: '3px 6px 4px',
    borderRadius: '999999px',
    minWidth: 'fit-content',
});

const ArticleTag = ({ children }) => <Wrapper $style={textStyles['Miscellaneous/14_100']}>{children}</Wrapper>;

ArticleTag.propTypes = {
    children: PropTypes.string,
};

export default ArticleTag;
