import { useDispatch } from 'react-redux';
import fm from 'format-message';
import { styled } from '@activebrands/core-web/libs/styletron';
import { removeBasketItem, updateBasketLineQuantity } from '@activebrands/core-web/state/basket/actions';
import { useTypedSelector } from '@activebrands/core-web/state/store';
import RemoveIcon from 'components/icons/RemoveIcon';
import AmountIncrementer from 'components/inputs/AmountIncrementer';
import Paragraph from 'components/text/Paragraph';

export const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});
const Remove = styled('button', {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    color: 'var(--color-text-subtle)',
});

interface BasketCardEndProps {
    $style?: object;
    line: string;
    quantity: number;
    setIsLoading: (isLoading: boolean) => void;
}

const BasketCardEnd = ({ $style, line, quantity, setIsLoading }: BasketCardEndProps) => {
    const dispatch = useDispatch();
    const items = useTypedSelector(state => state.basket.items);
    const product: any = items.find(item => item.line === line);

    const trackingMeta = {
        brandName: product.brandName,
        ean: product.ean,
        item: product.id,
        productName: product.productName,
        priceAsNumber: product.priceEachAsNumber,
        quantity: quantity,
        sku: product.sku,
        size: product.size,
        parentId: product.parentId,
        silkVariant: product.variationId,
    };

    const updateQuantity = quantity => {
        setIsLoading(true);
        dispatch(updateBasketLineQuantity(line, trackingMeta, quantity, setIsLoading));
    };

    const removeItem = () => {
        setIsLoading(true);
        dispatch(removeBasketItem(line, trackingMeta, quantity));
    };

    return (
        <Wrapper $style={{ alignItems: 'flex-end', ...$style }}>
            <Remove type="button" onClick={removeItem}>
                <Paragraph fontKeys="Miscellaneous/12_100">{fm('Remove')}</Paragraph>
                <RemoveIcon width="14px" />
            </Remove>
            <AmountIncrementer count={quantity} onChange={quantity => updateQuantity(quantity)} />
        </Wrapper>
    );
};

export default BasketCardEnd;
