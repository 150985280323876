import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const RemoveIcon = ({ $style = {}, color = 'currentColor', height = 'auto', width = '16px', ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox="0 0 12 12" {...rest}>
            <path
                d="M3.5 1A.5.5 0 0 1 4 .5h4a.5.5 0 0 1 .5.5v1.5h-5V1ZM1.5 2.5h9V10A1.5 1.5 0 0 1 9 11.5H3A1.5 1.5 0 0 1 1.5 10V2.5ZM4 5v4M6 5v4M8 5v4M0 2.5h12"
                stroke={color}
            />
        </SVG>
    );
};

RemoveIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default RemoveIcon;
