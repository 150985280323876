import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import ColumnsLayout from '@activebrands/core-web/components/content-layouts/ColumnsLayout';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import ArticleCard from 'components/ArticleCard';
import PromotionalHeader from 'components/PromotionalHeader';
import ArrowButton from 'components/buttons/ArrowButton';
import ProductCard from 'components/product/ProductCard';

const PromotionGrid = ({
    $style = {},
    button = {},
    columnSizes,
    heading,
    headingFontKeys,
    headingType,
    id,
    imageSettings,
    items,
    tag,
    text,
    type,
}) => {
    const [css] = useStyletron();

    return (
        <div className={css($style)}>
            <PromotionalHeader
                $paginationStyle={{ display: 'none' }}
                $style={{ display: ['flex', null, null, null, null, 'none'] }}
                button={button}
                heading={heading}
                headingFontKeys={headingFontKeys}
                headingType={headingType}
                id={id}
                tag={tag}
                text={text}
                type={type}
            />
            <ColumnsLayout
                $style={{ display: ['none', null, null, null, null, 'flex'] }}
                columnSizes={columnSizes}
                gutterHeight={40}
                gutterWidth={[12, null, null, null, 16]}
                items={[
                    <PromotionalHeader
                        $paginationStyle={{ display: 'none' }}
                        button={button}
                        heading={heading}
                        headingFontKeys={headingFontKeys}
                        headingType={headingType}
                        key="header"
                        tag={tag}
                        text={text}
                        type={type}
                    />,
                ].concat(
                    items.map((item, i) => {
                        if (type === 'product') {
                            return (
                                <ProductCard
                                    {...item}
                                    media={item.media?.length > 0 ? item.media.slice(0, 1) : []}
                                    imageSettings={imageSettings}
                                    key={item.id}
                                    loading="lazy"
                                    position={i}
                                />
                            );
                        }
                        if (type === 'article') {
                            return (
                                <ArticleCard
                                    {...item}
                                    imageSettings={imageSettings}
                                    key={item.id}
                                    loading="lazy"
                                    position={i}
                                />
                            );
                        }

                        return null;
                    })
                )}
            />
            <ColumnsLayout
                $style={{ display: ['flex', null, null, null, null, 'none'] }}
                columnSizes={columnSizes}
                gutterHeight={40}
                gutterWidth={[12, null, null, null, 16]}
                id={id}
                items={items.map((item, i) => {
                    if (type === 'product') {
                        return (
                            <ProductCard
                                {...item}
                                media={item.media?.length > 0 ? item.media.slice(0, 1) : []}
                                imageSettings={imageSettings}
                                key={item.id}
                                loading="lazy"
                                position={i}
                            />
                        );
                    }
                    if (type === 'article') {
                        return (
                            <ArticleCard
                                {...item}
                                imageSettings={imageSettings}
                                key={item.id}
                                loading="lazy"
                                position={i}
                            />
                        );
                    }

                    return null;
                })}
            />
            {button.label && button.url && (
                <ArrowButton
                    $style={{ marginTop: '24px', width: ['100%', null, 'auto'], display: ['flex', null, 'none'] }}
                    as={Link}
                    rel={button.rel}
                    theme={button.theme}
                    to={button.url}
                >
                    {button.label}
                </ArrowButton>
            )}
        </div>
    );
};
PromotionGrid.propTypes = {
    $style: PropTypes.object,
    button: PropTypes.object,
    columnSizes: PropTypes.array,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    imageSettings: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }).isRequired,
    items: PropTypes.array,
    tag: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.oneOf(['product', 'article']),
};

export default PromotionGrid;
