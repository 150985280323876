import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import errorMessages from '@activebrands/core-web/utils/auth/error-messages';
import generateRandomString from '@grebban/utils/string/generateRandomString';
import InputErrors from 'components/Form/InputErrors';

const Input = styled('input', ({ $backgroundColor }) => ({
    height: '1px',
    width: '1px',
    opacity: '0',
    position: 'absolute',

    ':checked + label:before': {
        background: 'var(--color-text)',
        border: `1px solid var(--color-border)`,
    },

    ':checked + label:after': {
        content: '""',
    },

    ':focus + label:before': {
        background: `rgba(0,0,0,0.1) !important`,
    },
}));

const Label = styled('label', ({ $required }) => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',

    ':before': {
        position: 'relative',
        display: 'inline-block',
        flexShrink: 0,
        flexGrow: 0,
        content: '""',
        width: '14px',
        height: '14px',
        background: 'transparent',
        marginRight: '8px',
        transform: 'translateY(-1px)', // Adjust so it looks aligned due to. line height
        borderRadius: '2px',
        border: `1px solid var(--color-border)`,
    },

    ':after': {
        // This will add a checkmark when selected
        // content: '""', // Add when checked
        position: 'absolute',
        left: '2px',
        width: '9px',
        height: '4px',
        borderLeft: `1px solid white`,
        borderBottom: `1px solid white`,
        transform: 'rotate(-45deg) translate(2px, -1px)',
    },
}));

const themes = {
    default: {
        styles: {
            border: `1px solid var(--color-border)`,
        },
        checkedStyles: {
            background: 'var(--color-bg-inverted)',
            border: `1px solid var(--color-border)`,
        },
        checkMarkStyles: {
            borderLeft: `1px solid var(--color-border-inverted)`,
            borderBottom: `1px solid var(--color-border-inverted)`,
        },
    },
    inverted: {
        styles: {
            border: `1px solid var(--color-border-inverted)`,
        },
        checkedStyles: {
            background: 'var(--color-bg)',
            border: `1px solid var(--color-border-inverted)`,
        },
        checkMarkStyles: {
            borderLeft: `1px solid var(--color-border)`,
            borderBottom: `1px solid var(--color-border)`,
        },
    },
};

/**
 * @param {object} [$style] -  CSS style object.
 * @param {array} [errors] -  Potential errors from submit.
 * @param {object} [labelStyle] -  CSS style object for the label.
 * @param {string} [id] - Id for hidden input, auto generated if not set.
 * @param {string} [label] - Label for the checkbox.
 */

const Checkbox = ({
    $checkboxLabelStyle = {},
    $checkboxStyle = {},
    $style = {},
    backgroundColor = 'var(--color-bg)',
    checkBoxStyle = {},
    defaultValue = false,
    errorMessageStyle = {},
    errors = [],
    id,
    label = '',
    onChange = () => null,
    required = false,
    theme = 'default',
    ...rest
}) => {
    const [css] = useStyletron();
    const [checked, setChecked] = useState(defaultValue);
    const internalId = useMemo(() => id || generateRandomString(7), [id]);

    useEffect(() => {
        if (checked !== defaultValue) {
            setChecked(!checked);
        }
    }, [defaultValue]);

    const { styles, checkedStyles, checkMarkStyles } = themes[theme];

    let errorMessage = '';

    if (errors.length > 0) {
        errorMessage = errorMessages[errors[0]] ? errors[0] : 'notChecked';
    }

    return (
        <div className={css({ position: 'relative', ...$style })}>
            <Input
                $backgroundColor={backgroundColor}
                $style={{
                    ':checked + label:before': checkedStyles,
                    ':checked + label:after': checkMarkStyles,
                    ...checkBoxStyle,
                }}
                checked={checked}
                id={internalId}
                required={required}
                type="checkbox"
                onChange={e => {
                    onChange(e);
                    setChecked(e.target.checked);
                }}
                onClick={e => e.currentTarget.blur()}
                {...rest}
            />
            <Label
                $required={required}
                $style={{ ':before': { ...styles, ...$checkboxStyle }, ...$checkboxLabelStyle }}
                htmlFor={internalId}
            >
                <RichText data={label} />
                {required && '*'}
            </Label>
            {errorMessage && <InputErrors $style={errorMessageStyle} errors={[errorMessage]} />}
        </div>
    );
};

Checkbox.propTypes = {
    $checkboxLabelStyle: PropTypes.object,
    $checkboxStyle: PropTypes.object,
    $style: PropTypes.object,
    backgroundColor: PropTypes.string,
    checkBoxStyle: PropTypes.object,
    defaultValue: PropTypes.bool,
    errorMessageStyle: PropTypes.object,
    errors: PropTypes.array,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
    labelStyle: PropTypes.object,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    theme: PropTypes.oneOf(['default', 'inverted']),
};

export default Checkbox;
