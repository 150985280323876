import { ReactNode, createContext, useContext, useState } from 'react';

import PropTypes from 'prop-types';

interface Context {
    state: any;
    setState: Function;
}

interface Props {
    children: ReactNode;
    initialState: any;
}

const StateContext = createContext<Context>({ state: null, setState: () => {} });

const StateProvider = ({ children, initialState }: Props) => {
    const [state, setState] = useState(initialState);

    return <StateContext.Provider value={{ state, setState }}>{children}</StateContext.Provider>;
};

StateProvider.propTypes = {
    initialState: PropTypes.any,
};

StateProvider.defaultProps = {
    initialState: undefined,
};

const useStateContext = (): [any, Function] => {
    const { state, setState } = useContext<Context>(StateContext);
    return [state, setState];
};

export { StateContext as default, StateProvider, useStateContext };
