import { forwardRef, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const withHeightAuto = Component => {
    const HeightAuto = forwardRef(({ height, maxHeight, ...rest }, ref) => {
        const node = ref || useRef();
        const lastHeight = useRef(null);

        const setHeight = useCallback(
            value => {
                requestAnimationFrame(() => {
                    if (node.current) {
                        node.current.style.height = `${maxHeight && value > maxHeight ? maxHeight : value}px`;
                        lastHeight.current = value;

                        // Check to see if this node is part of an accordion that is in turn the child of a parent accordion.
                        // If so we also set the height of the parent accordion in order to make sure that it fits the child
                        // elements.
                        const parentAccordionNode = node.current.parentNode.parentNode?.parentNode;
                        if (parentAccordionNode && parentAccordionNode.className.includes('accordion-panel-collapse')) {
                            const accordionItems = [...node.current.parentNode.parentNode.children];
                            const collapsedAccordionHeight = accordionItems.reduce(
                                (collapsedAccordionHeight, accordionItem) =>
                                    collapsedAccordionHeight + accordionItem.children[0].clientHeight,
                                0
                            );

                            if (value > 0) {
                                node.current.parentNode.parentNode.parentNode.style.height = `${
                                    collapsedAccordionHeight + value
                                }px`;
                            } else {
                                // Check if any of the siblings are expanded (which means that the user clicked on a sibling item
                                // without first collapsing this one). If so we let the setHeight function of the sibling set
                                // the parent accordion height because that will be the correct one.
                                let siblingItemIsExpanded = false;
                                const siblingItems = [...node.current.parentNode.parentNode.children];
                                siblingItems.forEach(element => {
                                    if (element.children[1].style.height !== '0px') {
                                        siblingItemIsExpanded = true;
                                    }
                                });

                                if (!siblingItemIsExpanded) {
                                    node.current.parentNode.parentNode.parentNode.style.height = `${collapsedAccordionHeight}px`;
                                }
                            }
                        }
                    }
                });
            },
            [node, maxHeight]
        );

        // Don't you miss jQuery?
        const getAbsoluteHeight = el => {
            const styles = window.getComputedStyle(el);
            const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

            return Math.ceil(el.offsetHeight + margin);
        };

        useEffect(() => {
            if (height === 'auto') {
                const { scrollHeight } = node.current;
                const childrenHeight = Object.values(node.current.children).reduce(
                    (a, c) => a + getAbsoluteHeight(c),
                    0
                );
                if (lastHeight.current !== scrollHeight) {
                    setHeight(scrollHeight);
                } else if (scrollHeight !== childrenHeight) {
                    setHeight(Math.min(scrollHeight, childrenHeight));
                }
            } else if (`${lastHeight.current}px` !== height) {
                setHeight(height);
            }
        });

        return <Component {...rest} ref={node} />;
    });

    HeightAuto.displayName = 'HeightAuto';

    HeightAuto.propTypes = {
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        maxHeight: PropTypes.number,
    };

    HeightAuto.defaultProps = {
        height: 0,
        maxHeight: 0,
    };

    return HeightAuto;
};

export default withHeightAuto;
