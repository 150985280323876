import PropTypes from 'prop-types';
import { styled, withStyleDeep } from '@activebrands/core-web/libs/styletron';

const Wrapper = styled('div');

const ActivePrice = styled('span', {
    display: 'inline-block',
    whiteSpace: 'nowrap',
});

const OriginalPrice = withStyleDeep(ActivePrice, {
    textDecoration: 'line-through',
});

/**
 * Used to show product prices
 *
 * @param {object} [$style] - Optional styling on wrapper.
 * @param {string} [activePrice] - The active sale price right now with currency.
 * @param {string} [fontKeys] - Text styling.
 * @param {string} [originalPrice] - Original price with currency, will be crossed out if salePrice and originalPrice differs.
 */

const Price = ({ $style = {}, activePrice, fontStyling = {}, originalPrice}) => {
    const onSale = originalPrice && activePrice && activePrice !== originalPrice;

    return (
        <Wrapper $style={{ ...fontStyling, ...$style }}>
            <ActivePrice
                $style={{
                    color: onSale ? 'var(--color-text-price-original-active)' : 'var(--color-price-active-original)',
                }}
            >
                {activePrice}
            </ActivePrice>
            {onSale && (
                <OriginalPrice $style={{ marginLeft: '4px', color: 'var(--color-text-price-sale-active)' }}>
                    {originalPrice}
                </OriginalPrice>
            )}
        </Wrapper>
    );
};

Price.propTypes = {
    $style: PropTypes.object,
    activePrice: PropTypes.string.isRequired,
    fontStyling: PropTypes.object,
    originalPrice: PropTypes.string,
};

export default Price;
