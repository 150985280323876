/**
 * This is a base component for background video (Uses the base component base/video.js for the video).
 * @author Hampus Lindholm & Anton Pedersen
 * @version 1.0 (Atomic-css: This style is updated to work with gatsby and styletron.)
 *
 * @param {node} children - JSX children
 * @param {string} className - Used by styled components (Add option to style this component)
 * @param {string|object|string[]|object[]} src - One or serveral video sources as strings or objects
 */

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import BaseVideo from '@activebrands/core-web/components/Video';
import { styled } from '@activebrands/core-web/libs/styletron';
import { isIE } from '@activebrands/core-web/utils/constants';

const BackgroundVideoStyle = styled('div', {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
});

const Video = styled(BaseVideo, {
    zIndex: -1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translate(-50%, -50%)',
    objectFit: 'cover',
});

const BackgroundVideo = ({
    $style = {},
    $videoStyle = {},
    autoPlay = true,
    children,
    className,
    src,
    media,
    ...rest
}) => {
    const backgroundRef = useRef(null);
    let videoRatio = null;

    // Save information from video ref
    const handleVideoRef = ref => {
        // Only run when necessary
        if (isIE) {
            const videoHeight = ref && ref.videoHeight;
            const videoWidth = ref && ref.videoWidth;

            // Calc VideoRatio after video data is loaded
            videoRatio = videoWidth / videoHeight;
        }
    };

    // Run on init and if backgroundRef or videoRatio change
    useEffect(() => {
        const handleResize = () => {
            // Save information from background ref
            const backgroundRefData = backgroundRef.current ? backgroundRef.current.getBoundingClientRect() : {};
            const backgroundRatio = backgroundRefData.width / backgroundRefData.height;

            const videoOrientation = videoRatio > 1 ? 'horizontal' : 'vertical';

            // Set default to fit value
            let fitVideoTo;

            if (videoOrientation === 'horizontal') {
                fitVideoTo = 'height';

                // No longer fills the entire box with fitVideoTo height change back to fitVideoTo width
                // This can occur when the videoHeight is smaller than the backgroundHeight
                if (backgroundRatio > videoRatio) {
                    fitVideoTo = 'width';
                }
            } else if (videoOrientation === 'vertical') {
                fitVideoTo = 'width';

                // No longer fills the entire box with fitVideoTo height change back to fitVideoTo width
                // This can occur when the videoWidth is smaller than the backgroundWidth
                if (backgroundRatio < videoRatio) {
                    fitVideoTo = 'height';
                }
            }

            // Small timeout for styletron workaround
            styleTimeout = setTimeout(() => {
                backgroundRef.current.firstChild.style.width = fitVideoTo === 'width' ? '100%' : 'auto';
                backgroundRef.current.firstChild.style.height = fitVideoTo === 'height' ? '100%' : 'auto';
            }, 5);
        };

        // Only run when necessary
        if (isIE) {
            // Run handleResize on init
            handleResize();
            window.addEventListener('resize', handleResize);
        }
    }, [videoRatio]);

    return (
        <BackgroundVideoStyle $style={$style} className={className} ref={backgroundRef}>
            <Video
                $style={$videoStyle}
                {...rest}
                loop
                muted
                playsInline
                autoPlay={autoPlay}
                controls={false}
                media={media}
                src={src}
                onLoadedMetadata={e => handleVideoRef(e.target)}
            />

            {children && children}
        </BackgroundVideoStyle>
    );
};

BackgroundVideo.propTypes = {
    $style: PropTypes.object,
    $videoStyle: PropTypes.object,
    autoPlay: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    className: PropTypes.string,
    loading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    media: PropTypes.string,
    src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    ]),
};

export default BackgroundVideo;
