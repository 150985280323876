import { useEffect } from 'react';

declare global {
    interface Window {
        lipscore: Record<string, any>;
    }
}

/**
 * useReInitWidgets hook is designed to run a script that reinitializes widgets when the component mounts.
 * @param {any} dependency The dependency that triggers the reinitialization when it changes.
 * @returns void
 *
 * @see {@link https://members.lipscore.com/widgets}
 */

const useReInitWidgets = (dependency?) => {
    useEffect(() => {
        try {
            if (window.lipscore) {
                window.lipscore.reInitWidgets(true);
            }
        } catch (error) {
            console.error('Error reinitializing Lipscore widgets', error);
        }
    }, [dependency]);
};

export default useReInitWidgets;
