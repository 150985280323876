import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';

const StyledP = styled('p', {
    display: 'inline',
});

const ParagraphResolver = ({ children = undefined }) => {
    return <StyledP>{children}</StyledP>;
};

ParagraphResolver.propTypes = {
    children: PropTypes.node,
};

export default ParagraphResolver;
