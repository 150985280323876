import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';

// import HeartIcon from 'components/icons/HeartIcon';

export const Wrapper = styled('div', { display: 'flex', alignItems: 'flex-start' });

// @todo: add onclick for adding to wishlist
const ColorSwatchCardEnd = ({ $style }) => <Wrapper $style={$style}>{/* <HeartIcon /> */}</Wrapper>;

ColorSwatchCardEnd.propTypes = {
    $style: PropTypes.object,
};

export default ColorSwatchCardEnd;
