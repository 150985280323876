import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ArrowButton from 'components/buttons/ArrowButton';
import ArrowPagination from 'components/slider/ArrowPagination';
import Heading from 'components/text/Heading';
import Tag from 'components/text/Tag';

const Wrapper = styled('div', ({ $isSlider, $type }) => ({
    width: '100%',
    height: '100%',
    marginBottom: '24px',

    [media.min['mobile.lg']]:
        $type === 'product'
            ? $isSlider
                ? {
                      height: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      aspectRatio: 'var(--ratio-vertical-secondary)',
                      paddingRight: '16px',
                  }
                : {
                      display: 'grid',
                      gridTemplateColumns: 'repeat(12, 1fr)',
                      height: 'auto',
                  }
            : {
                  width: '50%',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginBottom: '24px',
                  paddingRight: '16px',
              },

    [media.min['desktop.sm']]: {
        width: '100%',
        aspectRatio: $type === 'product' ? 'var(--ratio-vertical-secondary)' : 'var(--ratio-vertical-primary)',
        justifyContent: $type === 'feature' ? 'flex-start' : 'space-between',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '0',
    },
}));

const HeadingAndIngressWrapper = styled('div', {
    [media.min['mobile.lg']]: {
        gridColumn: '1 / span 6',
    },
});

const ButtonWrapper = styled('div', ({ $type }) => ({
    display: 'none',

    [media.min['mobile.lg']]:
        $type === 'product'
            ? {
                  gridColumn: '7 / span 6',
                  display: 'flex',
                  flexDirection: 'column',
              }
            : {},

    [media.min['desktop.sm']]:
        $type === 'article' || $type === 'feature'
            ? {
                  gridColumn: '7 / span 6',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: $type === 'feature' ? '24px' : '0',
              }
            : {},
}));

const PromotionalHeader = ({
    $paginationStyle,
    $style,
    button,
    heading,
    headingFontKeys,
    headingType,
    slider,
    tag,
    text,
    type,
}) => {
    const [css] = useStyletron();

    return (
        <Wrapper $style={$style} $type={type} $isSlider={!!slider}>
            <HeadingAndIngressWrapper>
                {tag && <Tag $style={{ marginBottom: '16px' }}>{tag}</Tag>}
                {heading && (
                    <Heading
                        $style={{ marginBottom: ['12px', null, '24px'] }}
                        as={headingType}
                        fontKeys={headingFontKeys}
                    >
                        {heading}
                    </Heading>
                )}
                <RichText data={text} />
            </HeadingAndIngressWrapper>
            <ButtonWrapper $type={type}>
                <div
                    className={css({
                        display: 'flex',
                        marginTop: 'auto',
                        marginBottom: '8px',
                    })}
                >
                    <ArrowPagination
                        $style={{
                            backgroundColor: 'var(--color-bg-button-default)',
                            borderRadius: '100px',
                            marginTop: 'auto',
                            padding: '10px 0 4px',
                            width: '40px',
                            height: '40px',
                            marginRight: '8px',
                            ...$paginationStyle,
                        }}
                        slider={slider}
                    />
                </div>
                {button?.label && button?.url && (
                    <ArrowButton
                        iconSize="16px"
                        fontKeys={['Miscellaneous/18_100', null, null, null, null, 'Miscellaneous/24_100_-2']}
                        $style={{
                            marginLeft: slider ? '0' : ['auto', null, null, null, null, '0'],
                            marginTop: slider ? '0' : 'auto',
                            width: slider ? '100%' : [null, null, null, null, null, '100%'],
                        }}
                        as={Link}
                        rel={button.rel}
                        theme={button.theme}
                        to={button.url}
                    >
                        {button.label}
                    </ArrowButton>
                )}
            </ButtonWrapper>
        </Wrapper>
    );
};

PromotionalHeader.propTypes = {
    $paginationStyle: PropTypes.object,
    $style: PropTypes.object,
    button: PropTypes.object,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    slider: PropTypes.object,
    tag: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
};

export default PromotionalHeader;
