import { ComponentType, useRef } from 'react';
import overlay from '@activebrands/core-web/libs/overlay';
import generateRandomString from '@grebban/utils/string/generateRandomString';

const useNotifications = () => {
    const timeouts = useRef<Record<string, ReturnType<typeof setTimeout>>>({});

    const remove = (id: string) => {
        overlay.close(id);
    };

    const push = (
        component: ComponentType<{ id?: string; onMouseEnter?: (e: any) => void; onMouseLeave?: (e: any) => void }>,
        componentProps: {
            id?: string;
            [key: string]: any;
            onMouseEnter?: (e: any) => void;
            onMouseLeave?: (e: any) => void;
        } = {},
        timeout = 4500
    ) => {
        const id = componentProps.id || `notification-${generateRandomString(7)}`;

        const props = {
            id,
            ...componentProps,
        };

        overlay.open(id, { component, props });

        if (timeout) {
            props.onMouseEnter = e => {
                clearTimeout(timeouts.current[id]);
            };

            props.onMouseLeave = e => {
                timeouts.current[id] = setTimeout(() => {
                    remove(id);
                }, timeout);
            };

            timeouts.current[id] = setTimeout(() => {
                remove(id);
            }, timeout);
        }

        return id;
    };

    return {
        push,
        remove,
    };
};

export default useNotifications;
