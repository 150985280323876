import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Image from '@activebrands/core-web/components/Image';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import Paragraph from 'components/text/Paragraph';

const MenuItem = styled('div', {
    width: '100%',
    wordWrap: 'break-word',
});
const SelectorWrapper = styled('div', {
    width: '100%',
});
const SelectButton = styled('button', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 12px 8px 12px',

    width: '100%',
    lineHeight: '100%',
    [media.min['tablet.sm']]: {
        justifyContent: 'flex-start',
    },
});

const SiteSelectorButton = ({ alternateHrefs = [] }) => {
    const [css] = useStyletron();
    const { siteSelectorLinks } = useSiteSelectorQuery();
    const aliasId = useSelector(state => state.application.aliasId);
    const currentSite = siteSelectorLinks.find(site => site.id == aliasId);

    return (
        <MenuItem>
            <SelectorWrapper>
                <SelectButton
                    onClick={() => {
                        overlay.open('site-selector', { alternateHrefs });
                    }}
                >
                    <div className={css({ display: 'flex' })}>
                        <div
                            className={css({
                                marginRight: '16px',
                                borderRadius: '60px',
                                width: '16px',
                                height: '16px',
                                overflow: 'hidden',
                            })}
                            src={currentSite?.icon}
                        >
                            <Image sizes={['24px']} src={{ url: currentSite?.icon, width: [16, 48, 72] }} />
                        </div>
                        <Paragraph fontKeys="Miscellaneous/14_100">{currentSite?.label}</Paragraph>
                    </div>
                </SelectButton>
            </SelectorWrapper>
        </MenuItem>
    );
};

SiteSelectorButton.propTypes = {
    alternateHrefs: PropTypes.array,
};

export default SiteSelectorButton;
