import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const PlayIcon = ({ $style = {}, color = 'currentColor', height = 'auto', width = '16px', ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox="0 0 16 16" {...rest}>
            <path d="M1.5 14.5v-13l1-.5 12 6v1l-12 7-1-.5Z" stroke={color} />
        </SVG>
    );
};

PlayIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default PlayIcon;
