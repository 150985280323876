export const themes = {
    default: {
        backgroundColor: 'var(--color-bg-button-default)',
        color: 'var(--color-text-button-default)',
        borderColor: 'transparent',
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-default-active)',
            color: 'var(--color-text-button-default-active)',
        },
        disabledStyles: {
            backgroundColor: 'transparent',
            color: 'var(--color-text-button-default-disabled)',
            cursor: 'not-allowed',
            borderColor: 'var(--color-bg-button-default)',
            hoverStyles: {
                backgroundColor: 'transparent',
                borderColor: 'var(--color-bg-button-default)',
                color: 'var(--color-text-button-default-disabled)',
            },
        },
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-default-hover)',
            color: 'var(--color-text-button-default-hover)',
        },
    },
    inverted: {
        backgroundColor: 'var(--color-bg-button-inverted)',
        color: 'var(--color-text-button-inverted)',
        borderColor: 'transparent',
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-inverted-active)',
            color: 'var(--color-text-button-inverted-active)',
        },
        disabledStyles: {
            backgroundColor: 'var(--color-bg-button-inverted-disabled)',
            color: 'var(--color-text-button-inverted-disabled)',
            cursor: 'not-allowed',
            borderColor: 'var(--color-bg-button-inverted)',
        },
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-inverted-hover)',
            color: 'var(--color-text-button-inverted-hover)',
        },
    },
    link: {
        backgroundColor: 'var(--color-bg-button-link)',
        color: 'var(--color-text-button-link)',
        borderColor: 'transparent',
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-link-active)',
            color: 'var(--color-text-button-link-hover)',
        },
        disabledStyles: {
            backgroundColor: 'var(--color-bg-button-link-disabled)',
            color: 'var(--color-text-button-link-disabled)',
            cursor: 'not-allowed',
            borderColor: 'transparent',
        },
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-link-hover)',
            color: 'var(--color-text-button-link-hover)',
        },
    },
};

// Missing the size? Use $style to add custom styling.
export const sizes = {
    // Secondary
    sm: {
        padding: ['12px', null, null, null, '8px 12px'],
    },
    // Primary
    md: {
        padding: '8px 16px',
    },
};

export const themeNames: { [key: string]: string } = {
    // CMS names
    beige: 'default',
    black: 'inverted',
};

export const getButtonTheme = (theme = 'default'): string => {
    if (!theme) {
        return 'default';
    }

    return themeNames[theme] || theme;
};
