import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';

const Box = styled('div', {
    '-webkit-overflow-scrolling': 'touch',
});

const ScrollableBox = forwardRef(({ scrolldirection, withScrollbar, $style, as, ...rest }, ref) => {
    const style = { ...$style };

    if (scrolldirection) {
        style.overflowX = scrolldirection === 'vertical' ? 'hidden' : 'auto';
        style.overflowY = scrolldirection === 'horizontal' ? 'hidden' : 'auto';
    }

    return <Box $as={as} $style={style} data-scrollbar={withScrollbar} ref={ref} {...rest} />;
});

ScrollableBox.displayName = 'ScrollableBox';

ScrollableBox.propTypes = {
    $style: PropTypes.object,
    as: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    scrolldirection: PropTypes.oneOf(['vertical', 'horizontal']),
    withScrollbar: PropTypes.bool,
};

ScrollableBox.defaultProps = {
    $style: {},
    as: undefined,
    scrolldirection: 'vertical',
    withScrollbar: false,
};

export default ScrollableBox;
