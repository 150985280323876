import PropTypes from 'prop-types';
import extractBackgroundOverlays from '@activebrands/core-web/libs/storyblok/utils/extract-background-overlays';
import transformFlexibleBackgrounds from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds';
import transformFlexibleBackgroundsFactors from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds-factors';
import transformLayout from '@activebrands/core-web/libs/storyblok/utils/transform-layout';
import Flexible from 'components/content-components/Flexible';

const BackgroundMediaDataTransformerBlock = ({
    content = {},
    isFourColumns = false,
    isThreeColumns = false,
    isTwoColumns = false,
}) => {
    const {
        anchorTag = [],
        backgroundDesktop = [],
        backgroundMobile = [],
        caption = [],
        textColor = '',
        wrapperLink = [],
    } = content;

    const backgroundOverlays = extractBackgroundOverlays([backgroundMobile[0], null, null, null, backgroundDesktop[0]]);
    const layout = transformLayout({ isTwoColumns, isThreeColumns, isFourColumns });

    const transformedBackgrounds = transformFlexibleBackgrounds(
        [backgroundMobile[0], null, null, null, backgroundDesktop[0]],
        transformFlexibleBackgroundsFactors(layout)
    );

    return (
        <Flexible
            background={transformedBackgrounds}
            backgroundLoading="lazy"
            backgroundOverlay={backgroundOverlays}
            caption={caption?.[0]?.caption}
            contentHorizontalAlignment="left"
            id={anchorTag?.[0]?.anchorTag}
            layout={layout}
            textColor={textColor}
            wrapperLink={wrapperLink?.[0]?.link?.url}
        />
    );
};

BackgroundMediaDataTransformerBlock.propTypes = {
    content: PropTypes.object.isRequired,
    isFourColumns: PropTypes.bool,
    isThreeColumns: PropTypes.bool,
    isTwoColumns: PropTypes.bool,
};

export default BackgroundMediaDataTransformerBlock;
