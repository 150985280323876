/**
 * Make sure that a url always ends with /
 * @param {string} url
 */

export const addTrailingSlash = (url: any): string | any => {
    if (typeof url !== 'string') {
        return url;
    }

    const tokens = url.split('?');
    const first = tokens[0];
    if (first.length && first[first.length - 1] !== '/') {
        tokens[0] += '/';
        return tokens.join('');
    }

    return url;
};
