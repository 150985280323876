import { BackgroundOverlay, RawSplitData } from '@activebrands/core-web/libs/storyblok/storyblok';
import extractBackgroundOverlay from './extract-background-overlay';
import { RawBackgroundData } from './transform-flexible-background';

/**
 * Extract and transform media overlay data from backgrounds for use in getBackgroundOverlayStyles
 * @param flexibleBackgrounds - Array of flexible backgrounds
 * @returns Array of background overlays
 * @example [{ overlay: 'cover', overlayOpacity: '0.2' }, {overlay: undefined, overlayOpacity: undefined } ]
 */

export type BackgroundOverlaysTypes = Array<BackgroundOverlay>;

const extractBackgroundOverlays = (
    flexibleBackgrounds: (RawBackgroundData | RawSplitData)[]
): BackgroundOverlaysTypes => flexibleBackgrounds.map(extractBackgroundOverlay);

export default extractBackgroundOverlays;
