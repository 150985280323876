import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const LogotypeIcon = ({
    $style = {},
    color = 'currentColor',
    height = 'auto',
    viewBox = '0 0 108 32',
    width = '107px',
    ...rest
}) => {
    const style = { width, height, ...$style };

    return (
        <SVG {...rest} $style={style} version="1.1" viewBox={viewBox}>
            <path
                clipRule="evenodd"
                d="M6.654 0C5.456 0 4.601.855 4.601 2.053s.855 2.054 2.053 2.054 2.053-.856 2.053-2.054S7.852 0 6.654 0Zm77.918 17.212c0 2.337-1.593 4.01-3.754 4.04l-.473.003c-2.448 0-3.852-1.707-3.852-4.043V7.777h-3.37v9.435c0 4.38 2.565 6.894 6.571 7.077.425.02 1.175.018 1.675 0 3.917-.142 6.571-2.696 6.571-7.077V7.777h-3.37v9.435h.002Zm-72.454-1.4c0-4.55 3.875-8.425 8.425-8.425 4.549 0 8.424 3.876 8.424 8.425 0 4.55-3.875 8.424-8.424 8.424-4.55 0-8.425-3.875-8.425-8.424Zm3.37-.001c0 3.033 2.359 5.392 5.055 5.392 2.695 0 5.054-2.36 5.054-5.392 0-3.033-2.359-5.392-5.054-5.392-2.696 0-5.055 2.359-5.055 5.392Zm25.779-8.424c-3.033 0-4.887 2.022-4.887 2.022V.311h-3.37v23.587h3.37v-8.761c0-3.033 1.685-4.718 4.213-4.718 2.19 0 3.875 1.685 3.875 4.044v9.435h3.37v-9.435c0-4.38-2.696-7.077-6.571-7.077v.001Zm18.76 0c3.2 0 5.223 2.19 5.223 2.19V7.71h3.37V23.9h-3.37v-1.854s-2.022 2.19-5.224 2.19c-4.212 0-7.919-3.706-7.919-8.424s3.707-8.425 7.92-8.425Zm-4.55 8.425c0 3.033 2.36 5.392 4.886 5.392 2.528 0 4.887-2.36 4.887-5.392 0-3.033-2.36-5.392-4.887-5.392-2.527 0-4.886 2.36-4.886 5.392ZM104.2 7.786v1.76l-.035-.137s-1.854-2.022-4.887-2.022c-4.043 0-7.582 3.539-7.582 8.088 0 4.55 3.539 8.088 7.582 8.088 2.917 0 4.725-1.853 4.86-1.996-.304 4.337-2.486 6.79-6.84 7.013-.218.011-.678.017-.909.017V32l.974-.013c6.445-.275 10.243-4.285 10.243-11.292V7.786H104.2ZM99.615 20.53c-2.358 0-4.549-2.19-4.549-5.055 0-2.864 2.19-5.055 4.55-5.055 2.358 0 4.549 2.19 4.549 5.055 0 2.864-2.191 5.055-4.55 5.055ZM0 28.238c3.367-.854 4.956-2.79 4.956-7.792V7.723h3.343v12.909C8.3 26.927 5.778 30.898 0 31.663v-3.425Z"
                fill={color}
                fillRule="evenodd"
            />
        </SVG>
    );
};

LogotypeIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default LogotypeIcon;
