import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import { styled } from '@activebrands/core-web/libs/styletron';
import LogotypeIcon from 'components/icons/LogotypeIcon';

const LogotypeLink = styled(Link, {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
});

// TODO: Add title label to CMS
const Logotype = ({ $style = {}, width }) => (
    <LogotypeLink $style={$style} title="Johaug" to="/">
        <LogotypeIcon color="currentColor" width={width} />
    </LogotypeLink>
);

Logotype.propTypes = {
    $style: PropTypes.object,
    width: PropTypes.string,
};

export default Logotype;
