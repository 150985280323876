interface IParams {
    contentHorizontalAlignment?: 'left' | 'right' | 'center';
    contentVerticalAlignment?: 'top' | 'bottom' | 'center';
}

interface IReturn {
    display: 'flex';
    width: '100%';
    height: '100%';
    justifyContent: 'flex-start' | 'flex-end' | 'center';
    alignItems: 'flex-start' | 'flex-end' | 'center';
}

const getPositionStyles = (params: IParams): IReturn => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent:
        params?.contentHorizontalAlignment === 'left'
            ? 'flex-start'
            : params?.contentHorizontalAlignment === 'right'
            ? 'flex-end'
            : 'center',
    alignItems:
        params?.contentVerticalAlignment === 'top'
            ? 'flex-start'
            : params?.contentVerticalAlignment === 'bottom'
            ? 'flex-end'
            : 'center',
});

export default getPositionStyles;
