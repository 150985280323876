import type { Layout } from './transform-layout';

export type Factors = Array<number | null> | number;

export default (layout?: Layout): Factors => {
    let transformedFactors: Factors;

    switch (layout) {
        case 'twoColumns':
            transformedFactors = [1, null, null, null, 0.5];
            break;
        case 'threeColumns':
            transformedFactors = [0.75, null, null, null, 0.33];
            break;
        case 'fourColumns':
            transformedFactors = [0.75, null, null, null, 0.25];
            break;
        default:
            return 1;
    }

    return transformedFactors;
};
