import { useCallback, useMemo, useRef } from 'react';
import { inServer } from '@activebrands/core-web/utils/constants';
import toHash from '@grebban/utils/string/toHash';
import { useWillUnmount } from './lifecycle';

// @todo: fix support for treshold as array
interface ObserverOptions {
    root: HTMLElement | null;
    rootMargin: string;
    threshold: number;
}

interface ObserverEntry {
    boundingClientRect: DOMRectReadOnly | null;
    intersectionRatio: number;
    intersectionRect: DOMRectReadOnly | null;
    isIntersecting: boolean;
    rootBounds: DOMRectReadOnly | null;
    target: Element | null;
    time: number;
}

type EntryCallback = (event: ObserverEntry) => any;

type CacheObject = {
    entries: WeakMap<Element, Function>;
    observer: IntersectionObserver;
    size: number;
};

declare global {
    interface Window {
        __intersectionObserverCache: Record<string, CacheObject>;
    }
}

const defaultOptions: ObserverOptions = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0,
};

const getKey = (...args: (string | number | null)[]) => toHash(args.join('.'));

const getIntersectionObserver = (root: any, rootMargin: string, threshold: number) => {
    const key = getKey(rootMargin, threshold);

    return root.__intersectionObserverCache?.[key];
};

const createIntersectionObserver = (options: ObserverOptions): CacheObject => {
    const root: Record<string, any> = options.root || window;
    const key = getKey(options.rootMargin, options.threshold);

    if (!root.__intersectionObserverCache) {
        root.__intersectionObserverCache = { [key]: {} };
    }

    root.__intersectionObserverCache[key] = {
        size: 0,
        entries: new WeakMap(),
        observer: new IntersectionObserver(entries => {
            entries.forEach(entry => {
                root.__intersectionObserverCache[key]?.entries?.get(entry.target)?.(entry);
            });
        }, options),
    };

    return root.__intersectionObserverCache[key];
};

const removeEntry = (node: Element, intersectionObserver: any) => {
    if (intersectionObserver.entries.has(node)) {
        intersectionObserver.entries.delete(node);
        intersectionObserver.observer.unobserve(node);

        if (--intersectionObserver.size < 1) {
            intersectionObserver.observer.disconnect();
        }
    }
};

const useIntersectionObserver = (callback: EntryCallback, options: Partial<ObserverOptions> = {}) => {
    const ref = useRef<Element | null>(null);

    const { root, rootMargin, threshold } = { ...defaultOptions, ...options };

    const cacheKey = useMemo(() => getKey(rootMargin, threshold), [rootMargin, threshold]);

    const observe = useCallback(
        (node: Element) => {
            if (!inServer && node && window.IntersectionObserver) {
                const rootElement = root || window;

                let intersectionObserver = getIntersectionObserver(rootElement, rootMargin, threshold);

                if (!intersectionObserver) {
                    intersectionObserver = createIntersectionObserver({ root, rootMargin, threshold });
                }

                const target = intersectionObserver.entries.get(node);

                if (!target) {
                    ++intersectionObserver.size;
                    ref.current = node;
                } else {
                    intersectionObserver.observer.unobserve(target);
                }

                intersectionObserver.entries.set(node, callback);
                intersectionObserver.observer.observe(node);

                return () => removeEntry(node, intersectionObserver);
            }

            return () => {};
        },
        [cacheKey]
    );

    const unobserve = useCallback(() => {
        if (!inServer && ref.current) {
            const rootElement = root || window;
            const intersectionObserver = getIntersectionObserver(rootElement, rootMargin, threshold);

            if (intersectionObserver) {
                removeEntry(ref.current, intersectionObserver);
            }
        }
    }, [cacheKey]);

    useWillUnmount(() => unobserve());

    return [observe, unobserve];
};

export default useIntersectionObserver;
