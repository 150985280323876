import SVG from '@activebrands/core-web/components/SVG';

interface SpinnerIconProps {
    $style?: any;
    color?: any;
    height?: any;
    width?: any;
}

const SpinnerIcon = ({
    $style = {},
    color = 'currentColor',
    height = 'auto',
    width = '32px',
    ...rest
}: SpinnerIconProps) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox="0 0 38 38" {...rest}>
            <defs>
                <linearGradient id="a" x1="8%" x2="65.7%" y1="0%" y2="23.9%">
                    <stop offset="0%" />
                    <stop offset="63.1%" />
                    <stop offset="100%" />
                </linearGradient>
            </defs>
            <g fill="none" transform="translate(1 1)">
                <path d="M36 18C36 8 28 0 18 0" stroke={color}>
                    <animateTransform
                        attributeName="transform"
                        dur="0.9s"
                        from="0 18 18"
                        repeatCount="indefinite"
                        to="360 18 18"
                        type="rotate"
                    />
                </path>
                <circle cx="36" cy="18" r="1">
                    <animateTransform
                        attributeName="transform"
                        dur="0.9s"
                        from="0 18 18"
                        repeatCount="indefinite"
                        to="360 18 18"
                        type="rotate"
                    />
                </circle>
            </g>
        </SVG>
    );
};

export default SpinnerIcon;
