import { addTrailingSlash } from '@activebrands/core-web/gatsby/utils/url';
import Events from '@activebrands/core-web/libs/Events';
import { TrackingLists } from '@activebrands/core-web/libs/Tracking/constants';
import { SearchEvents } from '@activebrands/core-web/libs/algolia/searchInsights';
import { FacetMap, SelectedFacet } from '@activebrands/core-web/types/faceting';
import { setQueryFacetsParams, updateQueryParamsOptions } from '@activebrands/core-web/types/query';
import { removePathPrefixRegExp } from '@activebrands/core-web/utils/create-regExp';
import buildQueryString from '@grebban/string-utils/buildQueryString';
import extractQueryParams from '@grebban/utils/string/extractQueryParams';
import { WindowLocation } from '@reach/router';
import { navigate } from 'gatsby';

const REDIRECT_PREFIX = process.env.REACT_APP_REDIRECT_PATH_PARAM;
const CLEAR_LOCATION_PREFIX = process.env.REACT_APP_CLEAR_LOCATION_PARAM;

const defaultOptions = {
    basePath: undefined,
    state: {},
};

/**
 * Navigate uses withPrefix wich adds site prefix automatically.
 * Use this function to remove ex /se from a path
 * @param {string} path
 * @returns {string}
 */

export const removePathPrefix = (path: string) => path.replace(removePathPrefixRegExp(), '');

/**
 * Extracts query params from the search property of the Location interface.
 * Handles params "redirect_path" and "clear_location".
 * Builds a new query string from query object.
 * @returns {string} redirect_path/query
 */

export const getRedirectPathWithQuery = (location: WindowLocation) => {
    // @todo: use extractQueryParams function from @grebban/utils
    const query = extractQueryParams(location.search);

    // Get redirect_path from query
    const redirectPath = query[`${REDIRECT_PREFIX}`] || '';
    // Remove the object to easier handle the new query string
    delete query[`${REDIRECT_PREFIX}`];
    delete query[`${CLEAR_LOCATION_PREFIX}`];

    // Use grebban package to build a new query string
    const newQueryString = buildQueryString(query);
    const newQuery = newQueryString ? `?${newQueryString}` : '';

    // If redirect_path doesn't exist, return query with a slash at the beginning
    if (!redirectPath) {
        return `/${newQuery}`;
    }

    // Add trailing slash to redirect_path. Return redirect_path + query
    return `${addTrailingSlash(redirectPath)}${newQuery}`;
};

/**
 * Update http-query with key:value pairs. Encode all values
 * @param {object} params - Object with key:value pairs
 * @param {string} basePath - Update params for a specific path. Default is current page
 */

export const updateQueryParams = (params: { [key: string]: string }, options: updateQueryParamsOptions = {}) => {
    options = { ...defaultOptions, ...options };
    const paramsArray = Object.keys(params).map(param => `${param}=${encodeURIComponent(params[param])}`);
    const paramsString = paramsArray.join('&');
    let basePath = options.basePath || window.location.pathname;
    if (options.keepPrefix !== true) {
        basePath = removePathPrefix(basePath);
    }

    const path = paramsString ? `${basePath}?${paramsString}` : basePath;
    navigate(path, { replace: true, state: { ...options.state } });
};

/**
 * Get facets and sorting parms from the http-query and prepare them for use by filters
 * @param {object} location - WindowLocation
 * @returns {object} - Facets and sort params ready for filter use
 */

export const getQueryFacets = (location: WindowLocation, facetMap: FacetMap) => {
    const queryParams = extractQueryParams(location.search);

    // Get and prepare facets
    const facets = Object.keys(queryParams).reduce((facets: SelectedFacet[], param: string) => {
        if (param in facetMap) {
            const type = facetMap[param].type;
            let value;

            switch (type) {
                case 'set': {
                    const set = new Set();
                    const decoded = decodeURI(queryParams[param]);
                    decoded.split(',').forEach(v => {
                        set.add(v);
                    });
                    value = set;
                    break;
                }

                case 'property': {
                    value = true;
                    break;
                }

                case 'range': {
                    const [min, max] = queryParams[param].split('-');
                    value = { min: parseInt(min, 10), max: parseInt(max, 10) };
                    break;
                }

                default:
                    break;
            }

            facets.push({
                facet: param,
                name: facetMap[param].label,
                type,
                value,
            });
        }
        return facets;
    }, []);

    // Get sort params
    const { sortBy, sortOrder } = queryParams;

    return { facets, sortBy, sortOrder };
};

export const setQueryFacets = ({
    facetMap,
    location,
    metaData,
    orderByDefault,
    removeParams = [],
    selectedFacets,
    sortBy,
    sortByDefault,
    sortOrder,
}: setQueryFacetsParams) => {
    const queryParams = extractQueryParams(location.search);
    const originalParams = { ...queryParams };

    // Remove unwanted params from the current query
    Object.keys(queryParams).forEach(param => {
        if (param in facetMap) {
            delete queryParams[param];
        }
    });
    removeParams.forEach(param => {
        delete queryParams[param];
    });
    delete queryParams.sortBy;
    delete queryParams.sortOrder;

    // Transform selected facets intro object similar to queryParams
    const facetParams = selectedFacets.reduce((all: Record<string, string>, current: SelectedFacet) => {
        const param = facetMap[current.facet]?.param;
        if (param) {
            switch (current.type) {
                case 'set':
                    all[param] = [...current.value].join(',');
                    break;

                case 'property':
                    all[param] = '1';
                    break;

                case 'range':
                    all[param] = `${current.value.min}-${current.value.max}`;
                    break;

                default:
                    break;
            }
        }

        return all;
    }, {});

    // Add sort params if they are neccesary
    const sortParams: Record<string, any> = {};
    if (sortBy && sortBy !== sortByDefault && sortByDefault) {
        sortParams.sortBy = sortBy;
    }
    if (sortOrder && sortOrder !== orderByDefault && orderByDefault) {
        sortParams.sortOrder = sortOrder;
    }

    // Update all active query prams
    const allParams = { ...queryParams, ...facetParams, ...sortParams };
    if (JSON.stringify(allParams) !== JSON.stringify(originalParams)) {
        // resetPagination will reset usePagination-hook
        // Note: The searchpage doesn't use the usePagination-hook
        updateQueryParams(allParams, { state: { resetPagination: true } });

        if (Object.keys(facetParams).length > 0) {
            Events.trigger(SearchEvents.FILTER_CLICK, {
                ...metaData[1],
                eventName: `${TrackingLists.PRODUCT_GRID}: Filter`,
                filters: Object.keys(facetParams)
                    .map(key => facetParams[key].split(',').map(val => `${key}:${val}`))
                    .reduce((acc, val) => acc.concat(val), [])
                    .slice(0, 10),
            });
        }
    }
};
