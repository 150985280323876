import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Price from '@activebrands/core-web/components/Price';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ColorSwatches from 'components/product/ColorSwatches';
import Heading from 'components/text/Heading';
import { Link } from 'gatsby';

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 12px 0 0',

    [media.min['desktop.sm']]: {
        padding: '16px 12px 0 0',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
});

const GridCardBottomSection = ({ $style = {}, color, name, price, relatedVariations, uri }) => {
    const [css] = useStyletron();

    return (
        <Wrapper $style={$style}>
            <Link
                className={css({
                    display: 'flex',
                    flexDirection: ['column', null, null, null, null, 'row'],
                    justifyContent: ['space-between', null, null, null, null, 'auto'],
                    width: '100%',
                })}
                title={name}
                to={uri}
            >
                <Heading
                    $style={{
                        alignSelf: 'start',
                        marginBottom: ['4px', null, null, null, null, '12px'],
                        width: ['auto', null, null, null, null, '70%'],
                    }}
                    fontKeys="Secondary/14_130_500"
                >
                    {name}
                </Heading>
                <Price
                    $style={{
                        marginBottom: '12px',
                        width: ['auto', null, null, null, null, '30%'],
                        textAlign: ['left', null, null, null, null, 'right'],
                    }}
                    activePrice={price?.salePriceWithCurrency}
                    fontStyling={{ ...textStyles['Miscellaneous/12_100'] }}
                    originalPrice={price?.priceWithCurrency}
                />
            </Link>
            <div
                className={css({
                    display: 'flex',
                    flexDirection: ['column', null, null, 'row'],
                    justifyContent: ['auto', null, null, 'space-between'],
                    width: '100%',
                    alignItems: ['auto', null, null, null, null, 'end'],
                })}
            >
                {relatedVariations.length > 1 && (
                    <ColorSwatches
                        $style={{
                            width: ['auto', null, null, null, null, '30%'],
                            justifyContent: ['start', null, null, null, null, 'end'],
                        }}
                        textstyles="Miscellaneous/10_100"
                        currentColor={color}
                        variations={relatedVariations}
                    />
                )}
            </div>
        </Wrapper>
    );
};

GridCardBottomSection.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.object,
    relatedVariations: PropTypes.array,
    uri: PropTypes.string,
};

export default GridCardBottomSection;
