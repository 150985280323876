import { graphql, useStaticQuery } from 'gatsby';

const useHeaderNavigationQuery = () => {
    const { application } = useStaticQuery(
        graphql`
            {
                application {
                    data {
                        config {
                            headerConfig {
                                featuredLabel
                                featuredItems {
                                    ...FeaturedItem
                                }
                                primaryMenu {
                                    _uid
                                    label
                                    link {
                                        ...Link
                                    }
                                    featuredItems {
                                        ...FeaturedItem
                                    }
                                    submenus {
                                        _uid
                                        label
                                        link {
                                            ...Link
                                        }
                                        featuredItems {
                                            ...FeaturedItem
                                        }
                                        submenus {
                                            _uid
                                            label
                                            link {
                                                ...Link
                                            }
                                            featuredItems {
                                                ...FeaturedItem
                                            }
                                        }
                                    }
                                }
                                secondaryMenu {
                                    ...LinkItem
                                }
                                extraLinks {
                                    ...LinkIcon
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    return application.data.config.headerConfig || {};
};

export default useHeaderNavigationQuery;
