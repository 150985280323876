import PropTypes from 'prop-types';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import Link from '@activebrands/core-web/components/Link';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import getPositionStyles from '@activebrands/core-web/utils/style-functions/get-position-styles';
import getTextColorStyles from '@activebrands/core-web/utils/style-functions/get-text-color-styles';
import ButtonsList from 'components/buttons/ButtonsList';
import ThemeButton from 'components/buttons/ThemeButton';
import PlayIcon from 'components/icons/PlayIcon';
import Heading from 'components/text/Heading';
import Tag from 'components/text/Tag';

const Wrapper = styled('div', {
    position: 'relative',
    width: '100%',
    height: '100%',
});

const ContentWrapper = styled('div', {
    position: 'relative',
    height: '100%',
});

const ContentBox = styled('div', {
    alignContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    textAlign: 'inherit',
    zIndex: 1,
});

const MaxWidthWrapper = styled('div', {
    height: '100%',
    margin: '0 auto',
    maxWidth: 'var(--site-corridor-max-width)',
    position: 'relative',
    width: '100%',
});

const Flexible = ({
    background,
    backgroundLoading = 'eager',
    backgroundOverlay,
    buttons = [],
    contentHorizontalAlignment = 'center',
    contentVerticalAlignment = 'center',
    heading,
    headingStyle = 'md',
    headingType = 'h2',
    id,
    layout = 'oneColumn',
    playButtonCallback,
    playButtonLabel,
    tag = '',
    text,
    textColor = 'beige',
    wrapperLink,
}) => {
    const [css] = useStyletron();
    const hasButtons = buttons.length > 0;

    const headingFontKeys = {
        sm: ['Primary/24_120_-2', null, null, 'Primary/48_100_-3', null, 'Primary/60_100_-3'],
        md: ['Primary/32_110_-2', null, null, 'Primary/72_100_-3', null, 'Primary/96_100_-3'],
    };

    const contentWrapperStyling = {
        oneColumn: { padding: ['8px 8px 12px 8px', null, null, '12px 12px 24px 12px', null, '16px 16px 32px 16px'] },
        twoColumns: { padding: ['8px 8px 16px 8px', null, null, '12px 12px 24px 12px'] },
        threeColumns: { padding: ['8px 8px 16px 8px', null, null, '12px 12px 24px 12px'] },
    };

    const contentBoxStyling = {
        oneColumn: { width: ['83.333%', null, null, null, '50%'] },
        twoColumns: { width: ['100%', null, null, '83.333%'] },
        threeColumns: { width: ['100%', null, null, '83.333%'] },
    };

    const textColorStyle = getTextColorStyles(textColor);
    const positionStyles = getPositionStyles({ contentHorizontalAlignment, contentVerticalAlignment });
    const contentWrapperStyles = contentWrapperStyling[layout];
    const contentBoxStyles = contentBoxStyling[layout];

    return (
        <Wrapper id={id}>
            <Link className={css(textColorStyle)} to={wrapperLink}>
                <BackgroundDynamic
                    background={background}
                    backgroundOverlay={backgroundOverlay}
                    loading={backgroundLoading}
                />
                <ContentWrapper
                    $style={{ textAlign: contentHorizontalAlignment, ...contentWrapperStyles, ...textColorStyle }}
                >
                    <MaxWidthWrapper $style={positionStyles}>
                        <ContentBox $style={{ ...contentBoxStyles }}>
                            {tag && (
                                <div>
                                    <Tag $style={{ marginBottom: '24px' }}>{tag}</Tag>
                                </div>
                            )}
                            {heading && (
                                <Heading as={headingType} fontKeys={headingFontKeys[headingStyle]}>
                                    {heading}
                                </Heading>
                            )}
                            {text && (
                                <div
                                    className={css({
                                        marginTop: ['8px', null, '16px'],
                                    })}
                                >
                                    <RichText data={text} />
                                </div>
                            )}
                            {hasButtons && (
                                <ButtonsList
                                    $style={{
                                        ...positionStyles,
                                        flexWrap: 'wrap',
                                        marginTop: ['24px', null, null, null, null, '32px'],
                                    }}
                                    buttons={buttons}
                                />
                            )}
                        </ContentBox>

                        {playButtonCallback && (
                            <ThemeButton
                                $style={{
                                    bottom: 0,
                                    display: 'flex',
                                    gap: '0 16px',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    right: 0,
                                }}
                                fontKeys={[
                                    'Miscellaneous/18_100_-1',
                                    null,
                                    null,
                                    null,
                                    null,
                                    'Miscellaneous/24_100_-1',
                                ]}
                                theme="default"
                            >
                                {playButtonLabel}
                                <PlayIcon onClick={playButtonCallback} />
                            </ThemeButton>
                        )}
                    </MaxWidthWrapper>
                </ContentWrapper>
            </Link>
        </Wrapper>
    );
};

Flexible.propTypes = {
    background: PropTypes.arrayOf(PropTypes.object),
    backgroundLoading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    contentHorizontalAlignment: PropTypes.oneOf(['left', 'right', 'center']),
    contentVerticalAlignment: PropTypes.oneOf(['top', 'bottom', 'center']),
    heading: PropTypes.string,
    headingStyle: PropTypes.oneOf(['sm', 'md']),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    layout: PropTypes.oneOf(['oneColumn', 'twoColumns', 'threeColumns']),
    playButtonCallback: PropTypes.func,
    playButtonLabel: PropTypes.string,
    tag: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    textColor: PropTypes.string,
    wrapperLink: PropTypes.string,
};

export default Flexible;
