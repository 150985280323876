import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Link from '@activebrands/core-web/components/Link';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';

const List = styled('ul', {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '30px',
});

const SecondaryMenuItem = styled('li', {
    display: 'flex',
});

const SecondaryLevelOneDesktop = ({ items = [], menuValue = '', setMenuValue }) => {
    if (!items?.length) {
        return null;
    }

    return (
        <List>
            {items?.map(item => {
                return (
                    <SecondaryMenuItem $style={{ ...textStyles['Primary/20-120_-1'] }} key={item.label}>
                        <ThemeButton
                            isVolatile
                            theme="link"
                            $style={{
                                padding: '6px 12px',
                            }}
                            fontKeys="Primary/20_120_-1"
                            as={Link}
                            rel={item.follow === 'no_follow' ? 'nofollow' : undefined}
                            to={item?.link?.url}
                            onClick={() => {
                                overlay.close('navigation');
                                setMenuValue({ current: null, prev: menuValue.current });
                            }}
                        >
                            {item.label}
                        </ThemeButton>
                    </SecondaryMenuItem>
                );
            })}
        </List>
    );
};

SecondaryLevelOneDesktop.propTypes = {
    items: PropTypes.array.isRequired,
    menuValue: PropTypes.exact({
        current: PropTypes.string,
        prev: PropTypes.string,
    }).isRequired,
    setMenuValue: PropTypes.func.isRequired,
};

export default SecondaryLevelOneDesktop;
