import { useCallback, useRef } from 'react';
import Events from '@activebrands/core-web/libs/Events';
import generateRandomString from '@grebban/utils/string/generateRandomString';
import { Slider, SliderState } from './types';

const getProps = (element: HTMLElement) => {
    const style = getComputedStyle(element);

    return {
        isDraggable: style.getPropertyValue('overflow-x') === 'auto',
        isInfinite: style.getPropertyValue('--infinite') === 'true',
        isTouchDevice: style.getPropertyValue('is-touch-device') === 'true',
        slidesPerView: parseFloat(style.getPropertyValue('--spv')),
        // Safari gives 'center center' and we only care about the first value.
        snapAlign: style.getPropertyValue('--snap-align').split(' ')[0],
    };
};

export const initialState = {
    node: undefined,
    index: 0,
    slidesPerView: 1,
    isDraggable: false,
    isInfinite: false,
    isTouchDevice: false,
    offsetLeft: 0,
    slideWidth: 0,
    numberOfClones: 0,
    numberOfSlides: 0,
    snapAlign: '',
};

const createSlider = (options: Partial<SliderState>): Slider => {
    const key = useRef(generateRandomString(7));
    const values = useRef<SliderState>({
        ...initialState,
        ...options,
        key: key.current,
    });

    const getIndex = () => {
        const { snapAlign, slideWidth, numberOfClones, node } = values.current;

        if (!node) {
            return;
        }

        const scrollLeft = node.scrollLeft;

        if (scrollLeft > 0) {
            const left = scrollLeft + (snapAlign === 'center' ? node.offsetWidth / 2 - slideWidth / 2 : 0);
            return Math.round(left / slideWidth) - numberOfClones / 2;
        }

        return 0;
    };

    const subscribe: Slider['subscribe'] = callback => {
        const handler = Events.subscribe(`${key.current}.SLIDER`, callback);

        callback(values.current, {});

        return () => handler && Events.unsubscribe(`${key.current}.SLIDER`, handler);
    };

    const trigger: Slider['trigger'] = data => {
        let newState = data;

        if (typeof data === 'function') {
            newState = data(values.current);
        }

        Events.trigger(`${key.current}.SLIDER`, newState, values.current);
        values.current = newState as SliderState;
    };

    const recalculate: Slider['recalculate'] = node => {
        const props = getProps(node);
        const numberOfClones = props.isInfinite ? Math.ceil(props.slidesPerView) * 2 : 0;
        const numberOfSlides = Array.from(node.children).filter((child: any) => !child.dataset.clone).length;
        const slideWidth = node.children.length > 0 ? (node.children[0] as HTMLElement).offsetWidth : 0;

        trigger(prev => ({
            ...prev,
            ...props,
            node,
            numberOfClones,
            numberOfSlides,
            offsetLeft: node.offsetLeft,
            slideWidth,
        }));
    };

    const ref: Slider['ref'] = useCallback(node => node && recalculate(node), []);

    const slideTo: Slider['slideTo'] = (index, smooth) => {
        const { node, slidesPerView, snapAlign, slideWidth, numberOfClones } = values.current;
        let snapOffset = 0;

        if (slidesPerView > 1 && snapAlign === 'center') {
            snapOffset = slideWidth * (slidesPerView / 2) - slideWidth / 2;
        }

        if (node) {
            const slide = node.children[numberOfClones / 2 + index] as HTMLElement;

            if (!slide) {
                console.error(`couldnt find slide with index ${numberOfClones / 2 + index}`);
                return;
            }

            const left = slide.offsetLeft - node.offsetLeft - snapOffset;

            if (smooth) {
                node.scrollTo({ behavior: 'smooth', left });
            } else {
                node.scrollLeft = left;
            }

            return left;
        }
    };

    const slideToPrev = () => {
        const index = getIndex();
        index !== undefined && slideTo(index - 1, true);
    };

    const slideToNext = () => {
        const index = getIndex();
        index !== undefined && slideTo(index + 1, true);
    };

    return {
        getIndex,
        recalculate,
        ref,
        slideTo,
        slideToNext,
        slideToPrev,
        subscribe,
        trigger,
    };
};

export default createSlider;
