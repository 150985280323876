import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const columnsWrappers = {
    default: loadable(() => import('./DefaultWrapper')),
    sizeGuide: loadable(() => import('./SizeGuideWrapper.js')),
    productGallery: loadable(() => import('./ProductGalleryWrapper')),
};

const ColumnsWrapper = ({ component, children, numberOfItems, position, switchDesktopOrder, ...rest }) => {
    const checkLayout = component => {
        if (component.indexOf('productGalleryModule') !== -1) {
            return 'productGallery';
        }

        if (component.indexOf('sizeGuideModule') !== -1) {
            return 'sizeGuide';
        }

        return 'default';
    };

    const Wrapper = columnsWrappers[checkLayout(component)];

    let contentPosition;

    // Three column
    if (numberOfItems === 3) {
        contentPosition = position === 0 ? 'flex-start' : position === 1 ? 'center' : 'flex-end';
    }

    // Two column
    if (numberOfItems === 2) {
        // Checks if the column is on the left side in desktop
        if ((position === 0 && !switchDesktopOrder) || (position === 1 && switchDesktopOrder)) {
            contentPosition = [null, null, 'flex-start'];
        } else {
            contentPosition = [null, null, 'flex-end'];
        }
    }

    return (
        <Wrapper
            contentPosition={contentPosition}
            marginTop={position === 1 ? ['16px', null, 0] : 0}
            numberOfItems={numberOfItems}
            {...rest}
        >
            {children}
        </Wrapper>
    );
};

ColumnsWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    component: PropTypes.string.isRequired,
    numberOfItems: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    switchDesktopOrder: PropTypes.bool,
};

export default ColumnsWrapper;
