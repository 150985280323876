import PropTypes from 'prop-types';
import CmsModules from 'libs/storyblok/content/Modules';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';

const FooterWrapper = styled('footer', {});

const FooterCore = ({ $style = {}, footerContent = {}, children = null }) => {
    const [css] = useStyletron();

    return (
        <FooterWrapper $style={$style}>
            <div className={css({ margin: '0 auto' })}>
                {footerContent && <CmsModules isFooter modules={footerContent} />}
                {children}
            </div>
        </FooterWrapper>
    );
};

FooterCore.propTypes = {
    $style: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)].isRequired),
    footerContent: PropTypes.arrayOf(PropTypes.object),
};

export default FooterCore;
