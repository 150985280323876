import { useMemo } from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from '@activebrands/core-web/components/BackgroundImage';
import BackgroundVideo from '@activebrands/core-web/components/BackgroundVideo';
import { minBreakpoints } from '@activebrands/core-web/config/breakpoints';
import getBackgroundOverlayStyles from '@activebrands/core-web/utils/style-functions/get-background-overlay-styles';
import asArray from '@grebban/utils/array/asArray';

const BackgroundDynamic = ({ $style, background, children, backgroundOverlay, loading }) => {
    // Varaibles used by BackgroundVideo for special rendering conditions
    let videoIsRenderd = false;

    // Prepare all background in a easy to use object
    const backgrounds = useMemo(
        () =>
            asArray(background).reduce(
                (acc, bg, i) => {
                    // Clone the accumulator to make the object editable
                    const clone = { ...acc };

                    // Get the background type of current value
                    const type = bg?.type || null;

                    // Save previousType so that we can reset the background if type changes on different devices
                    clone.previousType = type || acc.previousType;

                    // differentVideoSources will be used to determine if it is sufficent to have one BackgroundVideo-component
                    if (type === 'video' && clone.previousVideoSrc !== bg.src) {
                        if (clone.previousVideoSrc && bg.src !== null) {
                            clone.differentVideoSources = true;
                        }
                        clone.previousVideoSrc = bg.src;
                    }

                    // Save the background to correct type
                    clone.colors[i] = type === 'color' ? bg : type && acc.previousType === 'color' ? 'reset' : null;
                    clone.images[i] = type === 'image' ? bg : type && acc.previousType === 'image' ? 'reset' : null;
                    clone.videos[i] = type === 'video' ? bg : type && acc.previousType === 'video' ? 'reset' : null;

                    // Use to conditionaly render different backgroundtypes only when they are necessary
                    clone.anyColors = type === 'color' || acc.anyColors;
                    clone.anyImages = type === 'image' || acc.anyImages;
                    clone.anyVideos = type === 'video' || acc.anyVideos;

                    return clone;
                },
                {
                    colors: [null],
                    images: [null],
                    videos: [null],
                    anyColors: false,
                    anyImages: false,
                    anyVideos: false,
                    previousType: null,
                    previousVideoSrc: null,
                    differentVideoSources: false,
                }
            ),
        [background]
    );

    const backgroundOverlayStyles = getBackgroundOverlayStyles(backgroundOverlay);

    return (
        <BackgroundImage
            $style={{
                position: 'absolute',
                zIndex: -2,
                backgroundRepeat: 'no-repeat',
                ...$style,
                backgroundColor: backgrounds.anyColors
                    ? backgrounds.colors.map(color => (color === 'reset' ? 'transparent' : color?.color))
                    : null,
                ...(backgrounds.anyImages
                    ? {
                          backgroundPosition: backgrounds.images.map(image => image?.position),
                          backgroundSize: backgrounds.images.map(image => image?.size),
                      }
                    : {}),
                ...backgroundOverlayStyles,
            }}
            loading={loading}
            url={
                backgrounds.anyImages
                    ? backgrounds.images.map(image => (image === 'reset' ? 'none' : image?.src))
                    : null
            }
        >
            {backgrounds.anyVideos &&
                backgrounds.videos.map((video, i) => {
                    // Only render a BackgroundVideo for actual videos
                    if (!video || video === 'reset') {
                        return null;
                    }

                    // If the video-src is the same for all devices we only need to render one BackgroundVideo
                    if (!backgrounds.differentVideoSources && videoIsRenderd) {
                        return null;
                    }
                    videoIsRenderd = true;
                    const display = [];
                    const media = [];
                    backgrounds.videos.forEach((obj, j) => {
                        // Set display-style for all devices depending on backgrounds.videos
                        const displayValue =
                            (!obj && j === 0) || (obj && obj.src !== video.src) ? 'none' : obj ? 'block' : null;
                        display.push(displayValue);
                        // Use displayValue to add correct mediaqueris used to set video src dynamically
                        if (
                            backgrounds.differentVideoSources ||
                            (!backgrounds.differentVideoSources && media.length === 0)
                        ) {
                            if (displayValue === 'block') {
                                if (j) {
                                    const query = `(min-width: ${minBreakpoints[j]}px)`;
                                    media.push(query);
                                } else {
                                    media.push('');
                                }
                            }

                            if (j && displayValue === 'none') {
                                const query = `(max-width: ${minBreakpoints[j]}px)`;
                                media.push(query);
                            }
                        }
                    });

                    const resolvedSrc = Array.isArray(video.src)
                        ? video.src.map(source => {
                              return {
                                  ...source,
                                  media: media.filter(q => q).join(' and '), // Join media queries if any
                              };
                          })
                        : video.src;

                    return (
                        <BackgroundVideo
                            $style={{ display }}
                            className="video"
                            key={`${video.src}-${i}`}
                            loading={loading}
                            media={media.filter(q => q).join(' and ')}
                            preload="none"
                            src={resolvedSrc}
                            title={video.title}
                        />
                    );
                })}
            {children}
        </BackgroundImage>
    );
};

BackgroundDynamic.propTypes = {
    $style: PropTypes.object,
    background: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.object
            // @todo: This gives "Failed prop type: checker is not a function".
            // PropTypes.oneOfType([contentBackgroundColorProp, contentImageProp, contentVideoProp])
        ),
        PropTypes.object,
    ]),
    backgroundOverlay: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.shape({
            overlay: PropTypes.string,
            overlayOpacity: PropTypes.string,
        }),
    ]),
    children: PropTypes.node,
    loading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
};

export default BackgroundDynamic;
