import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import debounce from '@grebban/utils/function/debounce';
import LoadingIcon from 'components/icons/LoadingIcon';
import StateIcon from 'components/icons/StateIcon';

const Text = styled('span', {
    ...textStyles['Miscellaneous/14_100'],
    minWidth: '16px',
    textAlign: 'center',
});

const Button = styled('button', {
    height: '32px',
    width: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 0',
    background: 'var(--color-contrast-low)',
    borderRadius: '100%',

    [media.min['desktop.sm']]: {
        height: '22px',
        width: '22px',
    },
});

const AmountIncrementer = ({ count, step = 1, loading = false, onChange, $style }) => {
    const [current, setCurrent] = useState(count);
    const [css] = useStyletron();

    useEffect(() => {
        if (current !== count) {
            setCurrent(count);
        }
    }, [count]);

    const debouncedFunc = useMemo(() => debounce(onChange), [onChange]);

    const disableStyle = {
        opacity: `${loading ? '0.6' : '1'}`,
        cursor: `${loading ? 'not-allowed' : 'pointer'}`,
    };

    const decrementAmount = () => {
        debouncedFunc(-step);
        setCurrent(current - step);
    };

    const incrementAmount = () => {
        debouncedFunc(step);
        setCurrent(current + step);
    };

    return (
        <div className={css({ display: 'flex', alignItems: 'center', gap: '12px', ...$style })}>
            <Button $style={disableStyle} type="button" onClick={loading ? () => {} : decrementAmount}>
                <StateIcon size={['12px', null, null, null, null, '8px']} state="minus" />
            </Button>

            {loading ? <LoadingIcon /> : <Text>{count}</Text>}

            <Button $style={disableStyle} type="button" onClick={loading ? () => {} : incrementAmount}>
                <StateIcon size={['12px', null, null, null, null, '8px']} state="plus" />
            </Button>
        </div>
    );
};

AmountIncrementer.propTypes = {
    $style: PropTypes.object,
    count: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    step: PropTypes.number,
};

export default AmountIncrementer;
