import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import ThemeButton from 'components/buttons/ThemeButton';
import AddToBasketIcon from 'components/icons/AddToBasketIcon';
import AddedToBasketIcon from 'components/icons/AddedToBasketIcon';
import ArrowIcon from 'components/icons/ArrowIcon';
import BellIcon from 'components/icons/BellIcon';
import CloseIcon from 'components/icons/CloseIcon';
import LoadingIcon from 'components/icons/LoadingIcon';

const icons = {
    arrow: ArrowIcon,
    default: AddToBasketIcon,
    error: CloseIcon,
    loading: LoadingIcon,
    notifyMe: BellIcon,
    success: AddedToBasketIcon,
};

const StateButton = ({
    $style = {},
    children,
    extraText = '',
    $extraTextStyles = {},
    fontKeys = ['Miscellaneous/18_100_-1', null, null, null, null, 'Miscellaneous/24_100_-2'],
    iconPosition = 'right',
    size = 'md',
    state = 'default',
    statesTexts = {},
    theme = 'inverted',
    ...rest
}) => {
    const [css] = useStyletron();
    const currentStateText = statesTexts[state] || children;
    const IconComponent = icons[state] || null;
    const hasIcon = IconComponent !== null;
    const iconStyling = state === 'arrow' && iconPosition === 'left' ? { transform: 'rotate(180deg)' } : {};

    return (
        <ThemeButton
            $style={{
                alignItems: 'center',
                display: 'flex',
                height: '48px',
                gap: '8px',
                justifyContent: 'space-between',
                ...$style,
            }}
            size={size}
            theme={theme}
            {...rest}
        >
            <span className={css({ order: iconPosition === 'left' ? 2 : 1, ...responsiveTextStyles(fontKeys) })}>
                {currentStateText}
            </span>
            {(extraText || hasIcon) && (
                <div
                    className={css({
                        alignItems: 'center',
                        display: 'flex',
                        gap: '0 24px',
                        justifyContent: 'space-between',
                        order: iconPosition === 'left' ? 1 : 2,
                    })}
                >
                    {extraText && (
                        <span
                            className={css({
                                order: iconPosition === 'left' ? 2 : 1,
                                ...textStyles['Miscellaneous/12_100'],
                                ...$extraTextStyles,
                            })}
                        >
                            {extraText}
                        </span>
                    )}
                    {IconComponent !== null && (
                        <IconComponent
                            width="16px"
                            $style={{ order: iconPosition === 'left' ? 1 : 2, ...iconStyling }}
                        />
                    )}
                </div>
            )}
        </ThemeButton>
    );
};

StateButton.propTypes = {
    $style: PropTypes.object,
    children: PropTypes.node,
    extraText: PropTypes.string,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    iconPosition: PropTypes.oneOf(['left', 'right']),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    state: PropTypes.string,
    statesTexts: PropTypes.shape({
        default: PropTypes.string,
        error: PropTypes.string,
        loading: PropTypes.string,
        reset: PropTypes.string,
        success: PropTypes.string,
    }),
    statesTextsMobile: PropTypes.shape({
        default: PropTypes.string,
        error: PropTypes.string,
        loading: PropTypes.string,
        reset: PropTypes.string,
        success: PropTypes.string,
    }),
    theme: PropTypes.string,
};

export default StateButton;
