import PropTypes from 'prop-types';
import extractBackgroundOverlays from '@activebrands/core-web/libs/storyblok/utils/extract-background-overlays';
import transformFlexibleBackgrounds from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds';
import transformFlexibleLink from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-link';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import Information from 'components/content-components/Information';

const headingFontKeys = {
    sm: ['Primary/24_120_-2', null, null, 'Primary/32_110_-2', null, 'Primary/48_100_-3'],
    md: ['Primary/32_110_-2', null, null, 'Primary/48_100_-3', null, 'Primary/60_100_-3'],
};

const InformationDataTransformerBlock = ({ content = {} }) => {
    const {
        anchorTag = [],
        backgroundDesktop = [],
        backgroundMobile = [],
        button = [],
        heading = [],
        headingStyle = 'md',
        paragraph = [],
        switchMobileOrder = false,
        switchTabletOrder = false,
        switchDesktopOrder = false,
        tag = [],
        textColor = 'black',
    } = content;

    const { buttons } = button.length > 0 ? transformFlexibleLink(button[0]) : {};

    const backgroundOverlays = extractBackgroundOverlays([backgroundMobile[0], null, null, null, backgroundDesktop[0]]);

    const transformedBackgrounds = transformFlexibleBackgrounds(
        [backgroundMobile[0], null, null, null, backgroundDesktop[0]],
        1
    );

    const headingSettings = transformHeading({
        type: heading[0]?.type,
        defaultType: 'h3',
    });

    const contentOrder = {
        image: [],
        text: [],
    };

    const unitSwitches = [switchMobileOrder, null, switchTabletOrder, null, switchDesktopOrder];

    unitSwitches.forEach(switchOrder => {
        if (switchOrder) {
            contentOrder.image.push(2);
            contentOrder.text.push(1);
        } else if (switchOrder === null) {
            contentOrder.image.push(null);
            contentOrder.text.push(null);
        } else {
            contentOrder.image.push(1);
            contentOrder.text.push(2);
        }
    });

    return (
        <Information
            $contentStyling={{ padding: ['24px 0', null, '32px 0', null, null, '24px 0'] }}
            background={transformedBackgrounds}
            backgroundOverlay={backgroundOverlays}
            buttons={buttons}
            contentOrder={contentOrder}
            heading={heading[0]?.text}
            headingFontKeys={headingFontKeys[headingStyle || 'md']}
            headingStyle={headingStyle}
            headingType={headingSettings.type}
            id={anchorTag?.[0]?.anchorTag}
            switchDesktopOrder={switchDesktopOrder}
            switchTabletOrder={switchTabletOrder}
            tag={tag[0]?.tag}
            text={paragraph[0]?.richText}
            textColor={textColor}
        />
    );
};

InformationDataTransformerBlock.propTypes = {
    content: PropTypes.object.isRequired,
};

export default InformationDataTransformerBlock;
