import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const BasketIcon = ({ $style, color = 'currentColor', height = 'auto', width = '24px', ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox="0 0 24 24" {...rest}>
            <path
                d="M1.964 4.5h20.072l1.304 17.388a1.5 1.5 0 0 1-1.496 1.612H2.156A1.5 1.5 0 0 1 .66 21.888L1.964 4.5ZM7.5 4V.5h9V4"
                stroke={color}
            />
        </SVG>
    );
};

BasketIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default BasketIcon;
