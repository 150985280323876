import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';

const SVG = styled('svg');

SVG.propTypes = {
    $style: PropTypes.shape({
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
    viewBox: PropTypes.string,
    xmlns: PropTypes.string,
};

SVG.defaultProps = {
    xmlns: 'http://www.w3.org/2000/svg',
};

export default SVG;
