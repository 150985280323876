export const bannerThemes = {
    beige: {
        background: 'var(--color-bg-banner-beige)',
        color: 'var(--color-text-banner-beige)',
    },
    black: {
        background: 'var(--color-bg-banner-black)',
        color: 'var(--color-text-banner-black)',
    },
};
