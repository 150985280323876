import PropTypes from 'prop-types';
import useIntersectionObserver from '@activebrands/core-web/hooks/useIntersectionObserver';
import { generateSizes, generateSrcSet, toImgixSrc } from '@activebrands/core-web/libs/image';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import { inBrowser, isSupportingLazy } from '@activebrands/core-web/utils/constants';
import asArray from '@grebban/utils/array/asArray';
import isObject from '@grebban/utils/object/isObject';
import toHash from '@grebban/utils/string/toHash';

const cache = {};

const getImageProps = ({ srcSet, sizes, src }) => {
    const result = {
        src,
        srcSet,
        sizes: generateSizes(sizes),
    };

    if (isObject(src)) {
        const url = src?.url;
        const widths = asArray(src.width);
        const heights = asArray(src.height);

        result.src = toImgixSrc(url, {
            auto: 'format',
            w: widths[widths.length - 1],
            h: heights[widths.length - 1],
            fm: src.format,
            bg: src.background,
            q: src.quality || 70,
        });

        if (!srcSet && widths.length > 1) {
            result.srcSet = generateSrcSet(result.src, widths, heights);
        }
    }

    return result;
};

/**
 *
 * @typedef {object} Source
 * @property {string} url - Url of image, same as src in a normal img tag.
 * @property {(number | number[])} [width] - Resize image to specified width. if its a array it will also generate srcset attr.
 * @property {(number | number[])} [height] - Resize image to specified height.
 * @property {number} [quality=70] - Output image quality.
 * @property {('jpg' | 'png' | 'webp')} [format] - Output image format.
 * @property {string} [background] - Applies a background color to output image.
 */

/**
 * Base Image
 *
 * @param {object} [$style] - Styletron style object, objectFit & objectPosition will be set on img tag. Rest on wrapper element.
 * @param {('auto' | 'lazy' | 'eager')} [loading='lazy'] - Lazy = Nativ lazyloading with polyfill. Auto = use native without pollyfill. Eager = render directly.
 * @param {func} [onLoad] - Callback that fires after image is loaded.
 * @param {string} [placeholder] - Loading placeholder image, defaults to a blurred version of src.
 * @param {string | string[]} [sizes] - Specifies the width of the image relative to viewport through different breakpoints.
 * @param {(string | Source)} src - Image source. If specified as an object, component will generate srcSet based on width array.
 * @param {string} title - The title attribute provides additional information or a description about the image. It is typically displayed as a tooltip when the user hovers over the image.
 *
 * Example:
 *
 *     <Image
 *         src={{
 *             url: 'foo.png',
 *             width: [200, 300, 400],
 *         }}
 *         loading="lazy"
 *         sizes={['100vw', null, '50vw']}
 *     />
 */

const Image = ({
    $style = {},
    alt = 'Image title missing',
    loading = 'lazy',
    onLoad,
    placeholder,
    sizes,
    src,
    srcSet,
    title,
    ...rest
}) => {
    const [css] = useStyletron();

    const cacheKey = toHash(JSON.stringify(src));
    const cachedImage = cache[cacheKey];
    const image = cachedImage || getImageProps({ sizes, src, srcSet });
    const isCritical = loading === 'eager' || cachedImage;
    const useIOSupport = !isCritical && !isSupportingLazy;
    const addNoScript = !isCritical;
    const isVisible = isCritical || inBrowser;

    const [observe, unobserve] = useIntersectionObserver(
        entry => {
            if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                const image = entry.target;

                if (image.dataset.src && image.src !== image.dataset.src) {
                    image.src = image.dataset.src;
                }

                if (image.dataset.srcSet) {
                    image.srcset = image.dataset.srcSet;
                }

                if (image.dataset.sizes) {
                    image.sizes = image.dataset.sizes;
                }

                requestAnimationFrame(() => {
                    if (image.dataset.src) {
                        delete image.dataset.src;
                    }
                    if (image.dataset.srcSet) {
                        delete image.dataset.srcSet;
                    }
                    if (image.dataset.sizes) {
                        delete image.dataset.sizes;
                    }

                    unobserve();
                });
            }
        },
        { rootMargin: '200px' }
    );

    const handleLoad = event => {
        if (!isCritical) {
            event.target.style.opacity = 1;
            event.target.parentElement.style.backgroundImage = 'unset';
        }

        if (!cache[cacheKey]) {
            cache[cacheKey] = {
                src: event.target.src,
                srcSet: event.target.srcset,
                sizes: event.target.sizes,
            };
        }

        onLoad && onLoad(event);
    };

    // Break out image styling, rest goes do wrapper.
    const { objectFit = 'cover', objectPosition = 'center' } = $style;

    const backgroundImage = isCritical ? undefined : placeholder || `url(${toImgixSrc(image.src, { w: 20 })})`;

    const wrapperStyle = {};
    if (!isCritical && backgroundImage && backgroundImage !== 'none') {
        wrapperStyle.backgroundRepeat = 'no-repeat';
        wrapperStyle.backgroundSize = '100% 100%';
        wrapperStyle.backgroundPosition = 'center';
    }

    const imgProps = useIOSupport
        ? {
              'data-sizes': image.sizes,
              'data-src-set': srcSet || image.srcSet,
              'data-src': image.src,
              ref: observe,
          }
        : {
              sizes: image.sizes,
              src: image.src,
              srcSet: srcSet || image.srcSet,
          };

    return (
        <div
            className={css({
                position: 'relative',
                width: '100%',
                height: '100%',
                backgroundImage: backgroundImage,
                ...wrapperStyle,
                ...$style,
            })}
        >
            {isVisible && (
                <img
                    {...rest}
                    {...imgProps}
                    alt={alt}
                    className={css({
                        width: '100%',
                        height: '100%',
                        verticalAlign: 'top',
                        objectFit,
                        objectPosition,
                        ...(isCritical
                            ? {}
                            : {
                                  opacity: 0,
                                  transition: 'opacity var(--transition-fast)',
                              }),
                    })}
                    key={image.src}
                    loading={loading}
                    title={title}
                    onLoad={handleLoad}
                />
            )}
            {addNoScript && (
                <noscript>
                    <img
                        {...rest}
                        alt={alt}
                        className={css({
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit,
                            objectPosition,
                        })}
                        src={image.src}
                    />
                </noscript>
            )}
        </div>
    );
};

Image.propTypes = {
    $style: PropTypes.object,
    alt: PropTypes.string,
    loading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    onLoad: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    src: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]),
    srcSet: PropTypes.string,
    title: PropTypes.string,
};

export default Image;
