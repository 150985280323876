// Disabled eslint because some of the raw data from the Storyblok contains camelcases
/* eslint-disable camelcase */

import { ButtonData, RawButtonData } from '@activebrands/core-web/libs/storyblok/storyblok';

export const transformButton = (button: RawButtonData): ButtonData => ({
    label: button.label,
    theme: button.theme,
    url: button.link?.url || button.link?.cached_url,
    rel: button.follow === 'no_follow' ? 'nofollow' : undefined,
});

/**
 * Accepts array of raw button data from Storyblok
 */
export const transformButtons = (buttons: RawButtonData[]): ButtonData[] => buttons?.map(transformButton);
