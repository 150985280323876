import PropTypes from 'prop-types';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';

const Badge = styled('p', {
    whiteSpace: 'nowrap',
    background: 'var(--color-bg-badge)',
    color: 'var(--color-text-badge)',
});

const themes = {
    'brand-primary': {
        background: 'var(--color-bg-badge-brand-primary)',
        color: 'var(--color-text-badge-brand-primary)',
    },
    'accent': {
        background: 'var(--color-bg-badge-accent)',
        color: 'var(--color-text-badge-accent)',
    },
};

const Badges = ({
    $badgesStyle = {},
    $style = {},
    limit = 2,
    items = [],
    fontKeys = 'Miscellaneous/12_100',
    overrideTheme,
}) => {
    const [css] = useStyletron();

    if (!items?.length) {
        return null;
    }

    const slicedItems = items.slice(0, limit);

    return (
        <div className={css({ display: 'flex', gap: '8px', ...$style })}>
            {slicedItems.map(badge => (
                <Badge
                    $style={{
                        ...themes[overrideTheme || badge.theme],
                        ...responsiveTextStyles(fontKeys),
                        ...$badgesStyle,
                        ...badge.style,
                    }}
                    key={badge.internalName}
                >
                    {badge.displayText}
                </Badge>
            ))}
        </div>
    );
};

Badges.propTypes = {
    $badgesStyle: PropTypes.object,
    $style: PropTypes.object,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            colorTheme: PropTypes.string,
            displayText: PropTypes.string,
            internalName: PropTypes.string,
            style: PropTypes.object,
        })
    ).isRequired,
    limit: PropTypes.number,
    overrideTheme: PropTypes.oneOf(['brand-primary', 'accent']),
};

export default Badges;
