import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';

const IconWrapper = styled('div', {
    display: 'inline-block',
    position: 'relative',
    textAlign: 'left',
    transitionProperty: 'all',
});

const Line = styled('span', {
    display: 'block',
    position: 'absolute',
    backgroundColor: 'currentColor',
    transitionProperty: 'all',
    backfaceVisibility: 'hidden',
});

const StateIcon = ({
    $style = {},
    color = 'currentColor',
    duration = 'var(--duration-fast)',
    size = '10px',
    state = 'default',
    strokeWidth = '1px',
    timingFunction = 'var(--ease)',
    ...rest
}) => {
    // Default styles will always be the same no matter what icon state
    const { defaultWrapperStyle, defaultTopStyle, defaultMiddleStyle, defaultBottomStyle, strokeWidthAsNumber } =
        useMemo(
            () => ({
                defaultWrapperStyle: {
                    width: size,
                    height: size,
                    transitionDuration: duration,
                    transitionTimingFunction: timingFunction,
                    color,
                },
                defaultTopStyle: {
                    top: '50%',
                    left: '50%',
                    width: '100%',
                    height: strokeWidth,
                    transform: 'translate3d(-50%, -50%, 0)',
                    transitionDuration: duration,
                    transitionTimingFunction: timingFunction,
                },
                defaultMiddleStyle: {
                    top: '50%',
                    left: '50%',
                    width: '100%',
                    maxWidth: '0px',
                    height: strokeWidth,
                    transform: 'translate3d(-50%, -50%, 0)',
                    transitionDuration: duration,
                    transitionTimingFunction: timingFunction,
                },
                defaultBottomStyle: {
                    top: '50%',
                    left: '50%',
                    width: '100%',
                    height: strokeWidth,
                    transform: 'translate3d(-50%, -50%, 0) rotate(90deg)',
                    transitionDuration: duration,
                    transitionTimingFunction: timingFunction,
                },
                strokeWidthAsNumber: parseInt(strokeWidth, 10),
            }),
            [size, strokeWidth, duration, timingFunction]
        );

    const halfStorkeAsNumber = strokeWidthAsNumber / 2;

    let wrapperStyle = { ...defaultWrapperStyle };
    let topStyle = { ...defaultTopStyle };
    let middleStyle = { ...defaultMiddleStyle };
    let bottomStyle = { ...defaultBottomStyle };

    // Default icon style: + icon

    // Set icon style: - icon
    if (state === 'minus') {
        bottomStyle = { ...bottomStyle, transform: 'translate3d(-50%, -50%, 0)' };
    }

    // Set icon style: x icon
    if (['reset', 'close', 'warning', 'error'].indexOf(state) > -1) {
        wrapperStyle = {
            ...wrapperStyle,
            transform: 'rotate(45deg)',
        };
    }

    // Set icon style: spinning +
    if (state === 'loading') {
        wrapperStyle = {
            ...wrapperStyle,
            animationDuration: duration,
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
                from: {
                    transform: 'rotate(0)',
                },
                to: {
                    transform: 'rotate(90deg)',
                },
            },
        };
    }

    // Set icon style: check mark
    if (state === 'success') {
        wrapperStyle = {
            ...wrapperStyle,
            transform: 'rotate(-50deg)',
        };

        topStyle = {
            ...topStyle,
            top: '60%',
            left: '15%',
            maxWidth: '70%',
            transformOrigin: `${halfStorkeAsNumber}px ${halfStorkeAsNumber}px`,
            transform: null,
        };

        bottomStyle = {
            ...bottomStyle,
            left: '15%',
            top: '30%',
            maxWidth: '35%',
            transformOrigin: `${halfStorkeAsNumber}px ${halfStorkeAsNumber}px`,
            transform: 'rotate(90deg)',
        };
    }

    // Set icon style: ←, →
    if (['arrow-left', 'arrow-right'].indexOf(state) > -1) {
        topStyle = {
            ...topStyle,
            width: '45%',
            transformOrigin: `calc(100% - ${halfStorkeAsNumber}px) ${halfStorkeAsNumber}px`,
        };

        middleStyle = {
            ...middleStyle,
            maxWidth: `calc(100% - ${strokeWidth})`,
        };

        bottomStyle = {
            ...bottomStyle,
            width: '45%',
            transformOrigin: `${halfStorkeAsNumber}px ${halfStorkeAsNumber}px`,
        };

        // Set icon style: →
        if (state === 'arrow-right') {
            topStyle = {
                ...topStyle,
                left: `calc(100% - ${strokeWidth})`,
                transform: `translate3d(calc(-1 * 100% + ${halfStorkeAsNumber}px), -${halfStorkeAsNumber}px, 0) rotate(45deg)`,
            };

            bottomStyle = {
                ...bottomStyle,
                left: `calc(100% - ${strokeWidth})`,
                transform: `translate3d(-${halfStorkeAsNumber}px, -${halfStorkeAsNumber}px, 0) rotate(135deg)`,
            };
        }

        // Set icon style: ←
        if (state === 'arrow-left') {
            topStyle = {
                ...topStyle,
                left: `${strokeWidth}`,
                transform: `translate3d(calc(-1 * 100% + ${halfStorkeAsNumber}px), -${halfStorkeAsNumber}px, 0) rotate(135deg)`,
            };

            bottomStyle = {
                ...bottomStyle,
                left: `${strokeWidth}`,
                transform: `translate3d(-${halfStorkeAsNumber}px, -${halfStorkeAsNumber}px, 0) rotate(45deg)`,
            };
        }
    }

    // Set icon style: ^, ⌄, <, >
    if (['chevron-up', 'chevron-down', 'chevron-left', 'chevron-right'].indexOf(state) > -1) {
        topStyle = {
            ...topStyle,
            width: '65%',
            transformOrigin: `calc(100% - ${halfStorkeAsNumber}px) ${halfStorkeAsNumber}px`,
        };

        bottomStyle = {
            ...bottomStyle,
            width: '65%',
            transformOrigin: `${halfStorkeAsNumber}px ${halfStorkeAsNumber}px`,
        };

        // Set icon style: ^, ⌄
        if (['chevron-up', 'chevron-down'].indexOf(state) > -1) {
            wrapperStyle = {
                ...wrapperStyle,
                transform: 'rotate(90deg)',
            };
        }

        // Set icon style: ⌄, >
        if (['chevron-right', 'chevron-down'].indexOf(state) > -1) {
            topStyle = {
                ...topStyle,
                left: `calc(80% - ${strokeWidth})`,
                transform: `translate3d(calc(-1 * 100% + ${halfStorkeAsNumber}px), -${halfStorkeAsNumber}px, 0) rotate(45deg)`,
            };

            bottomStyle = {
                ...bottomStyle,
                left: `calc(80% - ${strokeWidth})`,
                transform: `translate3d(-${halfStorkeAsNumber}px, -${halfStorkeAsNumber}px, 0) rotate(135deg)`,
            };
        }

        // Set icon style: ^, >
        if (['chevron-left', 'chevron-up'].indexOf(state) > -1) {
            topStyle = {
                ...topStyle,
                left: `calc(20% - ${strokeWidth})`,
                transform: `translate3d(calc(-1 * 100% + ${halfStorkeAsNumber}px), -${halfStorkeAsNumber}px, 0) rotate(135deg)`,
            };

            bottomStyle = {
                ...bottomStyle,
                left: `calc(20% - ${strokeWidth})`,
                transform: `translate3d(-${halfStorkeAsNumber}px, -${halfStorkeAsNumber}px, 0) rotate(45deg)`,
            };
        }
    }

    return (
        <IconWrapper $style={{ width: size, height: size, ...$style, ...wrapperStyle }} {...rest}>
            <Line $style={topStyle} />
            <Line $style={middleStyle} />
            <Line $style={bottomStyle} />
        </IconWrapper>
    );
};

StateIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    duration: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    // We use so many alternatives to make this icon more global. For example 'open' is a better
    // name then 'add' when you are toggling visibility then 'add' and 'reset'.
    state: PropTypes.oneOf([
        // + icon
        'add',
        'default',
        'open',
        'plus',
        // - icon,
        'error',
        'minus',
        // x icon
        'close',
        'error',
        'reset',
        'warning',
        // spinning +
        'loading',
        // check icon
        'success',
        // ← icon
        'arrow-left',
        // → icon
        'arrow-right',
        // ^ icon
        'chevron-up',
        // > icon
        'chevron-right',
        // ⌄ icon
        'chevron-down',
        // < icon
        'chevron-left',
    ]),
    strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    timingFunction: PropTypes.string,
    transition: PropTypes.string,
};

export default StateIcon;
