import { useState } from 'react';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Link from '@activebrands/core-web/components/Link';
import withHeightAuto from '@activebrands/core-web/components/withHeightAuto';
import media from '@activebrands/core-web/config/media';
import LipScoreServiceSmallReviewsBadge from '@activebrands/core-web/libs/lipscore/serviceReviewWidgets/LipScoreServiceSmallReviewsBadge';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ArrowButton from 'components/buttons/ArrowButton';
import JohaugIcon from 'components/icons/JohaugIcon';
import StateIcon from 'components/icons/StateIcon';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    width: '100%',
    padding: '0 20px',
    backgroundColor: 'var(--color-contrast-lowest)',
    borderRadius: '24px 24px 0px 0px',
    borderTop: '0.5px solid var(--color-contrast-lower)',

    [media.min['tablet.sm']]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: '8px',
        borderRadius: '16px 16px 0px 0px',
    },

    [media.min['desktop.sm']]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: '8px',
        borderRadius: '16px 16px 0px 0px',
    },
});

const LogoAndReviewsWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    padding: '16px 0 48px 0',
    position: 'relative',
    width: '100%',

    [media.min['tablet.sm']]: {
        gridColumn: '1 / span 4',
        gridRow: 1,
        alignItems: 'flex-start',
        padding: '32px 0 100px 0',
    },
});

const AccordionWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '48px',

    [media.min['tablet.sm']]: {
        gridColumn: '6 / span 6',
        gridRow: 2,
        flexDirection: 'row',
        marginBottom: '0',
    },
});

const FeaturedWrapper = styled('div', {
    padding: '16px 0 48px 0',

    [media.min['tablet.sm']]: {
        padding: '32px 0 48px 0',
        gridColumn: '6 / span 7',
        gridRow: 1,
    },
});

const FeaturedLinksWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
});

const MenuItem = styled('div', {
    [media.min['tablet.sm']]: {
        wordWrap: 'break-word',
        width: '33.33%',
    },
});

const MenuHeaderMobile = styled('button', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '8px',
    padding: '8px 16px 10px 16px',
    backgroundColor: 'var(--color-bg-footer-accordion)',
    borderRadius: '100px',
    height: '42px',
    lineHeight: '100%',

    [media.min['tablet.sm']]: {
        display: 'none',
    },
});

const MenuHeader = styled('button', {
    display: 'none',

    [media.min['tablet.sm']]: {
        visibility: 'visible',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '4px 8px 5px 8px',
        backgroundColor: 'unset',
        width: 'unset',
        height: 'unset',
        borderRadius: '100px',
        lineHeight: '100%',
        marginBottom: '16px',
        cursor: 'auto',
        border: '1px solid',
    },
});

const Content = withHeightAuto(
    styled('div', {
        overflow: 'hidden',
        transitionDuration: '500ms',
        transitionProperty: 'height',
        transitionTimingFunction: 'var(--ease)',
        backgroundColor: 'var(--color-border-button-tertiary)',
        borderRadius: '0 0 15px 15px',

        [media.min['tablet.sm']]: {
            height: 'auto !important',
            overflow: 'visible',
            transition: 'none',
            color: 'var(--color-text)',
        },
    })
);
const InnerContent = styled('nav', {
    padding: '4px 8px',

    [media.min['tablet.sm']]: {
        padding: 0,
    },
});

const StyledLink = styled(Link, {
    display: 'block',
    marginBottom: '16px',
});

const FooterMenuInfo = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '48px',
    ...textStyles['Secondary/14_130'],

    [media.min['tablet.sm']]: {
        paddingBottom: '176px',
    },
});

const FooterMenu = ({ items, informationHeading, featuredLinks, featuredLinksLabel, informationParagraphs }) => {
    const [css] = useStyletron();

    const headingSettings = transformHeading({ type: informationHeading?.[0]?.type, defaultType: 'h3' });

    return (
        <Wrapper>
            <LogoAndReviewsWrapper>
                <JohaugIcon $style={{ maxWidth: ['160px', null, null, '232px', null, '300px'] }} width="auto" />
                <div className={css({ marginTop: ['32px', null, null, '24px'] })}>
                    <LipScoreServiceSmallReviewsBadge type="long" />
                </div>
            </LogoAndReviewsWrapper>
            <FeaturedWrapper>
                <Heading $style={{ marginBottom: ['16px', null, null, '24px'] }} as="h3" fontKeys="Primary/20-120_-1">
                    {featuredLinksLabel}
                </Heading>
                <FeaturedLinksWrapper>
                    {featuredLinks?.map((link, linkIndex) => (
                        <ArrowButton
                            isVolatile
                            $style={{ textDecoration: 'none', margin: '0px 4px 8px 0px' }}
                            as={Link}
                            fontKeys={['Miscellaneous/14_100', null, null, 'Miscellaneous/24_100_-2']}
                            key={`${link.label}-${linkIndex}`}
                            theme="default"
                            to={link.link.url}
                        >
                            {link.label}
                        </ArrowButton>
                    ))}
                </FeaturedLinksWrapper>
            </FeaturedWrapper>
            <AccordionWrapper>
                {items.map((footerItem, footerItemIndex) => {
                    const [open, setOpen] = useState(false);
                    const [iconState, setIconState] = useState('plus');
                    return (
                        <MenuItem key={`${footerItem.label}-${footerItemIndex}`}>
                            <MenuHeaderMobile
                                onClick={() => {
                                    setOpen(!open);
                                    setIconState(iconState === 'minus' ? 'plus' : 'minus');
                                }}
                            >
                                <Paragraph fontKeys={['Miscellaneous/24_100_-2']}>{footerItem.label}</Paragraph>
                                <StateIcon
                                    $style={{ display: [null, null, null, 'none'] }}
                                    size="16px"
                                    state={iconState}
                                />
                            </MenuHeaderMobile>
                            <MenuHeader>
                                <Paragraph fontKeys={['Miscellaneous/16_100_-1']}>{footerItem.label}</Paragraph>
                            </MenuHeader>
                            <Content height={open ? 'auto' : 0}>
                                <InnerContent>
                                    {footerItem?.menuItems?.map((footerMenuItem, footerMenuItemIndex) => (
                                        <StyledLink
                                            isVolatile
                                            key={`${footerMenuItem.label}-${footerItemIndex}-${footerMenuItemIndex}`}
                                            rel={footerMenuItem.follow === 'no_follow' ? 'nofollow' : undefined}
                                            to={footerMenuItem.link?.url}
                                        >
                                            {footerMenuItem.label}
                                        </StyledLink>
                                    ))}
                                </InnerContent>
                            </Content>
                        </MenuItem>
                    );
                })}
            </AccordionWrapper>
            <div className={css({ gridColumn: '1 / span 4' })}>
                {informationHeading?.length > 0 && (
                    <Heading $style={{ marginBottom: '16px' }} as={headingSettings.type} fontKeys="Primary/20-120_-1">
                        {informationHeading[0].text}
                    </Heading>
                )}
                {informationParagraphs?.length > 0 && (
                    <FooterMenuInfo>
                        {informationParagraphs.map((paragraph, paragraphIndex) => (
                            <RichText key={`${paragraph._uid}-${paragraphIndex}`} data={paragraph.richText} />
                        ))}
                    </FooterMenuInfo>
                )}
            </div>
        </Wrapper>
    );
};

FooterMenu.propTypes = {
    featuredLinks: PropTypes.array,
    featuredLinksLabel: PropTypes.string,
    informationHeading: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            type: PropTypes.string,
        })
    ),
    informationParagraphs: PropTypes.array,
    items: PropTypes.array,
};

export default FooterMenu;
