import fm from 'format-message';
import PropTypes from 'prop-types';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import MailIcon from 'components/icons/MailIcon';

const StyledButton = styled('button', {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
});

const NotifyMeButton = ({ $style, id }) => (
    <StyledButton $style={$style} type="button" onClick={() => overlay.open('notify-me', { id })}>
        {fm('Notify me')} <MailIcon />
    </StyledButton>
);

NotifyMeButton.propTypes = {
    $style: PropTypes.object,
    id: PropTypes.string,
};

export default NotifyMeButton;
