/* eslint-disable no-use-before-define */

import { createElement } from 'react';
import PropTypes from 'prop-types';
import clone from '@grebban/utils/object/clone';
import LinkResolver from './tags/LinkResolver';
import ParagraphResolver from './tags/ParagraphResolver';

const RichTextContentPropType = PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.object), // Recursive RichTextContentPropType
    marks: PropTypes.arrayOf(PropTypes.object),
});

export const RichTextPropType = PropTypes.oneOfType([
    PropTypes.string,
    RichTextContentPropType,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(RichTextContentPropType),
]);

/**
 * Prints RichText data from Storyblok.
 *
 * If the data is only a string, it's printed directly as a paragraph.
 * If the data is an array of content, it's mapped through
 * and each item is handled by handleContent.
 *
 * @param {object} [data] - RichText data from storyblok.
 * @param {object} [tagComponents] - custom/special tags that should differ from regular tag
 * @param {function} [textCallback] - a function that affects how the text should be displayed
 */

const RichText = ({ data, tagComponents, textCallback }) => {
    if (typeof data === 'string') {
        return data;
    } else if (!data) {
        return '';
    }

    const cloneData = clone(data);

    if (Array.isArray(cloneData)) {
        return cloneData.map((item, index) => handleContent({ tagComponents, textCallback, data: item, key: index }));
    }

    return handleContent({ tagComponents, textCallback, data: cloneData, key: 0 });
};

/**
 * Handles provided data from storyblok.
 *
 * Data is deconstructed into text, type, content and marks.
 * If the content is an array, we execute createTag to create the tag or tags.
 * If we have an array of marks (for example two nested tags) we execute handleMarks
 * which handles nesting of tags and possible extra attributes such as href, target etc.
 *
 * @param {object} [data] - Object of RichText data from storyblok.
 */

const handleContent = ({ tagComponents, textCallback, data, key, asString = false }) => {
    if (!data || Object.keys(data).length <= 0) {
        return null;
    }
    const { text, type, content, marks } = data;

    if (content && Array.isArray(content)) {
        return createTag({ tagComponents, textCallback, content, type, key, asString });
    } else if (marks && Array.isArray(marks)) {
        return handleMarks({ tagComponents, textCallback, text, marks, key, asString });
    } else if (type === 'hard_break') {
        return createElement('br');
    }

    return textCallback ? textCallback(text) : text;
};

/**
 * Handles the marks of a content object
 *
 * We remove the first mark from the marks array and execute createTag with the data from the removed mark.
 * If there are extra attributes, such as hrefs or targets for links, we send those into createTag as well.
 * If we have more marks, we continue executing createTag on the first mark (i.e the next mark) of the array and then remove it,
 * nesting the tag further inside the previous tag, until we have no marks
 * left, returning the text inside deepest nested tag.
 *
 * @param {string} [text] - The content to be printed
 * @param {array} [marks] - Array of tag types and/or extra attributes
 */

const handleMarks = ({ tagComponents, textCallback, text, marks, key, asString }) => {
    if (marks.length > 0) {
        const currentMark = marks.shift();

        return createTag({
            tagComponents,
            textCallback,
            content: text,
            type: currentMark.type,
            key,
            attrs: currentMark.attrs,
            marks,
            asString,
        });
    }

    return textCallback ? textCallback(text) : text;
};

/**
 * Creates a tag from the type provided.
 *
 * If the tag can be wrapped around other tags (bold and underline for example), we first create the first tag
 * and the execute handleMarks inside it to see if it should wrap other tags.
 * If so, we do this for each tag to be wrapped until there are no tags left.
 *
 * With thats such as lists for example, we create the tag and then map through the content inside
 * and then execute handleContent on each item in order to create the correct tags inside
 * and/or return the text inside.
 *
 *
 * @param {string} [content] - The content to be printed
 * @param {string} [type] - Which tag to be created
 * @param {array} [marks] - Array of tag types and/or extra attributes
 * @param {object} [attrs] - Object of extra attributes (href, target, etc.)
 */

const createTag = ({ tagComponents, textCallback, content, type, key, attrs = null, marks = null, asString }) => {
    const validTags = {
        'bold': 'b',
        'bullet_list': 'ul',
        'italic': 'em',
        'link': LinkResolver,
        'list_item': 'li',
        'ordered_list': 'ol',
        'paragraph': ParagraphResolver,
        'strike': 'strike',
        'textStyle': ParagraphResolver,
        'underline': 'u',
        ...tagComponents,
    };

    if (typeof content === 'string' && type === 'hard_break') {
        return content;
    }

    if (!validTags[type] || type === 'doc') {
        if (Array.isArray(content)) {
            return content.map((item, index) =>
                handleContent({ tagComponents, textCallback, data: item, key: `${key}-${index}`, asString })
            );
        } else {
            return content;
        }
    }

    const markTypes = ['bold', 'italic', 'link', 'strike', 'textStyle', 'underline'];

    const children = markTypes.includes(type)
        ? handleMarks({ tagComponents, textCallback, text: content, marks, key, asString })
        : content.map((item, index) =>
              handleContent({ tagComponents, textCallback, data: item, key: `${key}-${index}`, asString })
          );

    if (asString) {
        return children;
    }

    const bulletListStyle = validTags[type] === 'ul' && {
        display: 'block',
        listStyleType: 'disc',
        marginTop: '1em',
        marginBottom: '1em',
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: '40px',
    };

    return createElement(
        validTags[type],
        {
            ...attrs,
            key,
            style: bulletListStyle || {},
        },
        children
    );
};

/**
 * Works in the same way as RichText, but returns the content as a strig.
 *
 * @param {object} [data] - RichText data from storyblok.
 * @param {object} [tagComponents] - custom/special tags that should differ from regular tag
 * @param {function} [textCallback] - a function that affects how the text should be displayed
 */

export const getRichTextAsString = data => {
    let content = [];

    if (typeof data === 'string') {
        return data;
    } else if (Array.isArray(data)) {
        content = data.map((item, index) => handleContent({ data: item, index, asString: true }))[0];
    } else if (data) {
        content = handleContent({ data, index: 0, asString: true })[0];
    }

    return content?.join('') || '';
};

RichText.propTypes = {
    data: RichTextPropType,
    tagComponents: PropTypes.object,
    textCallback: PropTypes.func,
};

RichText.defaultProps = {
    data: undefined,
    tagComponents: undefined,
    textCallback: undefined,
};

export default RichText;
