import PropTypes from 'prop-types';
import useSlider from '@activebrands/core-web/libs/slider/useSlider';
import { styled } from '@activebrands/core-web/libs/styletron';
import ArrowIcon from 'components/icons/ArrowIcon';

const Button = styled('button');

const ArrowPagination = ({ slider, $style = {} }) => {
    if (!slider) {
        return null;
    }

    const { index, isInfinite, numberOfSlides, slidesPerView } = useSlider(slider, [
        'index',
        'isInfinite',
        'numberOfSlides',
        'slidesPerView',
    ]);

    const hide = !isInfinite && numberOfSlides === slidesPerView;
    const isBeginning = isInfinite ? false : index === 0;
    const isEnd = isInfinite
        ? false
        : slidesPerView === numberOfSlides || index + Math.floor(slidesPerView) >= numberOfSlides;

    const style = {
        ...{ display: hide ? 'none' : 'block' },
        ...$style,
    };

    return (
        <>
            <Button $style={style} disabled={isBeginning} onClick={() => slider.slideToPrev()}>
                <ArrowIcon $style={{ transform: 'rotate(-180deg)' }} />
            </Button>
            <Button $style={style} disabled={isEnd} onClick={() => slider.slideToNext()}>
                <ArrowIcon />
            </Button>
        </>
    );
};

ArrowPagination.propTypes = {
    $style: PropTypes.object,
    slider: PropTypes.object.isRequired,
};

export default ArrowPagination;
