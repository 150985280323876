import PropTypes from 'prop-types';
import { bannerThemes } from 'config/branding/component-themes/banner-themes';
import textStyles from 'config/branding/textStyles';
import Link from '@activebrands/core-web/components/Link';
import Marquee from '@activebrands/core-web/components/Marquee';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';

const Wrapper = styled('div', {
    ...textStyles['Miscellaneous/12_100_500'],
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    zIndex: 'var(--zindex-banner)',

    [media.min['desktop.sm']]: {
        padding: '0 4px',
        marginTop: '4px',
        position: 'absolute',
        top: 'var(--height-header)',
    },
});

const Banner = ({ spin = true, texts = [], theme = 'beige', to = '' }) => {
    const [css] = useStyletron();
    const isLink = !!to;

    return (
        <Wrapper $as={isLink && Link} $isLink={isLink} id="banner" to={to}>
            <Marquee
                pauseOnHover
                duration={2}
                spin={spin}
                $style={bannerThemes[theme]}
                className={css({ borderRadius: ['0', null, null, null, null, '4px'] })}
            >
                {texts.map((text, index) => (
                    <div className={css({ display: 'block', whiteSpace: 'nowrap', padding: '6px 24px' })} key={index}>
                        <RichText data={text} />
                    </div>
                ))}
            </Marquee>
        </Wrapper>
    );
};

Banner.propTypes = {
    spin: PropTypes.bool,
    texts: PropTypes.array.isRequired,
    theme: PropTypes.string,
    to: PropTypes.string,
};

export default Banner;
