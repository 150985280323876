import PropTypes from 'prop-types';
import { logger } from '@activebrands/core-web/utils/logger';
import loadable from '@loadable/component';
import ErrorCatcher from 'components/ErrorCatcher';

const availableDataTransformerBlock = {
    articlePromotionModule: loadable(() =>
        import(
            '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/ArticlePromotionDataTransformerBlock'
        )
    ),
    backgroundMediaModule: loadable(() =>
        import(
            '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/BackgroundMediaDataTransformerBlock'
        )
    ),
    callToActionModule: loadable(() =>
        import('@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/CallToActionDataTransformerBlock')
    ),
    categoryModule: loadable(() =>
        import('@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/CategoryDataTransformerBlock')
    ),
    discoverModule: loadable(() =>
        import('@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/DiscoverDataTransformerBlock')
    ),
    flexibleModule: loadable(() =>
        import('@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/FlexibleDataTransformerBlock')
    ),
    informationModule: loadable(() =>
        import('@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/InformationDataTransformerBlock')
    ),
    newsletterModule: loadable(() =>
        import('@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/NewsletterDataTransformerBlock')
    ),
    productPromotionModule: loadable(() =>
        import(
            '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/ProductPromotionDataTransformerBlock'
        )
    ),
    shopTheLookModule: loadable(() =>
        import('@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/ShopTheLookDataTransformerBlock')
    ),
    textModule: loadable(() =>
        import('@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/TextDataTransformerBlock')
    ),
    videoPlayerModule: loadable(() =>
        import('@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/VideoPlayerDataTransformerBlock')
    ),
};

const CmsDataTransformerBlock = ({ block, ...rest }) => {
    if (block) {
        // See if the componentName exists inside availableDataTransformerBlock.
        const componentName = block.component || null;
        const DataTransformer = availableDataTransformerBlock[componentName] || null;

        if (DataTransformer) {
            return (
                <ErrorCatcher key={block._uid}>
                    <DataTransformer content={block} layout={componentName} {...rest} />
                </ErrorCatcher>
            );
        }

        logger.warn(`DataTransformer for ${componentName} not found.`);
        return null;
    }
    return null;
};

CmsDataTransformerBlock.propTypes = {
    block: PropTypes.shape({
        component: PropTypes.string,
        _uid: PropTypes.string,
    }),
};

export default CmsDataTransformerBlock;
