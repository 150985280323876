import { useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Image from '@activebrands/core-web/components/Image';
import AnimatedOverlay from '@activebrands/core-web/components/overlays/AnimatedOverlay';
import media from '@activebrands/core-web/config/media';
import { useDidUpdate } from '@activebrands/core-web/hooks/lifecycle';
import overlay, { useOverlaySubscriber } from '@activebrands/core-web/libs/overlay';
import useScrollLock from '@activebrands/core-web/libs/scroll';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import loadable from '@loadable/component';
import PrimaryMenu from 'components/Header/Navigation/PrimaryMenu';
import ExtraLinks from 'components/Header/Navigation/PrimaryMenu/ExtraLinks';
import FeaturedItems from 'components/Header/Navigation/PrimaryMenu/FeaturedItems';
import PrimaryMenuBackdrop from 'components/Header/Navigation/PrimaryMenu/PrimaryMenuBackdrop';
import SecondaryMenu from 'components/Header/Navigation/SecondaryMenu';
import SiteSelector from 'components/Header/Navigation/SiteSelector';
import PrimaryMenuHeading from 'components/Header/PrimaryMenuHeading';
import StateIcon from 'components/icons/StateIcon';
import Paragraph from 'components/text/Paragraph';

const SiteSelectorOverlay = loadable(() => import('components/overlays/SiteSelectorOverlay'));

const Wrapper = styled('div', ({ $isOpen }) => ({
    height: `calc(var(--vh) - var(--height-header))`,
    left: 0,
    position: 'fixed',
    bottom: 'var(--height-header)',
    transform: $isOpen ? 'translate3d(0%, 0, 0)' : 'translate3d(-100%, 0, 0)',
    width: 'calc(100vw - 8px)',
    zIndex: 'var(--zindex-header)',
    transitionDuration: 'var(--duration-fast)',
    transitionProperty: 'transform',
    transitionTimingFunction: 'var(--ease)',

    [media.min['desktop.sm']]: {
        top: 'var(--height-header)',
        bottom: 'unset',
        width: '100%',
    },
}));

const SiteSelectorWrapper = styled('div', {
    position: 'absolute',
    left: 0,
    bottom: '8px',
    width: '100%',
    height: '57px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: 'var(--color-bg-secondary)',
    borderRadius: '16px',
});

const InnerWrapper = styled('div', {
    height: '100%',
    position: 'relative',
    maxWidth: 'var(--width-overlays)',
    zIndex: 'calc(var(--zindex-header) + 2)',
});

const Section = styled('section', ({ $isOpen }) => ({
    transitionDuration: 'var(--duration-fast)',
    transitionProperty: 'transform',
    transitionTimingFunction: 'var(--ease)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'absolute',
    transform: $isOpen ? 'translate3d(0%, 0, 0)' : 'translate3d(-100%, 0, 0)',
    width: '100%',
    zIndex: 'calc(var(--zindex-header) + 3)',
    margin: '4px',
    borderRadius: '8px',

    '::after': {
        position: 'absolute',
        right: 0,
        bottom: 0,
        display: 'block',
        content: '',
        width: '100%',
        height: '100%',
        zIndex: '-1',
    },

    [media.min['desktop.sm']]: {
        top: 0,
        width: '100%',
    },
}));

const Nav = styled('nav', {
    backgroundColor: 'var(--color-bg-header)',
    color: 'var(--color-text-navigation)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: `calc(var(--vh) - var(--height-header) - 8px)`, // 8px is the margin
    overflowY: 'auto',
    width: '100%',
    zIndex: 'calc(var(--zindex-header) + 4)',
    cursor: 'default',
    borderRadius: '16px',

    [media.min['desktop.sm']]: {
        borderRadius: '8px',
    },
});

const BottomWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0 8px',
});

const SelectButton = styled('button', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    padding: '0 14px 0 8px',
    border: '1px solid var(--color-bg-button-default)',
    backgroundColor: 'var(--color-bg-button-default)',
    color: 'var(--color-text)',
    borderRadius: '78px',
});

const Navigation = ({
    alternateHrefs = [],
    menuValue = '',
    pageData = {},
    resetNavigation = () => null,
    setMenuValue = () => null,
}) => {
    const [css] = useStyletron();
    const { siteSelectorLinks } = useSiteSelectorQuery();
    const aliasId = useSelector(state => state.application.aliasId);
    const currentSite = siteSelectorLinks.find(site => site.id == aliasId);
    const { lockScroll, unlockScroll } = useScrollLock();
    const isOpen = useOverlaySubscriber('navigation');

    const [isSiteSelectorOpen, setIsSiteSelectorOpen] = useState(false);
    const { featuredLabel = '', featuredItems = [], primaryMenu = [], secondaryMenu = [], extraLinks = [] } = pageData;

    useDidUpdate(() => {
        isOpen ? lockScroll() : unlockScroll();

        if (!isOpen) {
            resetNavigation();

            if (isSiteSelectorOpen) {
                setIsSiteSelectorOpen(!isSiteSelectorOpen);
            }
        }
    }, [isOpen]);

    const toggleSiteSelector = () => {
        setIsSiteSelectorOpen(!isSiteSelectorOpen);
        setMenuValue({ current: 0, prev: menuValue.current });
    };

    const isSiteSelectorOrSubMenuOpen = isSiteSelectorOpen || !!menuValue?.current;

    return (
        <Wrapper $isOpen={isOpen}>
            <PrimaryMenuBackdrop
                $isOpen={isOpen}
                $zIndex="calc(var(--zindex-header) + 2)"
                onClick={() => resetNavigation()}
            />
            <InnerWrapper>
                <Section $isOpen={isOpen}>
                    <Nav $style={{ paddingBottom: isSiteSelectorOrSubMenuOpen ? '0' : '80px' }} id="navigation">
                        <PrimaryMenuHeading resetNavigation={resetNavigation} text={fm('Menu')} />
                        <div
                            className={css({
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: ['auto', null, null, null, '100%'],
                            })}
                        >
                            <>
                                <div className={css({ padding: '0 8px 24px 8px', zIndex: '1' })}>
                                    <FeaturedItems
                                        $style={{ marginBottom: '24px' }}
                                        items={featuredItems}
                                        label={featuredLabel}
                                    />
                                    <PrimaryMenu
                                        items={primaryMenu}
                                        menuValue={menuValue}
                                        resetNavigation={resetNavigation}
                                        setMenuValue={setMenuValue}
                                    />
                                    <SecondaryMenu
                                        items={secondaryMenu}
                                        menuValue={menuValue}
                                        setMenuValue={setMenuValue}
                                    />
                                    <ExtraLinks items={extraLinks} />
                                </div>
                            </>
                            <AnimatedOverlay
                                lockScroll
                                $style={{ overflow: 'hidden', height: '100%', width: '100%', top: '0' }}
                                alternateHrefs={alternateHrefs}
                                animation="slideLTR"
                                component={SiteSelectorOverlay}
                                id="site-selector-nav"
                            />
                            <SiteSelector
                                alternateHrefs={alternateHrefs}
                                isSiteSelectorOpen={isSiteSelectorOpen}
                                setIsSiteSelectorOpen={setIsSiteSelectorOpen}
                            />
                            <SiteSelectorWrapper $style={{ display: isSiteSelectorOrSubMenuOpen ? 'none' : 'block' }}>
                                <BottomWrapper>
                                    <SelectButton onClick={() => toggleSiteSelector()}>
                                        <div
                                            className={css({
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            })}
                                        >
                                            <div
                                                className={css({
                                                    marginRight: '16px',
                                                    borderRadius: '60px',
                                                    width: '24px',
                                                    height: '24px',
                                                    overflow: 'hidden',
                                                })}
                                            >
                                                <Image
                                                    sizes={['24px']}
                                                    src={{ url: currentSite?.icon, width: [24, 48, 72] }}
                                                />
                                            </div>
                                            <Paragraph fontKeys="Miscellaneous/16_100_-1">
                                                {currentSite?.label}
                                            </Paragraph>
                                        </div>
                                        <StateIcon
                                            state={isSiteSelectorOpen ? 'close' : 'open'}
                                            width="24px"
                                            onClick={() => overlay.close('site-selector')}
                                        />
                                    </SelectButton>
                                </BottomWrapper>
                            </SiteSelectorWrapper>
                        </div>
                    </Nav>
                </Section>
            </InnerWrapper>
        </Wrapper>
    );
};

Navigation.propTypes = {
    alternateHrefs: PropTypes.array,
    menuValue: PropTypes.object,
    pageData: PropTypes.object,
    resetNavigation: PropTypes.func,
    setMenuValue: PropTypes.func,
};

export default Navigation;
