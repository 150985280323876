import { useEffect } from 'react';
import media from '@activebrands/core-web/config/media';
import { matchesMedia } from '@activebrands/core-web/utils/responsive';

interface useDynamicMaincontentTopProps {
    desktopMainContentTop: string;
    mobileMainContentTop: string;
}

/**
 * Custom hook for dynamically the margin top of main content.
 * @param {Object} props - The hook props.
 * @param {string} props.desktopMainContentTop - The CSS value for the margin top of the content on desktop.
 * @param {string} props.mobileMainContentTop - The CSS value for the margin top of the content on mobile.
 */

const useDynamicMaincontentTop = ({ desktopMainContentTop, mobileMainContentTop }: useDynamicMaincontentTopProps) => {
    // Set the distance from the top of the page for content depending on the theme and if there is a banner.
    useEffect(() => {
        if (matchesMedia(media.min['desktop.sm'])) {
            document.documentElement.style.setProperty('--dynamic-main-content-top', desktopMainContentTop);
        } else {
            document.documentElement.style.setProperty('--dynamic-main-content-top', mobileMainContentTop);
        }
    }, [desktopMainContentTop, mobileMainContentTop]);
};

export default useDynamicMaincontentTop;
