import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Price from '@activebrands/core-web/components/Price';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ColorSwatches from 'components/product/ColorSwatches';
import Heading from 'components/text/Heading';
import { Link } from 'gatsby';

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '8px 8px 0 0',

    [media.min['desktop.sm']]: {
        padding: '8px 8px 8px 8px',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
});

const GridCardBottomSection = ({
    $style = {},
    currentVariationId = '',
    name = '',
    price = {},
    relatedVariations = [],
    uri = '',
}) => {
    const [css] = useStyletron();

    return (
        <Wrapper $style={$style}>
            <div
                className={css({
                    display: ['block', null, null, null, null, 'flex'],
                    flexDirection: 'column',
                    gap: '12px',
                    marginBottom: ['8px', null, null, null, null, '0'],
                })}
            >
                <Link
                    className={css({
                        alignSelf: 'start',
                        width: 'auto',
                    })}
                    title={name}
                    to={uri}
                >
                    <Heading fontKeys="Secondary/14_130_500">{name}</Heading>
                </Link>

                {relatedVariations.length > 1 && (
                    <ColorSwatches
                        $style={{
                            width: '100%',
                            display: ['none', null, null, null, null, 'flex'],
                            justifyContent: 'start',
                        }}
                        textstyles="Miscellaneous/10_100"
                        currentVariationId={currentVariationId}
                        relatedVariations={relatedVariations}
                    />
                )}
            </div>
            <Price
                $style={{
                    marginBottom: ['12px', null, null, null, null, '0'],
                    display: [null, null, null, null, null, 'flex'],
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    width: 'auto',
                    textAlign: ['left', null, null, null, null, 'right'],
                    lineHeight: '140%',
                }}
                activePrice={price?.salePriceWithCurrency}
                fontStyling={{ ...textStyles['Miscellaneous/12_100'] }}
                originalPrice={price?.priceWithCurrency}
            />
            {relatedVariations.length > 1 && (
                <ColorSwatches
                    $style={{
                        width: '100%',
                        display: ['flex', null, null, null, null, 'none'],
                        justifyContent: 'start',
                    }}
                    textstyles="Miscellaneous/10_100"
                    currentVariationId={currentVariationId}
                    relatedVariations={relatedVariations}
                />
            )}
        </Wrapper>
    );
};

GridCardBottomSection.propTypes = {
    $style: PropTypes.object,
    currentVariationId: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.object,
    relatedVariations: PropTypes.array,
    uri: PropTypes.string,
};

export default GridCardBottomSection;
