import PropTypes from 'prop-types';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import overlay from '@activebrands/core-web/libs/overlay';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import getTextColorStyles from '@activebrands/core-web/utils/style-functions/get-text-color-styles';
import StateButton from 'components/buttons/StateButton';
import Heading from 'components/text/Heading';

const ShopTheLook = ({
    background,
    backgroundOverlay,
    heading = '',
    headingFontKeys = ['Primary/16_120_-1', null, 'Primary/16_120_-1', null, 'Primary/16_120_-1'],
    headingType = 'h2',
    id,
    buttonLabel = '',
    buttonTheme = '',
    products = [],
    text = '',
    textColor = 'var(--color-palette-off-black)',
}) => {
    const [css] = useStyletron();
    const textColorStyle = getTextColorStyles(textColor);

    return (
        <div className={css({ position: 'relative', width: '100%', height: '100%', ...textColorStyle })} id={id}>
            <BackgroundDynamic background={background} backgroundOverlay={backgroundOverlay} loading="lazy" />
            <div
                className={css({
                    height: '100%',
                    display: 'flex',
                    flexDirection: ['column', null, 'row'],
                    padding: ['8px 8px 16px 8px', null, '12px 12px 24px 12px', null, null, '16px 16px 32px 16px'],
                })}
            >
                <div className={css({ marginTop: [null, null, 'auto'], marginRight: [null, null, '32px'] })}>
                    {heading && (
                        <Heading as={headingType} fontKeys={headingFontKeys}>
                            {heading}
                        </Heading>
                    )}
                    {text && (
                        <div className={css({ marginTop: '8px' })}>
                            <RichText data={text} />
                        </div>
                    )}
                </div>
                {buttonLabel && products.length > 0 && (
                    <StateButton
                        $style={{ marginLeft: 'auto', marginTop: 'auto' }}
                        theme={buttonTheme}
                        onClick={() => overlay.open('shop-the-look', { products })}
                    >
                        {buttonLabel}
                    </StateButton>
                )}
            </div>
        </div>
    );
};

ShopTheLook.propTypes = {
    background: PropTypes.arrayOf(PropTypes.object),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttonLabel: PropTypes.string,
    buttonTheme: PropTypes.string,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string.isRequired,
    products: PropTypes.array,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    textColor: PropTypes.string,
};

export default ShopTheLook;
