import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import { TrackingLists } from '@activebrands/core-web/libs/Tracking/constants';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import ArticleCard from 'components/ArticleCard';
import FeatureCard from 'components/FeatureCard';
import ArrowButton from 'components/buttons/ArrowButton';
import ProductCard from 'components/product/ProductCard';
import PromotionalSlider from 'components/slider/PromotionalSlider';

const PromotionSlider = ({
    $style = {},
    button = {},
    heading,
    headingFontKeys,
    headingType,
    id,
    imageSettings,
    items = [],
    slidesPerView,
    tag,
    text,
    type,
}) => {
    const [css] = useStyletron();

    const displaySlider = items.length > 2;

    return (
        <div className={css($style)}>
            <PromotionalSlider
                isDraggable
                $paginationStyle={{
                    display: displaySlider ? ['none', null, null, null, 'initial'] : 'none',
                }}
                button={button}
                heading={heading}
                headingFontKeys={headingFontKeys}
                headingType={headingType}
                id={id}
                slidesPerView={slidesPerView}
                snapAlign="none"
                spaceBetween="4px"
                tag={tag}
                text={text}
                type={type}
            >
                {items.map((item, i) => {
                    if (type === 'article') {
                        return (
                            <ArticleCard
                                {...item}
                                imageSettings={imageSettings}
                                key={item.id}
                                loading="lazy"
                                position={i}
                                trackingList={TrackingLists.PRODUCT_GALLERY}
                            />
                        );
                    }

                    if (type === 'feature') {
                        return <FeatureCard {...item} imageSettings={imageSettings} key={item.id} loading="lazy" />;
                    }

                    if (type === 'product') {
                        return (
                            <ProductCard
                                {...item}
                                media={item.media?.length > 0 ? item.media.slice(0, 1) : []}
                                key={item.id}
                                imageSettings={imageSettings}
                                loading="lazy"
                                position={i}
                                trackingList={TrackingLists.PRODUCT_GALLERY}
                            />
                        );
                    }

                    return null;
                })}
            </PromotionalSlider>
            {button.label && button.url && (
                <ArrowButton
                    $style={
                        type === 'product'
                            ? { marginTop: '24px', width: ['100%', null, 'auto'], display: ['flex', null, 'none'] }
                            : {
                                  marginTop: '24px',
                                  width: ['100%', null, null, null, null, 'auto'],
                                  display: ['flex', null, null, null, null, 'none'],
                              }
                    }
                    as={Link}
                    rel={button.rel}
                    theme={button.theme}
                    to={button.url}
                >
                    {button.label}
                </ArrowButton>
            )}
        </div>
    );
};

PromotionSlider.propTypes = {
    $style: PropTypes.object,
    button: PropTypes.object,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    imageSettings: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }).isRequired,
    items: PropTypes.array,
    slidesPerView: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    tag: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.oneOf(['product', 'article']),
};

export default PromotionSlider;
