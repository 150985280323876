import { useEffect, useRef } from 'react';

/**
 * NewsletterWidget has several events that can be listened to.
 *
 * @see {@link https://learn.microsoft.com/en-us/dynamics365/customer-insights/journeys/developer/realtime-marketing-form-client-side-extensibility#custom-events}
 */
const Events = () => {
    const widgetsRef = useRef<NodeListOf<Element> | null>(null);

    useEffect(() => {
        const handleFormRender = () => {
            const widgets = document.querySelectorAll('[component="newsletterWidget"]');
            
            widgetsRef.current = widgets;

            widgets.forEach((widget, i) => {
                const form = widget.querySelector('form');

                if (form) {
                    form.id = `newsletter-widget-${i}`;
                }
            });
        };

        document.addEventListener("d365mkt-afterformload", handleFormRender);

        return () => {
            document.removeEventListener("d365mkt-afterformload", handleFormRender);
        };
    }, []);

    return null;
};

export default Events;