import PropTypes from 'prop-types';
import transformLayout from '@activebrands/core-web/libs/storyblok/utils/transform-layout';
import Text from 'components/content-components/Text';

const TextDataTransformerBlock = ({ content = {}, isTwoColumns = false, isThreeColumns = false }) => {
    const { anchorTag = [], items = [], textAlignment = 'left' } = content;

    const layout = transformLayout({ isTwoColumns, isThreeColumns });

    return (
        <Text contentHorizontalAlignment={textAlignment} layout={layout} id={anchorTag?.[0]?.anchorTag} items={items} />
    );
};

TextDataTransformerBlock.propTypes = {
    content: PropTypes.object,
    isThreeColumns: PropTypes.bool,
    isTwoColumns: PropTypes.bool,
};

export default TextDataTransformerBlock;
