//TODO: Try to adjust this to new theme handling
import { inBrowser } from '@activebrands/core-web/utils/constants';

const body = inBrowser ? getComputedStyle(document.body) : undefined;

const headerHeights = {
    accountHeightHeader: 20,
    bannerHeight: 24,
    headerHeight: 56,
    topLogoMobileHeight: 32,
    ...(inBrowser
        ? {
              accountHeightHeader: parseInt(body?.getPropertyValue('--height-header-account') || '20', 10),
              bannerHeight: parseInt(body?.getPropertyValue('--height-banner') || '24', 10),
              headerHeight: parseInt(body?.getPropertyValue('--height-header') || '56', 10),
              topLogoMobileHeight: parseInt(body?.getPropertyValue('--height-top-logo-mobile') || '32', 10),
          }
        : {}),
};

export default headerHeights;
