import { RawLinkData, TransformedData } from '@activebrands/core-web/libs/storyblok/storyblok';
import { transformButtons } from './transform-buttons';

/**
 * Accepts raw link and color data from Storyblok and interperates it either as an array of buttons or as a wrapperLink
 * Color are not used here, it is passed on to transformButtons
 */

export default (linkData: RawLinkData): TransformedData => {
    let buttons;
    let wrapperLink;

    switch (linkData?.component) {
        case 'buttons':
            buttons = transformButtons(linkData.buttons);
            break;

        case 'link':
            wrapperLink = linkData?.link?.url;
            break;

        default:
            break;
    }

    return {
        buttons,
        wrapperLink,
    };
};
