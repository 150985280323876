import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';

const StyledTag = styled('span', {});

const Tag = ({ $style = {}, as = 'span', children, fontKeys = 'Miscellaneous/16_100_-1', ...rest }) => {
    const fontStyle = responsiveTextStyles(fontKeys);
    const style = {
        border: '1px solid currentColor',
        borderRadius: '40px',
        display: 'inline-block',
        padding: '4px 8px',
        ...fontStyle,
        ...$style,
    };

    return (
        <StyledTag $style={style} as={as} {...rest}>
            {children}
        </StyledTag>
    );
};

Tag.propTypes = {
    $style: PropTypes.object,
    as: PropTypes.string,
    children: PropTypes.node,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Tag;
