const allowedTypes: { [key: string]: string } = { h1: 'h1', h2: 'h2', h3: 'h3', h4: 'h4', h5: 'h5', h6: 'h6' };
const allowedStyles: { [key: string]: string } = { sm: 'sm', md: 'md', lg: 'lg' };

type Settings = {
    type: string;
    defaultType: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    style?: string;
    defaultStyle?: 'sm' | 'md' | 'lg';
};

const getAllowedStylesSafely = (str: string | undefined) => str && allowedStyles[str];

const TransformHeading = ({ type, defaultType, style, defaultStyle }: Settings) => ({
    type: allowedTypes[type] || allowedTypes[defaultType] || 'h2',
    style: getAllowedStylesSafely(style) || getAllowedStylesSafely(defaultStyle) || 'md',
});

export default TransformHeading;
