import { forwardRef, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import useIntersectionObserver from '@activebrands/core-web/hooks/useIntersectionObserver';
import { styled } from '@activebrands/core-web/libs/styletron';
import asArray from '@grebban/utils/array/asArray';
import generateRandomString from '@grebban/utils/string/generateRandomString';

const VideoElement = styled('video', {
    width: '100%',
    height: '100%',
});

/**
 * This is a base component of an HTML5 video.
 * @author Hampus Lindholm & Anton Pedersen
 * @version 1.0 (Atomic-css: This style is updated to work with gatsby and styletron.)
 *
 * @param {bool} autoPlay - Should the video autoplay
 * @param {string} className - Used by styled components (Add option to style this component)
 * @param {bool} controls - Option to show or hide video controls (play/pause/mute)
 * @param {bool} loop - Should the video start over or stop
 * @param {bool} muted - Should the video be muted by default
 * @param {bool} playsInline - Option to use playsInline (Important on iOS devices)
 * @param {string|object|string[]|object[]} src - One or serveral video sources as strings or objects
 * @param {bool} poster - The source to the poster (Image shown before video is playing/loaded)
 */

const Video = forwardRef(
    ({ autoPlay, className, controls, loading, loop, muted, playsInline, poster, src, ...rest }, ref) => {
        const isLazy = loading === 'lazy';
        const stringified = JSON.stringify(src);
        // HTML5 video has problems rendering when src is replaced
        // Create a random number used in key to force rerenders
        const internalRef = useRef(null);
        const randomVideoID = useMemo(() => generateRandomString(9), [stringified]);

        const [observe, unobserve] = useIntersectionObserver(
            entry => {
                if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                    const video = entry.target;

                    if (video.childElementCount === 0 && !video.src) {
                        video.src = video.dataset.src;

                        requestAnimationFrame(() => delete video.dataset.src);
                    } else {
                        const mediaQuery = video.childNodes[0].getAttribute('media');

                        // Update video src if the element doesn't have a mediaQuery or if mediaQuery matches and the element is missing a src
                        if (!mediaQuery || window.matchMedia(mediaQuery).matches) {
                            // set 'src' attribute for all video element source children
                            // this is okay since the browser will only load the first source that it supports
                            for (let i = 0; i < video.childNodes.length; i++) {
                                if (!video.childNodes[i].src) {
                                    video.childNodes[i].src = video.childNodes[i].dataset.src; // this is done by copying its 'data-src' attribute
                                    requestAnimationFrame(() => delete video.childNodes[i].dataset.src);
                                }
                            }
                        }
                    }

                    video.load();
                    // video.play();

                    unobserve();
                }
            },
            { rootMargin: '200px' }
        );

        const sources = useMemo(
            () =>
                asArray(src).map(source => ({
                    key: source.src || source,
                    type: source.type || 'video/mp4',
                    [`${isLazy ? 'data-' : ''}src`]: source.src || source,
                    media: source.media,
                })),
            [isLazy, stringified]
        );

        const setRef = node => {
            if (node && node !== internalRef.current) {
                internalRef.current = node;
                ref && ref(node);
                isLazy && observe(node);
            }
        };

        return (
            <VideoElement
                autoPlay={autoPlay}
                className={className}
                controls={controls}
                key={`${randomVideoID}_${src}`}
                loop={loop}
                muted={muted}
                playsInline={playsInline}
                poster={poster}
                ref={setRef}
                {...rest}
            >
                {sources.map(({ key, ...props }) => (
                    <source key={key} {...props} />
                ))}
            </VideoElement>
        );
    }
);

Video.propTypes = {
    autoPlay: PropTypes.bool,
    className: PropTypes.string,
    controls: PropTypes.bool,
    loading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    playsInline: PropTypes.bool,
    poster: PropTypes.string,
    src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            type: PropTypes.string,
        }),
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    src: PropTypes.string.isRequired,
                    type: PropTypes.string,
                }),
            ])
        ),
    ]),
};

Video.defaultProps = {
    autoPlay: false,
    className: '',
    controls: false,
    loading: 'eager',
    loop: false,
    muted: true,
    playsInline: false,
    poster: '',
    src: undefined,
};

export default Video;
