import { useEffect, useState } from 'react';
import { initialState } from './create-slider';
import { Slider, SliderState } from './types';

const useSlider = (slider: Slider, keys?: (keyof SliderState)[]) => {
    const [state, setState] = useState<SliderState>(initialState);

    useEffect(() => {
        return slider.subscribe((newState, oldState) => {
            let shouldUpdate = true;

            if (keys) {
                if (!keys.length) {
                    shouldUpdate = false;
                } else {
                    shouldUpdate = keys.some(k => newState[k] !== oldState[k]);
                }
            }
            shouldUpdate && setState(newState);
        });
    }, []);

    return state;
};

export default useSlider;
