import { BackgroundOverlay } from '@activebrands/core-web/libs/storyblok/storyblok';
import asArray from '@grebban/utils/array/asArray';

const getBackgroundStyling = (overlayData: BackgroundOverlay) => {
    const backgroundColor = `rgba(0, 0, 0, ${overlayData?.overlayOpacity})`;

    const allowedOverlays: Record<string, string> = {
        'none': 'none',
        'cover': `${backgroundColor}`,
        'in-out': `radial-gradient(${backgroundColor}, transparent)`,
        'out-in': `radial-gradient(transparent, ${backgroundColor})`,
        'top-bottom': `linear-gradient(${backgroundColor}, transparent)`,
        'bottom-top': `linear-gradient(transparent, ${backgroundColor})`,
    };

    return overlayData?.overlay ? allowedOverlays[overlayData.overlay] : null;
};

const getBackgroundOverlayStyles = (backgroundOverlay: BackgroundOverlay) => {
    const backgroundOverlays = asArray(backgroundOverlay) as BackgroundOverlay[];

    if (backgroundOverlays.length > 0 && backgroundOverlays.some(b => b && b.overlay !== 'none')) {
        return {
            '::after': {
                display: 'block',
                content: '""',
                position: 'absolute',
                top: '0px',
                bottom: '0px',
                left: '0px',
                right: '0px',
                zIndex: -1,
                background: backgroundOverlays.map(overlay => getBackgroundStyling(overlay)),
            },
        };
    }

    return {};
};

export default getBackgroundOverlayStyles;
