import useReInitWidgets from '@activebrands/core-web/libs/lipscore/hooks/useReInitWidgets';
import { styled } from '@activebrands/core-web/libs/styletron';

const Wrapper = styled('div', {});

interface LipScoreServiceSmallReviewsBadgeProps {
    $style?: any;
    type: 'short' | 'long';
    widgetHeight: string;
    widgetWidth: string;
}

/**
 * LipScoreProductRatingWidget component displays a product rating widget from LipScore.
 *
 * @param {LipScoreServiceSmallReviewsBadgeProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 *
 * @see {@link https://members.lipscore.com/widgets}
 */

const LipScoreServiceSmallReviewsBadge = ({
    $style = {},
    type = 'short',
    widgetHeight = '100%',
    widgetWidth = '100%',
}: LipScoreServiceSmallReviewsBadgeProps) => {
    useReInitWidgets();

    return (
        <Wrapper
            $style={$style}
            className={`lipscore-service-review-badge-small-${type}`} // Option to add lipscore-no-border and lipscore-no-separator
            data-ls-widget-height={widgetHeight} // Widget height in px|%]
            data-ls-widget-width={widgetWidth} // Widget width in px|%]
        />
    );
};

export default LipScoreServiceSmallReviewsBadge;
