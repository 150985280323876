import PropTypes from 'prop-types';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import { isStoryblokPreview } from '@activebrands/core-web/utils/constants';
import PromotionGrid from 'components/content-components/Promotional/PromotionGrid';
import PromotionSlider from 'components/content-components/Promotional/PromotionSlider';

const layouts = {
    slider: PromotionSlider,
    grid: PromotionGrid,
};

const ProductPromotion = ({
    $style = {},
    button = {},
    columnSizes = [],
    heading,
    headingFontKeys = ['Primary/32_110_-2', null, 'Primary/40_100_-2'],
    headingType,
    id,
    imageSettings = {},
    layout = 'slider',
    products = [],
    slidesPerView,
    tag,
    text,
}) => {
    const [css] = useStyletron();

    if (isStoryblokPreview) {
        return (
            <div
                className={css({
                    padding: '64px 0',
                    textAlign: 'center',
                })}
            >
                <p>Products are unavailable in preview</p>
            </div>
        );
    }

    if (products.length < 1) {
        return null;
    }

    const PromotionLayout = layouts[layout];

    return (
        <PromotionLayout
            $style={$style}
            button={button}
            columnSizes={columnSizes}
            heading={heading}
            headingFontKeys={headingFontKeys}
            headingType={headingType}
            id={id}
            imageSettings={imageSettings}
            items={products}
            slidesPerView={slidesPerView}
            tag={tag}
            text={text}
            type="product"
        />
    );
};

ProductPromotion.propTypes = {
    $style: PropTypes.object,
    button: PropTypes.object,
    columnSizes: PropTypes.array,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    imageSettings: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }).isRequired,
    layout: PropTypes.string,
    products: PropTypes.array,
    slidesPerView: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    tag: PropTypes.string,
    text: PropTypes.string,
};

export default ProductPromotion;
