import { breakpointLabels, minBreakpoints } from '@activebrands/core-web/config/breakpoints';
import { generateSrc } from '@activebrands/core-web/libs/image';
import { RawSplitData } from '@activebrands/core-web/libs/storyblok/storyblok';
import transformFlexibleBackground, {
    RawBackgroundData,
    TransformedBackground,
    TransformedBackgroundTypes,
} from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-background';
import transformImage from '@activebrands/core-web/libs/storyblok/utils/transform-image';
import asArray from '@grebban/utils/array/asArray';
import transformBynderMedia, { addBynderParamsForGenerateSrc, derivativeBreakpointMap } from './transform-bynder-media';

/**
 * Transforming backgrounds from Storyblok and generates background images based on a multiplication factor.
 *
 * @param {string | string[]} [flexibleBackgrounds] - Raw background data from storyblock.
 * @param {number | number[]} [factors = 1] - Multiplication factor for calculating image sizes, multiplied by the associated breakpoint.
 *
 * How factors works:
 * It is used to add a width to images in like this (?w=*number*).
 * The parameters is set using the width of the screen at the various breakpoints.
 * The task of the factor is to choose how large the image should be in each breakpoint in relation to the width of the screen.
 *
 * Factor example: At breakpoint 768-1023, the image is half the size of the screen, then the factor 0.5 must be used and the result will be 1024 * 0.5 = 512.
 *
 * Function example: transformFlexibleBackgrounds([backgroundMobile[0], null, backgroundDesktop[0]], [1, null, 0.5])
 *
 * Breakpoint settings: [0-767, 768-1023, 1024-1439, 1440-(maxWidth - 1), maxWidth->]
 *
 */

export default (
    flexibleBackgrounds: (RawBackgroundData | RawSplitData | null)[],
    factors: number | number[] = 1,
    settings: Record<string, any> = {}
): TransformedBackgroundTypes[] => {
    // Transforms breakpoints to match breakpoint settings in styletron
    const transformedBreakpoints = minBreakpoints.slice(2);
    // Make sure the flexible background array and breakpoints array are the same length
    while (flexibleBackgrounds.length < transformedBreakpoints.length) {
        flexibleBackgrounds.push(null);
    }

    // If there is only one value, put it in an array
    const factorsArray = asArray(factors);

    const transformedBackgrounds = flexibleBackgrounds.reduce(
        (acc: any, currentBackground: any, index: number) => {
            // Save current background url. The value can be null, then the previous value must be used.
            const background = currentBackground || acc.latestBackground;

            // Save current factor. The value can be null, then the previous value must be used.
            const factor = factorsArray[index] || acc.latestFactor;

            // Save current derivativeBreakpoint. The value can be null, then the previous value must be used.
            const derivativeBreakpoint =
                derivativeBreakpointMap[breakpointLabels[index]] || acc.latestDerivativeBreakpoint;
            const newDerivativeBreakpoint = derivativeBreakpoint !== acc.latestDerivativeBreakpoint;

            // Multiply current factor with current breakpoint = (?w=*width*).
            const width = Math.ceil(factor * transformedBreakpoints[index]);

            let transformedBackground: TransformedBackground | null = null;

            // Generate new images sources if the background is a background image or background split
            if (background) {
                switch (background.component) {
                    case 'backgroundBynder':
                        // Transform background image or video
                        transformedBackground = transformBynderMedia(background, breakpointLabels[index]);

                        if (transformedBackground && transformedBackground.type === 'image') {
                            // Bynder has a max limit of 2000px for the bugest of width or height
                            let baseMaxHeight = 2000;
                            let baseMaxWidth = 2000;
                            const originalRatio =
                                transformedBackground.width && transformedBackground.height
                                    ? transformedBackground.width / transformedBackground.height
                                    : 0;

                            // Use the original ratio to calculate the base max width and height
                            if (originalRatio) {
                                if (originalRatio > 1) {
                                    baseMaxHeight = Math.floor(baseMaxHeight / originalRatio);
                                } else {
                                    baseMaxWidth = Math.floor(baseMaxWidth * originalRatio);
                                }
                            }

                            // Generate new image source
                            transformedBackground.src = generateSrc(
                                transformedBackground.src,
                                addBynderParamsForGenerateSrc({
                                    auto: 'format',
                                    focusPointX: transformedBackground?.focusPointX,
                                    focusPointY: transformedBackground?.focusPointY,
                                    gravity: transformedBackground?.gravity,
                                    maxHeight: Math.min(transformedBackground.height || 0, baseMaxHeight),
                                    maxWidth: Math.min(transformedBackground.width || 0, baseMaxWidth),
                                    quality: 75,
                                    ratio: transformedBackground?.ratio,
                                    width,
                                })
                            );
                        }

                        // We only want to add new responsive backgrounds for bynder videos if there is a new derivative breakpoint
                        if (transformedBackground?.type === 'video' && !newDerivativeBreakpoint) {
                            transformedBackground = null;
                        }

                        break;

                    case 'backgroundImage':
                        // Transform background image
                        transformedBackground = transformImage(background);

                        // Generate new image source
                        transformedBackground.src = generateSrc(transformedBackground.src, {
                            w: width,
                            auto: 'format',
                            q: 75,
                        });

                        break;

                    case 'backgroundSplit': {
                        // Transform background split
                        transformedBackground = transformFlexibleBackground(background, settings);

                        // Put the value in an array if there is only one background in the split to be able to use the map
                        const backgroundSplit = asArray<TransformedBackgroundTypes>(transformedBackground);

                        transformedBackground = backgroundSplit.map((splitBackground: TransformedBackgroundTypes) => {
                            let transformedSplit;

                            if (splitBackground?.type === 'image') {
                                // No need to transform, it was done when we transformed the split
                                transformedSplit = splitBackground;

                                if (transformedSplit) {
                                    // Generate new image source if the background is a background image
                                    transformedSplit.src = generateSrc(transformedSplit.src, {
                                        w: width / 2,
                                        auto: 'format',
                                        q: 75,
                                    });
                                }
                            } else {
                                // If it's a video or color, just run a transform
                                transformedSplit = splitBackground;
                            }

                            return transformedSplit;
                        });

                        break;
                    }
                    // If it's a video or color, just run a transform
                    default:
                        transformedBackground = transformFlexibleBackground(background, settings);
                        break;
                }
            }

            const generateDynamicBackgroundSizes =
                background?.component === 'backgroundBynder' ||
                background?.component === 'backgroundSplit' ||
                transformedBackground?.type === 'image';

            return {
                backgrounds: [...acc.backgrounds, transformedBackground],
                latestBackground: generateDynamicBackgroundSizes ? background : undefined,
                latestFactor: factor,
                latestDerivativeBreakpoint: derivativeBreakpoint,
            };
        },
        {
            backgrounds: [],
            latestBackground: null,
            latestFactor: null,
            derivativeBreakpoint: null,
        }
    );

    return transformedBackgrounds.backgrounds;
};
