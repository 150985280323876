import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const LogOutIcon = ({ $style = {}, color = 'currentColor', height = 'auto', width = '16px', ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox="0 0 16 17" {...rest}>
            <path d="M9 1H2.5C1.39543 1 0.5 1.89543 0.5 3V14C0.5 15.1046 1.39543 16 2.5 16H9" stroke={color} />
            <path d="M15.4556 8.50003L4 8.5" stroke={color} />
            <path d="M11.7852 12L15.5 8.74365V8.24365L11.7852 5" stroke={color} />
        </SVG>
    );
};

LogOutIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    fill: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default LogOutIcon;
