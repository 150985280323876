import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useCheckoutUrlQuery from 'queries/useCheckoutUrlQuery';
import Link from '@activebrands/core-web/components/Link';
import useNotifications from '@activebrands/core-web/hooks/useNotifications';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import ArrowButton from 'components/buttons/ArrowButton';
import StateIcon from 'components/icons/StateIcon';
import ProductCardMini from 'components/product/ProductCardMini';
import Heading from 'components/text/Heading';
import NotificationWrapper from 'components/wrappers/NotificationWrapper';
import { useStyletron } from 'styletron-react';

const Header = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 16px 8px 16px',
    paddingBottom: '8px',
    borderBottom: '1px solid var(--color-contrast-low)',
});

const Buttons = styled('div', {
    display: 'flex',
    gap: '8px',
    padding: '0 8px',
});

const buttonStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'left',
    flex: 1,
    ...textStyles['Miscellaneous/14_100'],
};

const AddedToBasket = ({
    color,
    containImage = false,
    id,
    media,
    name,
    onMouseEnter,
    onMouseLeave,
    price,
    size,
    uri = '',
}) => {
    const [css] = useStyletron();
    const { checkoutPage } = useCheckoutUrlQuery();
    const notifications = useNotifications();

    const closeNotificationOnViewCart = () => {
        overlay.open('basket');
        notifications.remove(id);
    };

    return (
        <NotificationWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Header>
                <Heading fontKeys="Primary/20-120_-1">{fm('Added to cart')}</Heading>
                <StateIcon
                    $style={{ cursor: 'pointer' }}
                    size="14px"
                    state="close"
                    onClick={() => notifications.remove(id)}
                />
            </Header>
            <div className={css({ padding: '0 16px', marginBottom: '16px' })}>
                <ProductCardMini
                    color={color}
                    containImage={containImage}
                    id={id}
                    media={media}
                    name={name}
                    size={size}
                    uri={uri}
                    price={price}
                    type="order"
                />
            </div>
            <Buttons>
                <ArrowButton $style={buttonStyles} size="sm" onClick={() => closeNotificationOnViewCart()}>
                    {fm('View cart')}
                </ArrowButton>
                <ArrowButton
                    $style={buttonStyles}
                    as={Link}
                    size="sm"
                    theme="inverted"
                    to={checkoutPage}
                    onClick={() => notifications.remove(id)}
                >
                    {fm('Checkout')}
                </ArrowButton>
            </Buttons>
        </NotificationWrapper>
    );
};

AddedToBasket.propTypes = {
    color: PropTypes.string,
    containImage: PropTypes.bool,
    id: PropTypes.string.isRequired,
    media: PropTypes.object,
    name: PropTypes.string.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    price: PropTypes.object,
    size: PropTypes.string.isRequired,
    uri: PropTypes.string,
};

export default AddedToBasket;
