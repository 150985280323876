import PropTypes from 'prop-types';
import { transformButton } from '@activebrands/core-web/libs/storyblok/utils/transform-buttons';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import ProductPromotion from 'components/content-components/Promotional/ProductPromotion';

const ProductPromotionDataTransformerBlock = ({ content, slidesPerView, maxNumberOfProducts = 10, imageSettings }) => {
    const { anchorTag = [], button = {}, heading = [], paragraph, productSelection, layout, tag = [] } = content;

    const headingSettings = transformHeading({
        type: heading[0]?.type,
        defaultType: 'h3',
    });

    const transformedButton = button?.[0] ? transformButton(button?.[0]) : {};
    const slicedProducts = productSelection?.[0]?.products?.slice(0, maxNumberOfProducts);

    return (
        <ProductPromotion
            button={transformedButton}
            columnSizes={[6, null, 3]}
            heading={heading[0]?.text}
            headingType={headingSettings.type}
            id={anchorTag[0]?.anchorTag}
            imageSettings={imageSettings}
            layout={layout}
            products={slicedProducts}
            slidesPerView={slidesPerView}
            tag={tag[0]?.tag}
            text={paragraph[0]?.richText}
        />
    );
};

ProductPromotionDataTransformerBlock.propTypes = {
    content: PropTypes.object.isRequired,
    imageSettings: PropTypes.object,
    maxNumberOfProducts: PropTypes.number,
    slidesPerView: PropTypes.array,
};

export default ProductPromotionDataTransformerBlock;
