import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const LoadingIcon = ({ $style, color = 'currentColor', height = 'auto', speed = '1s', width = '16px', ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG
            $style={{
                animationDuration: speed,
                animationIterationCount: 'infinite',
                transformOrigin: '50% 50%',
                display: 'inline-block',
                animationTimingFunction: 'linear',
                animationName: {
                    '0%': {
                        transform: 'rotate(0deg)',
                    },
                    '100%': {
                        transform: 'rotate(360deg)',
                    },
                },
                ...style,
            }}
            fill="none"
            viewBox="0 0 16 16"
            {...rest}
        >
            <path
                d="M7.11.553a7.5 7.5 0 0 1 4.675.972l-.547.807a6.528 6.528 0 1 0 2.935 3.546l.895-.387A7.5 7.5 0 1 1 7.111.553Z"
                stroke={color}
            />
            <path
                d="M7.11.553a7.5 7.5 0 0 1 4.675.972l-.547.807a6.528 6.528 0 1 0 2.935 3.546l.895-.387A7.5 7.5 0 1 1 7.111.553Z"
                stroke={color}
            />
        </SVG>
    );
};

LoadingIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    speed: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default LoadingIcon;
