import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import asArray from '@grebban/utils/array/asArray';
import useIntersectionObserver from '@activebrands/core-web/hooks/useIntersectionObserver';
import { styled } from '@activebrands/core-web/libs/styletron';

const Element = styled('div', ({ $backgroundImage }) => ({
    width: '100%',
    height: '100%',
    ...($backgroundImage
        ? {
              backgroundImage: $backgroundImage,
              backgroundPosition: '50% 50%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
          }
        : {}),
}));

const BackgroundImage = forwardRef(({ $style, loading, placeholder = 'none', style, url, ...rest }, ref) => {
    const internalRef = useRef(null);

    const isLazy = url && loading === 'lazy';
    const backgroundImage = url && asArray(url).map(u => (u ? (u === 'none' ? 'none' : `url("${u}")`) : u));

    const [observe, unobserve] = useIntersectionObserver(
        entry => {
            if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                entry.target.style.backgroundImage = null;

                unobserve();
            }
        },
        { rootMargin: '200px' }
    );

    const setRef = node => {
        if (node && node !== internalRef.current) {
            internalRef.current = node;
            ref && ref(node);
            isLazy && observe(node);
        }
    };

    return (
        <Element
            {...rest}
            $style={{
                ...$style,
                backgroundImage,
            }}
            ref={setRef}
            style={{
                ...style,
                backgroundImage: isLazy ? placeholder : undefined,
            }}
        />
    );
});

BackgroundImage.propTypes = {
    $style: PropTypes.object,
    loading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    placeholder: PropTypes.string,
    style: PropTypes.object,
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

BackgroundImage.defaultProps = {
    $style: {},
    loading: 'eager',
    placeholder: undefined,
    style: {},
    url: null,
};

export default BackgroundImage;
