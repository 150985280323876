import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Price from '@activebrands/core-web/components/Price';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ColorSwatches from 'components/product/ColorSwatches';
import Heading from 'components/text/Heading';
import { Link } from 'gatsby';

const Wrapper = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'end',
    padding: '8px 12px 0 0',
});

const SingleViewCardBottomSection = ({
    $style = {},
    currentVariationId = '',
    name = '',
    price = {},
    relatedVariations = [],
    uri = '',
}) => {
    const [css] = useStyletron();

    return (
        <Wrapper $style={$style}>
            <Link
                className={css({
                    alignSelf: 'start',
                    marginBottom: '12px',
                    width: '70%',
                })}
                title={name}
                to={uri}
            >
                <Heading fontKeys="Secondary/14_130_500">{name}</Heading>
            </Link>
            <Price
                $style={{
                    alignSelf: 'start',
                    textAlign: 'right',
                    width: '30%',
                }}
                activePrice={price?.salePriceWithCurrency}
                fontStyling={{ ...textStyles['Miscellaneous/12_100'] }}
                originalPrice={price?.priceWithCurrency}
            />
            {relatedVariations.length > 1 && (
                <ColorSwatches
                    $style={{
                        width: '100%',
                        display: ['flex', null, null, null, null, 'none'],
                        justifyContent: 'start',
                    }}
                    textstyles="Primary/12_100_-1_500"
                    currentVariationId={currentVariationId}
                    relatedVariations={relatedVariations}
                />
            )}
        </Wrapper>
    );
};

SingleViewCardBottomSection.propTypes = {
    $style: PropTypes.object,
    currentVariationId: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.object,
    relatedVariations: PropTypes.array,
    uri: PropTypes.string,
};

export default SingleViewCardBottomSection;
