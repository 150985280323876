import fm from 'format-message';
import useAccountQuery from 'queries/useAccountQuery';
import BaseLink from '@activebrands/core-web/components/Link';
import overlay, { useOverlaySubscriber } from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import { useTypedSelector } from '@activebrands/core-web/state/store';
import AccountIcon from 'components/icons/AccountIcon';

const Link = styled(BaseLink, {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: '10px',
});

const AuthLink = () => {
    const isAuthenticated = useTypedSelector(s => s.auth.isAuthenticated);
    const isOpen = useOverlaySubscriber('unauthenticated');
    const { accountPage } = useAccountQuery();

    const linkProps = isAuthenticated
        ? {
              to: accountPage,
              title: fm('My pages'),
          }
        : {
              role: 'button',
              tabIndex: '0',
              onClick: () => {
                  overlay.closeAll();
                  if (!isOpen) {
                      overlay.toggle('unauthenticated', { redirectUri: accountPage });
                  }
              },
          };

    return (
        <Link isVolatile {...linkProps}>
            <AccountIcon
                $style={{
                    display: 'block',
                    width: ['22px', null, null, null, null, '16px'],
                    height: ['22px', null, null, null, null, '16px'],
                }}
            />
        </Link>
    );
};

export default AuthLink;
