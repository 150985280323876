import PropTypes from 'prop-types';
import extractBackgroundOverlays from '@activebrands/core-web/libs/storyblok/utils/extract-background-overlays';
import { transformButtons } from '@activebrands/core-web/libs/storyblok/utils/transform-buttons';
import transformFlexibleBackgrounds from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds';
import transformFlexibleLink from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-link';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import Discover from 'components/content-components/Discover';

const DiscoverDataTransformerBlock = ({ content = {}, isHero = false }) => {
    const {
        anchorTag = [],
        backgroundDesktop = [],
        backgroundMobile = [],
        categoriesTag = '',
        categoryButtons = [],
        heading = [],
        headingStyle = 'md',
        paragraph = [],
        link = [],
        tag = [],
        textColor = 'beige',
    } = content;

    const { buttons, wrapperLink } = link?.length > 0 ? transformFlexibleLink(link[0]) : {};

    const backgroundOverlays = extractBackgroundOverlays([backgroundMobile[0], null, null, null, backgroundDesktop[0]]);

    const transformedBackgrounds = transformFlexibleBackgrounds(
        [backgroundMobile[0], null, null, null, backgroundDesktop[0]],
        1
    );

    const headingSettings = transformHeading({
        type: heading?.[0]?.type,
        defaultType: isHero ? 'h1' : 'h3',
    });

    return (
        <Discover
            background={transformedBackgrounds}
            backgroundLoading={isHero ? 'eager' : 'lazy'}
            backgroundOverlay={backgroundOverlays}
            buttons={buttons}
            categoriesTag={categoriesTag}
            categoryButtons={categoryButtons?.length ? transformButtons(categoryButtons) : []}
            heading={heading?.[0]?.text}
            headingStyle={headingStyle}
            headingType={headingSettings.type}
            id={anchorTag?.[0]?.anchorTag}
            tag={tag?.[0]?.tag}
            text={paragraph?.[0]?.richText}
            textColor={textColor}
            wrapperLink={wrapperLink}
        />
    );
};

DiscoverDataTransformerBlock.propTypes = {
    content: PropTypes.object.isRequired,
    isHero: PropTypes.bool,
};

export default DiscoverDataTransformerBlock;
