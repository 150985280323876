import PropTypes from 'prop-types';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import getTextColorStyles from '@activebrands/core-web/utils/style-functions/get-text-color-styles';
import ButtonsList from 'components/buttons/ButtonsList';
import Heading from 'components/text/Heading';
import Tag from 'components/text/Tag';

const Wrapper = styled('div', {
    position: 'relative',
    height: '100%',
});

const ContentWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: '100%',
    margin: '0 auto',
    maxWidth: 'var(--site-corridor-max-width)',
    width: '100%',

    [media.min['desktop.sm']]: {
        padding: '24px 16px',
        flexDirection: 'row',
        justifyContent: 'unset',
    },
});

const Content = styled('div', {
    height: '75%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '8px 8px 0 8px',
    width: '100%',
    zIndex: 1,

    [media.min['mobile.lg']]: {
        paddingRight: '30%',
    },

    [media.min['desktop.sm']]: {
        left: 'unset',
        padding: '0 5% 0 0',
        top: 'unset',
        transform: 'unset',
        width: '70%',
        justifyContent: 'flex-end',
    },
});

const CategoriesWrapper = styled('div', {
    position: 'relative',
    width: '100%',
    height: '25%',
    overflowX: ['auto', null, null, null, 'hidden'],

    [media.min['mobile.lg']]: {
        height: 'auto',
        position: 'unset',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        marginBottom: 'calc(var(--height-header) + 40px)',
    },

    [media.min['desktop.sm']]: {
        width: '30%',
        marginBottom: '0',
    },
});

const Categories = styled('div', {
    width: '100%',
    position: 'absolute',
    left: 0,
    bottom: 'calc(var(--height-header) + 40px)',

    [media.min['mobile.lg']]: {
        position: 'unset',
        height: 'auto',
        zIndex: 1,
    },

    [media.min['desktop.sm']]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: '0',
    },
});

const StyledTag = styled('tag', {
    display: 'block',
    marginBottom: '12px',
    padding: '0 8px',

    [media.min['desktop.sm']]: {
        width: '400px',
        padding: 0,
    },
});

const Discover = ({
    background,
    backgroundLoading = 'eager',
    backgroundOverlay,
    buttons = [],
    categoriesTag,
    categoryButtons = [],
    heading,
    headingFontKeys = ['Primary/32_110_-2', null, null, 'Primary/72_100_-3', null, 'Primary/96_100_-3'],
    headingType,
    id,
    tag,
    text,
    textColor = 'beige',
    wrapperLink,
}) => {
    const [css] = useStyletron();

    const textColorStyle = getTextColorStyles(textColor);

    return (
        <Wrapper id={id}>
            <Link className={css(textColorStyle)} to={wrapperLink}>
                <BackgroundDynamic
                    background={background}
                    backgroundOverlay={backgroundOverlay}
                    loading={backgroundLoading}
                />
                <ContentWrapper $style={textColorStyle}>
                    <Content>
                        {tag && <Tag $style={{ width: 'fit-content', marginBottom: '24px' }}>{tag}</Tag>}
                        {heading && (
                            <Heading $style={{ paddingRight: '24px' }} as={headingType} fontKeys={headingFontKeys}>
                                {heading}
                            </Heading>
                        )}
                        {text && (
                            <div
                                className={css({
                                    marginTop: ['8px', null, '16px'],
                                    width: ['100%', null, null, null, null, '50%'],
                                })}
                            >
                                <RichText data={text} />
                            </div>
                        )}
                        <ButtonsList
                            $style={{
                                width: 'fit-content',
                                display: 'inline-flex',
                                flexDirection: ['column', null, null, null, 'row'],
                                marginTop: '24px',
                                alignContent: 'flex-start',
                            }}
                            buttonStyling={{
                                padding: ['12px 16px', null, null, null, null, '8px 16px'],
                            }}
                            buttons={buttons}
                        />
                    </Content>
                    <CategoriesWrapper>
                        <Categories>
                            {categoriesTag && <StyledTag>{categoriesTag}</StyledTag>}
                            <ButtonsList
                                $style={{
                                    flexDirection: ['row', null, null, null, null, 'column'],
                                    width: ['auto', null, null, null, '100%'],
                                    maxWidth: [null, null, null, null, null, '400px'],
                                    overflowX: ['auto', null, null, null, 'hidden'],
                                    '-webkit-overflow-scrolling': 'touch',
                                    '::-webkit-scrollbar': {
                                        display: 'none',
                                    },

                                    // Adds the same space as gap in front and after
                                    '::before': {
                                        display: ['block', null, null, null, null, 'none'],
                                        content: '""',
                                        width: '0px',
                                    },
                                    '::after': {
                                        display: ['block', null, null, null, null, 'none'],
                                        content: '""',
                                        width: '0px',
                                    },
                                }}
                                buttonStyling={{
                                    height: ['40px', null, null, null, '42px'],
                                    padding: ['12px', null, null, null, null, '8px 16px'],
                                }}
                                buttons={categoryButtons}
                                fontKeys={['Miscellaneous/14_100', null, null, null, null, 'Miscellaneous/24_100_-2']}
                            />
                        </Categories>
                    </CategoriesWrapper>
                </ContentWrapper>
            </Link>
        </Wrapper>
    );
};

Discover.propTypes = {
    background: PropTypes.arrayOf(
        PropTypes.object
        // @todo: This gives "Failed prop type: checker is not a function".
        // PropTypes.oneOfType([contentBackgroundColorProp, contentImageProp, contentVideoProp])
    ),
    backgroundLoading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    categoriesTag: PropTypes.string,
    categoryButtons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    tag: PropTypes.string,
    text: PropTypes.object,
    textColor: PropTypes.string,
    wrapperLink: PropTypes.string,
};

export default Discover;
