interface rawLayouts {
    isOneColumn?: boolean;
    isTwoColumns?: boolean;
    isThreeColumns?: boolean;
    isFourColumns?: boolean;
}

export type Layout = 'oneColumn' | 'twoColumns' | 'threeColumns' | 'fourColumns';

export default ({
    isTwoColumns = false,
    isThreeColumns = false,
    isFourColumns = false,
    isOneColumn = false,
}: rawLayouts): Layout => {
    if (isOneColumn) {
        return 'oneColumn';
    }
    if (isTwoColumns) {
        return 'twoColumns';
    }
    if (isThreeColumns) {
        return 'threeColumns';
    }
    if (isFourColumns) {
        return 'fourColumns';
    }
    return 'oneColumn';
};
