import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import RoundedImage from 'components/RoundedImage';

const Wrapper = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '4px',
});

const ColorSwatches = ({ $style = {}, currentVariationId = '', relatedVariations = [], maxColors = 4 }) => {
    const [css] = useStyletron();

    return (
        <Wrapper $style={$style}>
            {relatedVariations.slice(0, maxColors).map(({ colorHex, colorImageUrl, colorName, id, name, uri }) => (
                <Link key={uri} title={colorName} to={uri}>
                    <RoundedImage
                        $style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '100px',
                            backgroundColor: `#${colorHex}`,
                        }}
                        isSelected={id === currentVariationId}
                        name={name}
                        src={colorImageUrl ? { width: 14, url: colorImageUrl, format: 'jpg' } : undefined}
                    />
                </Link>
            ))}
            {relatedVariations.length > maxColors && (
                <span className={css({ marginLeft: '4px', display: 'block' })}>
                    + {relatedVariations.length - maxColors}
                </span>
            )}
        </Wrapper>
    );
};

ColorSwatches.propTypes = {
    $style: PropTypes.object,
    currentVariationId: PropTypes.string,
    maxColors: PropTypes.number,
    relatedVariations: PropTypes.array,
};

export default ColorSwatches;
