import { styled } from '@activebrands/core-web/libs/styletron';
import convertHexToBase64 from '@activebrands/core-web/utils/convert-hex-to-base64';

const PrimaryMenuBackdrop = styled('span', ({ $cursorColor, $isOpen, $zIndex }) => ({
    top: 0,
    left: 'var(--width-overlays)',
    right: 0,
    bottom: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    cursor: `url('data:image/svg+xml,%3Csvg width="34" height="34" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1 1l32 32M33 1L1 33" stroke="${
        $cursorColor ? convertHexToBase64($cursorColor) : '%23FFFFFF'
    }"/%3E%3C/svg%3E'), auto`,
    zIndex: $zIndex,
    visibility: $isOpen ? 'visible' : 'hidden',
}));

export default PrimaryMenuBackdrop;
