import fm from 'format-message';
import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';
import CloseIcon from 'components/icons/CloseIcon';

const CloseButton = ({ $style = {}, handleOnClick }) => (
    <ThemeButton
        $style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            display: 'flex',
            alignItems: 'center',
            gap: '0 8px',
            justifyContent: 'center',
            ...$style,
        }}
        type="button"
        onClick={() => handleOnClick()}
    >
        <CloseIcon width="14px" /> {fm('Close')}
    </ThemeButton>
);

CloseButton.propTypes = {
    $style: PropTypes.object,
    handleOnClick: PropTypes.func.isRequired,
};

export default CloseButton;
