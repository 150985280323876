import { Children, ReactNode, cloneElement, useEffect, useLayoutEffect, useRef } from 'react';
import useSlider from '@activebrands/core-web/libs/slider/useSlider';
import mouseEvents from './mouse-events';
import { Slider } from './types';

interface Props {
    slider: Slider;
    children: ReactNode[];
    bundleSectionId: number;
    bundleUpdateIndex: Function;
}

interface Internals {
    pos: { left: number; x: number };
    isHolding: boolean;
    isLooped: boolean;
    slide: null | HTMLElement;
}

const useIsoLayoutEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect;

const withSlides = (Component: any) => {
    return ({ bundleSectionId, bundleUpdateIndex, slider, children, ...rest }: Props) => {
        const {
            node,
            isDraggable,
            isInfinite,
            isTouchDevice,
            numberOfSlides,
            numberOfClones,
            snapAlign,
            slideWidth,
            slidesPerView,
            offsetLeft,
        } = useSlider(slider, [
            'node',
            'isDraggable',
            'isInfinite',
            'isTouchDevice',
            'numberOfSlides',
            'numberOfClones',
            'snapAlign',
            'slideWidth',
            'slidesPerView',
            'offsetLeft',
        ]);

        const internal = useRef<Internals>({
            pos: { left: 0, x: 0 },
            isHolding: false,
            isLooped: false,
            slide: null,
        });

        useIsoLayoutEffect(() => {
            if (node && isInfinite) {
                slider.slideTo(0);
            }
        }, [node, isInfinite, numberOfClones]);

        const getIndex = () => {
            let index = slider.getIndex();

            if (index === undefined) {
                return -1;
            }

            if (index < 0) {
                index += numberOfSlides;
            } else if (index >= numberOfSlides) {
                index -= numberOfSlides;
            }

            return index;
        };

        const updateIndex = () => {
            const index = getIndex();
            const slide = node?.children[index + numberOfClones / 2] as HTMLElement;

            if (internal.current.slide !== slide) {
                internal.current.slide?.removeAttribute('data-current');
                slider.trigger(prev => ({ ...prev, index }));
                internal.current.slide = slide;
                slide.setAttribute('data-current', 'true');
                if (bundleSectionId && bundleUpdateIndex) {
                    bundleUpdateIndex(getIndex(), bundleSectionId);
                }
            }
        };

        const getScrollLeft = (index: number) => {
            if (!node) {
                return -1;
            }

            let snapOffset = 0;

            if (slidesPerView > 1 && snapAlign === 'center') {
                snapOffset = slideWidth * (slidesPerView / 2) - slideWidth / 2;
            }

            return (node.children[Math.floor(index)] as HTMLElement).offsetLeft - node.offsetLeft - snapOffset;
        };

        let timer: any = null;
        const onScroll = ({ target }: any) => {
            if (isInfinite) {
                let lastChild = numberOfSlides - numberOfClones / 2;
                let startPosition = 0;

                if (snapAlign === 'center') {
                    const halfSlide = slideWidth / 2;
                    startPosition = halfSlide * slidesPerView - halfSlide;
                    lastChild = target.children.length - numberOfClones - 1;
                }
                if (target.scrollLeft <= startPosition) {
                    slider.slideTo(lastChild);
                    internal.current.isLooped = true;
                }

                if (target.scrollLeft >= getScrollLeft(target.children.length - slidesPerView)) {
                    slider.slideTo(0);
                    internal.current.isLooped = true;
                }
            }

            updateIndex();

            if (timer) {
                clearTimeout(timer);
            }

            // Fires when user stopped scrolling.
            timer = setTimeout(() => {
                // Viewport has changed, update config.
                if (offsetLeft !== target.offsetLeft) {
                    return slider.recalculate(target);
                }

                // Prevents bugg where last slide get stuck.
                if (isInfinite && target.scrollLeft + 10 >= getScrollLeft(target.children.length - slidesPerView)) {
                    slider.slideTo(0);
                }
            }, 100);
        };

        const childs = Children.toArray(children);
        const clones = numberOfClones / 2;

        const preSlice = isInfinite && [childs.length - clones, childs.length];
        const postSlice = isInfinite && [0, clones];

        if (node && !isTouchDevice && isDraggable) {
            rest = {
                ...rest,
                ...mouseEvents(node, internal),
            };
        }

        return (
            <Component {...rest} ref={slider.ref} onScroll={onScroll}>
                {preSlice &&
                    childs.slice(...preSlice).map((el: any, i) => cloneElement(el, { 'data-clone': preSlice[0] + i }))}
                {children}
                {postSlice &&
                    childs
                        .slice(...postSlice)
                        .map((el: any, i) => cloneElement(el, { 'data-clone': postSlice[0] + i }))}
            </Component>
        );
    };
};

export default withSlides;
