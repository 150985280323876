import Image from '@activebrands/core-web/components/Image';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import type { ImageLoading, ImageSettings } from '@activebrands/core-web/types/components/image';

interface RoundedImageProps {
    $selectStyle?: object;
    $style?: object;
    borderColor?: string;
    isSelected?: boolean;
    loading?: ImageLoading;
    name?: string;
    selectedBorderColor?: string;
    src?: ImageSettings;
}

const RoundedImage = ({
    $selectStyle = {},
    $style = {},
    borderColor = 'var(--color-swatches-border-color)',
    isSelected = false,
    selectedBorderColor = 'var(--color-swatches-border-color-highlight)',
    loading = 'eager',
    name = '',
    src,
    ...rest
}: RoundedImageProps) => {
    const [css] = useStyletron();
    const hasImageSrc = !!src?.url;

    const selectedStyle = isSelected
        ? {
              border: `1px solid ${selectedBorderColor}`,

              ':after': {
                  content: '""',
                  position: 'absolute',
                  zIndex: 2,
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  boxShadow: `inset 0px 0px 0px 1px var(--color-swatches-border-color-highlight-inner)`,
                  ...$selectStyle,
              },
          }
        : {};

    const style = {
        height: '14px',
        width: '14px',
        position: 'relative',
        borderRadius: '50%',
        overflow: 'hidden',
        border: `1px solid ${borderColor}`,
        ...selectedStyle,
        ...$style,
    };

    return hasImageSrc ? (
        <Image $style={style} loading={loading} src={src} title={name} {...rest} />
    ) : (
        <div className={css(style)} title={name} />
    );
};

export default RoundedImage;
