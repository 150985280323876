export default (node: HTMLElement, ref: any) => ({
    onMouseDown: (e: MouseEvent) => {
        node.style.cursor = 'grabbing';
        node.style.setProperty('scroll-snap-type', 'unset');

        ref.current.isHolding = true;
        ref.current.pos = {
            left: node.scrollLeft,
            x: e.clientX,
        };
    },
    onMouseMove: (e: MouseEvent) => {
        if (ref.current.isHolding) {
            if (ref.current.isLooped) {
                ref.current.isLooped = false;
                ref.current.pos = {
                    x: e.clientX,
                    left: node.scrollLeft,
                };
            }

            node.scrollLeft = ref.current.pos.left - Math.ceil(e.clientX - ref.current.pos.x);
        }
    },
    onMouseUp: () => {
        // @todo: Remove this and fix bug in macOS Safari.
        // slider.slideTo(getIndex(), true);

        node.style.removeProperty('cursor');
        node.style.removeProperty('scroll-snap-type');
        ref.current.isHolding = false;
    },
});
