import PropTypes from 'prop-types';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import ArrowButton from 'components/buttons/ArrowButton';
import Heading from 'components/text/Heading';
import Tag from 'components/text/Tag';
import { Link } from 'gatsby';

const Information = ({
    $contentStyling = {},
    background,
    backgroundOverlay,
    buttons = [],
    contentOrder = [{ image: 1 }, { text: 2 }],
    heading,
    headingFontKeys = '',
    headingType = 'h2',
    id = '',
    tag,
    text,
}) => {
    const [css] = useStyletron();
    const hasButtons = buttons.length > 0;

    return (
        <div className={css({ display: 'flex', flexDirection: 'column' })} id={id}>
            <div
                className={css({
                    position: 'relative',
                    order: contentOrder.image,
                    aspectRatio: ['var(--ratio-vertical-primary)', null, 'var(--ratio-horizontal-primary)'],
                })}
            >
                <BackgroundDynamic background={background} backgroundOverlay={backgroundOverlay} loading="lazy" />
            </div>
            <div className={css({ order: contentOrder.text })}>
                <div className={css($contentStyling)}>
                    {tag && <Tag $style={{ marginBottom: ['16px', null, '24px'] }}>{tag}</Tag>}
                    <div
                        className={css({
                            display: ['flex', null, 'grid'],
                            flexDirection: 'column',
                            gridTemplateColumns: 'repeat(12, 1fr)',
                        })}
                    >
                        {heading && (
                            <div
                                className={css({
                                    width: '100%',
                                    gridColumn: ['1 / span 12', null, null, '1 / span 6', '1 / span 5'],
                                })}
                            >
                                <Heading
                                    $style={{
                                        marginBottom: ['12px', null, '32px', null, null, '0'],
                                    }}
                                    as={headingType}
                                    fontKeys={headingFontKeys}
                                >
                                    {heading}
                                </Heading>
                            </div>
                        )}
                        <div
                            className={css({
                                display: ['flex', null, 'grid'],
                                gridColumn: ['1 / span 12', null, null, null, null, '7 / span 6'],
                                flexWrap: 'wrap',
                                gridTemplateColumns: 'repeat(6, 1fr)',
                                gap: [null, null, null, null, '0'],
                            })}
                        >
                            {text && (
                                <div
                                    className={css({
                                        gridColumn: [null, '1 / span 6', '1 / span 4', '1 / span 3'],
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        marginBottom: ['24px', null, '0'],
                                    })}
                                >
                                    <RichText data={text} />
                                </div>
                            )}
                            <div
                                className={css({
                                    width: ['100%', 'auto'],
                                    display: ['flex', null, null, null, 'grid'],
                                    gridColumn: [null, null, '6 / span 1', null, null, '5 / span 2'],
                                    alignSelf: 'end',
                                })}
                            >
                                {hasButtons &&
                                    buttons.map((button, index) => (
                                        <ArrowButton
                                            $style={{ width: '100%' }}
                                            as={Link}
                                            rel={button.rel}
                                            theme={button.theme}
                                            to={button.url}
                                            key={`${button?.label}-${index}`}
                                        >
                                            {button.label}
                                        </ArrowButton>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Information.propTypes = {
    $contentStyling: PropTypes.object,
    background: PropTypes.arrayOf(PropTypes.object),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    contentOrder: PropTypes.array,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    tag: PropTypes.string,
    text: PropTypes.object,
};

export default Information;
