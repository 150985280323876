import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';

const List = styled('ul', {
    display: ['none', null, null, null, null, 'flex'],
    flexDirection: 'row',
    gap: '4px',
    paddingLeft: ['0', null, null, null, null, '16px'],
    margin: '0',
});

const LevelOneItem = styled('li', {
    alignItems: 'center',
    display: 'flex',
    height: 'var(--height-header)',
    justifyContent: 'center',
    position: 'relative',
});

const PrimaryLevelOneDesktop = ({ items = [], menuValue = '', buttonColor, resetNavigation, setMenuValue }) => {
    if (!items?.length) {
        return null;
    }

    return (
        <List>
            {items.map(levelOneItem => {
                const hasSubmenu = levelOneItem.submenus?.length > 0;
                const isActive = menuValue.current === levelOneItem._uid;

                // Only add isVolatile prop if there is a level one submenu that is a link
                // The reason to do this is because DOM doesn't recognize isVolatile if not a link
                const conditionalProps = {};
                if (!hasSubmenu) {
                    conditionalProps.isVolatile = true;
                }

                return (
                    <LevelOneItem $active={isActive} key={levelOneItem.label}>
                        <ThemeButton
                            isVolatile
                            theme="link"
                            $style={{
                                padding: '4px 8px',
                                color: buttonColor,
                            }}
                            fontKeys={['Miscellaneous/14_100_500']}
                            as={hasSubmenu ? null : Link}
                            rel={levelOneItem?.follow === 'no_follow' ? 'nofollow' : undefined}
                            to={levelOneItem?.link?.url}
                            onClick={() => {
                                if (hasSubmenu) {
                                    overlay.closeAll();
                                    overlay.open('navigation');
                                    setMenuValue({
                                        current: hasSubmenu ? levelOneItem._uid : null,
                                        prev: menuValue.current,
                                    });
                                } else {
                                    resetNavigation();
                                }
                            }}
                            {...conditionalProps}
                        >
                            {levelOneItem.label}
                        </ThemeButton>
                    </LevelOneItem>
                );
            })}
        </List>
    );
};

PrimaryLevelOneDesktop.propTypes = {
    buttonColor: PropTypes.string,
    items: PropTypes.array.isRequired,
    menuValue: PropTypes.exact({
        current: PropTypes.string,
        prev: PropTypes.string,
    }).isRequired,
    resetNavigation: PropTypes.func,
    setMenuValue: PropTypes.func.isRequired,
};

export default PrimaryLevelOneDesktop;
