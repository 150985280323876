import debounce from "lodash/debounce";
import Events from "@activebrands/core-web/libs/Events";
import { ProductEvents } from "@activebrands/core-web/libs/Events/types";

let impressionQueue = [];

const debounceImpressionHandler = debounce(() => {
    const rows = impressionQueue
        .map(impression => impression.gridRow)
        .filter((value, index, array) => array.indexOf(value) === index)
        .filter(value => !!value)
        .sort();

    rows.forEach(row => {
        let currency = "";
        const products = [];
        const rowImpressions = [];
        impressionQueue = impressionQueue.map(impression => {
            if (row === impression.gridRow) {
                rowImpressions.push(impression);
                return undefined;
            }

            return impression;
        }).filter(value => value !== undefined);

        rowImpressions.forEach(impression => {
            currency = impression.currency;
            products.push(impression.product);
        });

        Events.trigger(ProductEvents.IMPRESSION, {
            currencyCode: currency,
            products: products,
        });
    });
}, 250);

export const impressionDebounce = (product, currency, gridRow) => {
    impressionQueue.push({product, currency, gridRow});
    debounceImpressionHandler();
};