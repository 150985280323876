import PropTypes from 'prop-types';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import asArray from '@grebban/utils/array/asArray';

// These functions are also used in ColumnsScrollLayout
export const getRowGutter = gutterWidth => asArray(gutterWidth).map(width => (width ? `-${width / 2}px` : null));
export const getRowAfter = gutterHeight => asArray(gutterHeight).map(height => (height ? `-${height}px` : null));
export const getColumnGutter = gutterWidth => asArray(gutterWidth).map(width => (width ? `${width / 2}px` : null));
export const getColumnAfter = gutterHeight => asArray(gutterHeight).map(height => (height ? `${height}px` : null));

/**
 * Display an array of components in a column layout based on columnQuantity.
 * If the number of items (components) is greater than columnQuantity the columns will wrap to a new row.
 *
 * @param {node[]} items - An array of JSX components to render inside each column. Set size prop to overwrite columnSizes.
 * @param {number} columnBase - The base number of columns used. As default 12 is the base.
 * @param {number||number[]} columnSizes - The size of each column. Will be calculated as "columnSizes / columnBase" for percentage.
 * @param {number||number[]}  gutterWidth - The distance between each column. The number will be interpreted as "px".
 * @param {number||number[]}  gutterHeight - The distance after each block. The number will be interpreted as "px".
 */

const ColumnsLayout = ({
    $style,
    columnBase = 12,
    columnSizes = [12, 6, null, 4, 3],
    gutterHeight = 16,
    gutterWidth = 16,
    items = [],
    ...rest
}) => {
    const [css] = useStyletron();
    const rowGutter = getRowGutter(gutterWidth);
    const rowAfter = getRowAfter(gutterHeight);
    const columnGutter = getColumnGutter(gutterWidth);
    const columnAfter = getColumnAfter(gutterHeight);

    return (
        <div
            className={css({
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                marginLeft: rowGutter,
                marginRight: rowGutter,
                marginBottom: rowAfter,
                ...$style,
            })}
            {...rest}
        >
            {items.map((Component, i) => {
                if (!Component) {
                    return null;
                }

                const columnsSize = Component?.props?.$size || columnSizes;
                const columnsSizeAsArray = asArray(columnsSize);

                const columnStyles = columnsSizeAsArray.reduce(
                    (acc, width) => {
                        const maxWidth = width ? `${(width / columnBase) * 100}%` : null;
                        const flex = width ? `0 0 ${(width / columnBase) * 100}%` : null;

                        return {
                            maxWidth: [...acc.maxWidth, maxWidth],
                            flex: [...acc.flex, flex],
                        };
                    },
                    { maxWidth: [], flex: [] }
                );

                return (
                    <div
                        className={css({
                            flex: columnStyles.flex,
                            marginBottom: columnAfter,
                            maxWidth: columnStyles.maxWidth,
                            paddingLeft: columnGutter,
                            paddingRight: columnGutter,
                            ...Component.props?.columnStyle,
                        })}
                        key={`${i}-${Component?.key}`}
                    >
                        {Component}
                    </div>
                );
            })}
        </div>
    );
};

ColumnsLayout.propTypes = {
    $style: PropTypes.object,
    columnBase: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    columnSizes: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    gutterHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    gutterWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
    items: PropTypes.arrayOf(PropTypes.node),
};

export default ColumnsLayout;
