import PropTypes from 'prop-types';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import LogotypeIcon from 'components/icons/LogotypeIcon';
import StateIcon from 'components/icons/StateIcon';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    position: 'relative',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px',

    [media.min['desktop.sm']]: {
        marginBottom: '16px',
    },
});

const PrimaryMenuHeading = ({ text = '', resetNavigation = () => null }) => {
    const [css] = useStyletron();

    return (
        <Wrapper>
            <Paragraph
                $style={{ color: 'var(--color-text-subtle)' }}
                fontKeys={['Miscellaneous/16_100_-1', null, 'Miscellaneous/12_100']}
            >
                {text}
            </Paragraph>

            <div
                className={css({
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                })}
            >
                <LogotypeIcon $style={{ display: ['block', null, null, null, null, 'none'] }} width="80px" />
            </div>

            <StateIcon
                $style={{ display: ['block', null, null, null, null, 'none'] }}
                size="16px"
                state="reset"
                onClick={() => resetNavigation()}
            />
        </Wrapper>
    );
};

PrimaryMenuHeading.propTypes = {
    resetNavigation: PropTypes.func,
    text: PropTypes.string,
};

export default PrimaryMenuHeading;
