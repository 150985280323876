import fm from 'format-message';
import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import FeaturedItems from 'components/Header/Navigation/PrimaryMenu/FeaturedItems';
import OverlayHeading from 'components/Header/OverlayHeading';
import ThemeButton from 'components/buttons/ThemeButton';
import ArrowIcon from 'components/icons/ArrowIcon';
import PrimaryMenuBackdrop from './PrimaryMenuBackdrop';

const Wrapper = styled('div', ({ $isActive, $level }) => ({
    position: 'fixed',
    height: '100%',
    left: 0,
    top: 0,
    visibility: $isActive ? 'visible' : 'hidden',
    width: '100vw',
    transform: 'none',
    zIndex: `calc(var(--zindex-header) + 5 + ${$level})`, // All menus are located on top each other rendered in the dom. This makes the levels stack in correct order in mobile.
    [media.min['desktop.sm']]: {
        transform: 'translate3d(0, 0, 0)',
        left: `calc(var(--width-overlays) * ${$level} + (4px * ${$level}) )`, // 4px added to each menu level to set the gap between them.
    },
}));

const InnerWrapper = styled('div', ({ $level }) => ({
    height: 'calc(100% - 8px)',
    position: 'relative',
    maxWidth: 'var(--width-overlays)',
    zIndex: `calc(var(--zindex-header) + 7 + ${$level})`,
}));

const Section = styled('div', ({ $level }) => ({
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: `calc(var(--zindex-header) + 8 + ${$level})`,

    '::after': {
        position: 'absolute',
        right: 0,
        bottom: 0,
        display: 'block',
        content: '',
        width: '100%',
        height: '100%',
        zIndex: '-1',
    },

    [media.min['desktop.sm']]: {
        left: 0,
        width: '100%',
    },
}));

const Nav = styled('section', ({ $level }) => ({
    backgroundColor: 'var(--color-bg-secondary)',
    borderRadius: '16px',
    color: 'var(--color-text-navigation)',
    height: '100%',
    overflowY: 'auto',
    width: 'calc(100% - 8px)',
    zIndex: `calc(var(--zindex-header) + 9 + ${$level})`,

    [media.min['desktop.sm']]: {
        borderRadius: '8px',
    },
}));

const SubMenuLevel = ({
    featuredItems = [],
    featuredLabel = '',
    isActive = false,
    level,
    menuValue = {},
    parentLabel = '',
    parentLink = {},
    resetNavigation,
    setMenuValue,
    submenus = [],
}) => {
    const [css] = useStyletron();
    const hasFeaturedItems = featuredItems.length > 0;
    const firstLevel = level === 1;

    return (
        <Wrapper $isActive={isActive} $level={level}>
            <PrimaryMenuBackdrop
                $isOpen={isActive}
                $zIndex={`calc(var(--zindex-header) + (6 + ${level}))`}
                onClick={() => resetNavigation()}
            />

            <InnerWrapper $level={level}>
                <Section $isActive={isActive} $level={level}>
                    <Nav $level={level}>
                        <OverlayHeading
                            firstLevel={firstLevel}
                            menuValue={menuValue}
                            resetNavigation={resetNavigation}
                            setMenuValue={setMenuValue}
                            text={parentLabel}
                        />
                        {hasFeaturedItems && (
                            <FeaturedItems $style={{ padding: '0 8px' }} items={featuredItems} label={featuredLabel} />
                        )}
                        <div
                            className={css({
                                display: 'flex',
                                marginTop: '8px',
                                marginBottom: '24px',
                                padding: '0 8px',
                            })}
                        >
                            <ThemeButton
                                isVolatile
                                theme="link"
                                $style={{
                                    width: 'auto',
                                    display: 'flex',
                                    padding: '6px 12px',
                                    transitionDuration: null,
                                    transitionProperty: null,
                                    transitionTimingFunction: null,
                                }}
                                fontKeys={['Primary/20-120_-1']}
                                as={Link}
                                to={parentLink?.url}
                            >
                                {fm('See all')}
                            </ThemeButton>
                        </div>
                        <ul className={css({ display: 'flex', flexFlow: 'column', gap: '12px 0', padding: '0 8px' })}>
                            {submenus?.map((levelTwoItem, index) => {
                                const subMenuIsActive = menuValue.current === levelTwoItem._uid;
                                const hasSubmenu = levelTwoItem.submenus?.length > 0;

                                return (
                                    <li key={`${index}_${levelTwoItem._uid}`}>
                                        <ThemeButton
                                            isVolatile
                                            theme="link"
                                            $style={{
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: '6px 12px',
                                                transitionDuration: null,
                                                transitionProperty: null,
                                                transitionTimingFunction: null,
                                            }}
                                            fontKeys={['Miscellaneous/24_100_-2']}
                                            as={Link}
                                            to={levelTwoItem?.link?.url}
                                            onClick={e => {
                                                if (hasSubmenu) {
                                                    e.preventDefault();
                                                    setMenuValue({
                                                        current: hasSubmenu ? levelTwoItem._uid : null,
                                                        prev: menuValue.current,
                                                    });
                                                } else {
                                                    resetNavigation();
                                                }
                                            }}
                                        >
                                            {levelTwoItem.label}
                                            {hasSubmenu && <ArrowIcon />}
                                        </ThemeButton>
                                        <SubMenuLevel
                                            featuredItems={levelTwoItem.featuredItems}
                                            featuredLabel={levelTwoItem.featuredLabel}
                                            id={levelTwoItem._uid}
                                            isActive={subMenuIsActive}
                                            level={level + 1}
                                            menuValue={menuValue}
                                            parentLabel={levelTwoItem.label}
                                            parentLink={levelTwoItem.link}
                                            resetNavigation={resetNavigation}
                                            setMenuValue={setMenuValue}
                                            subMenuIsActive={subMenuIsActive}
                                            submenus={levelTwoItem.submenus}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </Nav>
                </Section>
            </InnerWrapper>
        </Wrapper>
    );
};

SubMenuLevel.propTypes = {
    featuredItems: PropTypes.array,
    featuredLabel: PropTypes.string,
    isActive: PropTypes.bool,
    level: PropTypes.number,
    menuValue: PropTypes.object,
    parentLabel: PropTypes.string,
    parentLink: PropTypes.object,
    resetNavigation: PropTypes.func,
    setMenuValue: PropTypes.func,
    submenus: PropTypes.array,
};

export default SubMenuLevel;
