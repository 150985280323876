import { BackgroundOverlay, RawSplitData } from '@activebrands/core-web/libs/storyblok/storyblok';
import { RawBackgroundData } from './transform-flexible-background';

/**
 * Will extract media overlay information from the background.
 * Done seperatly from transform as BackgroundSplit is handeled as one background instead of two in this function.
 */

const extractBackgroundOverlay = (flexibleBackground: RawBackgroundData | RawSplitData): BackgroundOverlay => {
    const noOverlay = { overlay: undefined, overlayOpacity: undefined };

    switch (flexibleBackground?.component) {
        case 'backgroundBynder':
        case 'backgroundImage':
        case 'backgroundSplit':
        case 'backgroundVideo':
            return {
                overlay: flexibleBackground.overlay || 'none',
                overlayOpacity: flexibleBackground.overlayOpacity || '0.2',
            };

        case 'backgroundColor':
            return noOverlay;

        default:
            return noOverlay;
    }
};

export default extractBackgroundOverlay;
