import { ProductPrice } from '@activebrands/core-web/types/product';

const getCampaignPrice = (productId: string, campaignPricelist: Record<string, ProductPrice>): ProductPrice | null => {
    if (campaignPricelist && campaignPricelist[productId]) {
        return campaignPricelist[productId];
    }

    return null;
};

export default getCampaignPrice;
