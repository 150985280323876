/**
 * The header can have three different themes before scrolling on the page. After scroll, it always switches to default.
 * The logic for which theme to be chosen can be found in src/components/Heeader/getHeaderTheme.
 * In this file there is the option to set more themes than Default. These will not change if the page theme changes.
 * All themes look like default in mobile and tablet.
 *
 * Themes:
 *  * Default
 *  * TransparentBlack
 *  * TransparentWhite
 *
 */

const headerThemes = {
    // Static theme - not affected by site theme
    'transparentBlack': {
        '--color-bg-header': ['var(--color-palette-neutral-200)', null, null, null, null, 'transparent'],
        '--color-bg-search-header': ['var(--color-palette-off-white)', null, null, null, null, 'var(--color-bg)'],
        '--color-border-search-header': [
            'var(--color-palette-off-black)',
            null,
            null,
            null,
            null,
            'var(--color-border)',
        ],
        '--color-text-header': [
            'var(--color-palette-off-black)',
            null,
            null,
            null,
            null,
            'var(--color-palette-off-black)',
        ],
        '--color-text-search-header': ['var(--color-palette-off-black)', null, null, null, null, 'var(--color-text)'],
        '--color-top-logo-mobile': ['var(--color-palette-off-black)', null, null, null, null, 'var(--color-text)'],
    },
    // Static theme - not affected by site theme
    'transparentWhite': {
        '--color-bg-header': ['var(--color-palette-neutral-200)', null, null, null, null, 'transparent'],
        '--color-bg-search-header': [
            'var(--color-palette-off-white)',
            null,
            null,
            null,
            null,
            'var(--color-palette-neutral-200)',
        ],
        '--color-border-search-header': [
            'var(--color-palette-off-black)',
            null,
            null,
            null,
            null,
            'var(--color-border-inverted)',
        ],
        '--color-text-header': [
            'var(--color-palette-off-black)',
            null,
            null,
            null,
            null,
            'var(--color-palette-off-white)',
        ],
        '--color-text-search-header': ['var(--color-palette-off-black)', null, null, null, null, 'var(--color-text)'],
        '--color-top-logo-mobile': 'var(--color-palette-off-white)',
    },
    'checkout': {
        '--color-bg-header': 'var(--color-palette-neutral-200)',
        '--color-text-header': 'var(--color-palette-off-black)',
        '--color-text-search-header': ['var(--color-palette-off-black)', null, null, null, null, 'var(--color-text)'],
        '--color-top-logo-mobile': ['var(--color-palette-off-black)', null, null, null, null, 'var(--color-text)'],
    },
    // Dynamic theme - affected by site theme
    // This is set for each theme in src/config/branding/themes
    'default': {},
};

export default headerThemes;
