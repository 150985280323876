import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';
import StateIcon from 'components/icons/StateIcon';

const ArrowButton = ({
    $style = {},
    children,
    direction = 'right',
    iconSize = '18px',
    state = 'arrow-right',
    theme = 'default',
    iconStyling,
    ...rest
}) => (
    <ThemeButton
        $style={{
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            ...$style,
        }}
        theme={theme}
        {...rest}
    >
        {direction === 'left' && (
            <StateIcon $style={{ marginRight: '8px', ...iconStyling }} size={iconSize} state={state} />
        )}
        {children}
        {direction === 'right' && (
            <StateIcon $style={{ marginLeft: '8px', ...iconStyling }} size={iconSize} state={state} />
        )}
    </ThemeButton>
);

ArrowButton.propTypes = {
    $style: PropTypes.object,
    chevronSize: PropTypes.number,
    children: PropTypes.node,
    direction: PropTypes.oneOf(['arrow-left', 'arrow-right', 'left', 'right']),
    iconSize: PropTypes.string,
    iconStyling: PropTypes.object,
    isRound: PropTypes.bool,
    state: PropTypes.string,
    theme: PropTypes.oneOf(['default', 'inverted', 'beige']),
};

export default ArrowButton;
