import { useRef, useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import headerThemes from 'config/branding/header-themes';
import useHeaderNavigationQuery from 'queries/useHeaderNavigationQuery';
import Link from '@activebrands/core-web/components/Link';
import AnimatedOverlay from '@activebrands/core-web/components/overlays/AnimatedOverlay';
import media from '@activebrands/core-web/config/media';
import { StateProvider } from '@activebrands/core-web/contexts/StateContext';
import { useDidUpdate } from '@activebrands/core-web/hooks/lifecycle';
import useEvent from '@activebrands/core-web/libs/Events/useEvent';
import overlay, { useOverlaySubscriber } from '@activebrands/core-web/libs/overlay';
import scroll from '@activebrands/core-web/libs/scroll';
import { styled } from '@activebrands/core-web/libs/styletron';
import { isIOS } from '@activebrands/core-web/utils/constants';
import loadable from '@loadable/component';
import { useLocation } from '@reach/router';
import AuthLink from 'components/Header/AuthLink';
import Logotype from 'components/Header/Logotype';
import Navigation from 'components/Header/Navigation';
import PrimaryLevelOneDesktop from 'components/Header/Navigation/PrimaryMenu/PrimaryLevelOneDesktop';
import SecondaryLevelOneDesktop from 'components/Header/Navigation/SecondaryMenu/SecondaryLevelOneDesktop';
import OverlayToggler from 'components/Header/OverlayToggler';
import SearchInput from 'components/Header/SearchInput';
import BasketCounterIcon from 'components/icons/BasketCounterIcon';
import CloseIcon from 'components/icons/CloseIcon';
import MenuIcon from 'components/icons/MenuIcon';
import SearchIcon from 'components/icons/SearchIcon';
import StateIcon from 'components/icons/StateIcon';
import Paragraph from 'components/text/Paragraph';
import { useStyletron } from 'styletron-react';
import CloseOverlay from './CloseButton';

const SearchOverlay = loadable(() => import('components/Header/SearchOverlay'));
const BasketOverlay = loadable(() => import('components/Header/BasketOverlay'));

const HeaderElement = styled('header', {
    alignItems: 'center',
    backgroundColor: 'var(--color-bg-header)',
    borderRadius: '16px 16px 0px 0px',
    bottom: 0,
    color: 'var(--color-text-header)',
    display: 'flex',
    height: 'var(--height-header)',
    justifyContent: 'space-between',
    paddingLeft: 'var(--margin-content)',
    paddingRight: 'var(--margin-content)',
    position: 'fixed',
    transition: 'color, background-color var(--duration-fast) var(--ease)',
    width: 'calc(100% - 8px)',
    zIndex: 'var(--zindex-header)',

    [media.min['desktop.sm']]: {
        position: 'fixed',
        top: 0,
        bottom: 'auto',
        borderRadius: '0 0 8px 8px',

        ':hover': {
            backgroundColor: 'var(--color-bg-secondary)',
            color: 'var(--color-text)',
        },
    },
});

const IconWrapper = styled('div', {
    display: 'flex',
    gap: '8px',
    [media.min['desktop.sm']]: {
        gap: 'unset',
    },
});

const Header = ({
    alternateHrefs = [],
    isCheckout = false,
    backToSiteLabel = '',
    hasBanner = false,
    previewDataOverride,
    theme = 'default',
}) => {
    const headerRef = useRef(null);
    const location = useLocation();
    const [css] = useStyletron();
    const headerConfigContent = useHeaderNavigationQuery();
    const pageData = previewDataOverride || headerConfigContent;
    const { primaryMenu, secondaryMenu = [] } = pageData;
    const [menuValue, setMenuValue] = useState({ current: null, prev: null });

    const openOverlays = useOverlaySubscriber();

    const headerOverlays = [
        'account-navigation',
        'basket',
        'category-facet-groups',
        'category-sort-options',
        'navigation',
        'quickshop',
        'search-facet-groups',
        'search-sort-options',
        'search',
        'shop-the-look',
        'sizeguide',
        'site-selector',
        'unauthenticated',
        'wrong-site-overlay',
    ];

    const headerOverlayIsOpen = headerOverlays.includes(openOverlays[0]);

    let isDefaultTheme = false;

    //These are only used by scroll
    const setDefaultStyleProperties = () => {
        isDefaultTheme = true;
        headerRef.current.style.setProperty('--color-bg-header', 'var(--color-bg-secondary)');
        headerRef.current.style.setProperty('--color-search-header', 'var(--color-bg-secondary)');
        headerRef.current.style.setProperty('--color-text-search-header', 'var(--color-text)');
        headerRef.current.style.setProperty('--color-text-header', 'var(--color-text)');
    };

    const resetHeaderStyleProperties = () => {
        isDefaultTheme = false;
        headerRef.current.style.removeProperty('--color-bg-header');
        headerRef.current.style.removeProperty('--color-search-header');
        headerRef.current.style.removeProperty('--color-text-search-header');
        headerRef.current.style.removeProperty('--color-text-header');
    };

    const resetNavigation = () => {
        const navigation = document.getElementById('navigation');
        navigation.scrollTop = 0;
        setMenuValue({ current: null, prev: null });
        overlay.close('navigation');
    };

    useDidUpdate(() => {
        resetHeaderStyleProperties();
    }, [location.pathname]);

    useEvent('OVERLAY.STATE_CHANGE', ({ type, ids }) => {
        const someHeaderOverlayIsOpen = type === 'open' && headerOverlays.includes(ids[0]);
        const scrollYPosition = document.documentElement.scrollTop;

        if (ids.length === 0) {
            return;
        }

        if (someHeaderOverlayIsOpen) {
            setDefaultStyleProperties();
        } else if (scrollYPosition === 0) {
            resetHeaderStyleProperties();
        }
    });

    useEvent(
        'BODY.SCROLL_DOWN',
        position => {
            const scrollDownThreshold = position.y > 0;
            if (scrollDownThreshold && !isDefaultTheme) {
                setDefaultStyleProperties();
            }
        },
        [hasBanner]
    );

    useEvent(
        'BODY.SCROLL_UP',
        position => {
            const scrollUpThreshold = position.y > 0;
            if (isIOS && scroll.isLocked()) {
                return;
            }

            if (!scrollUpThreshold && isDefaultTheme) {
                resetHeaderStyleProperties();
            }
        },
        [hasBanner]
    );

    return (
        <StateProvider key="SearchProvider">
            <div className={css({ display: 'flex', justifyContent: 'center' })}>
                <HeaderElement $style={headerThemes[isCheckout ? 'checkout' : theme]} ref={headerRef}>
                    {isCheckout ? (
                        <Link className={css({ display: 'flex', flexDirection: 'row' })} to="/">
                            <div className={css({ display: 'flex', alignItems: 'center', gap: '8px' })}>
                                <StateIcon size="16px" state="arrow-left" />
                                <Paragraph fontKeys="Miscellaneous/14_100">{backToSiteLabel}</Paragraph>
                            </div>
                        </Link>
                    ) : (
                        <div
                            className={css({
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            })}
                        >
                            <OverlayToggler overlayId="navigation">
                                {isOpen =>
                                    isOpen ? (
                                        <CloseIcon
                                            $style={{
                                                width: ['24px', null, null, null, null, '16px'],
                                                height: ['24px', null, null, null, null, '16px'],
                                            }}
                                        />
                                    ) : (
                                        <MenuIcon
                                            $style={{
                                                width: ['24px', null, null, null, null, '16px'],
                                                height: ['24px', null, null, null, null, '16px'],
                                            }}
                                        />
                                    )
                                }
                            </OverlayToggler>
                            <PrimaryLevelOneDesktop
                                items={primaryMenu}
                                menuValue={menuValue}
                                resetNavigation={resetNavigation}
                                setMenuValue={setMenuValue}
                            />
                            <Navigation
                                alternateHrefs={alternateHrefs}
                                menuValue={menuValue}
                                pageData={pageData}
                                resetNavigation={resetNavigation}
                                setMenuValue={setMenuValue}
                            />
                            <OverlayToggler
                                $style={{ display: ['block', null, null, null, null, 'none'], marginRight: 'auto' }}
                                overlayId="search"
                                type="button"
                            >
                                <SearchIcon
                                    $style={{
                                        display: 'block',
                                        width: ['22px', null, null, null, null, '16px'],
                                        height: ['22px', null, null, null, null, '16px'],
                                    }}
                                />
                            </OverlayToggler>
                        </div>
                    )}
                    <Logotype $style={{ display: ['none', null, null, null, null, 'inline-flex'] }} />
                    {headerOverlayIsOpen ? (
                        <CloseOverlay
                            $style={{ display: ['flex', null, null, 'none'] }}
                            handleOnClick={() => {
                                resetNavigation();
                                overlay.closeAll();
                            }}
                        />
                    ) : !isCheckout ? (
                        <Logotype $style={{ display: ['inline-flex', null, null, null, null, 'none'] }} width="80px" />
                    ) : null}
                    {isCheckout && (
                        <>
                            <Paragraph
                                $style={{
                                    display: ['none', null, null, null, null, 'flex'],
                                    color: 'var(--color-text-subtle)',
                                }}
                                fontKeys="Miscellaneous/14_100"
                            >
                                {fm('Checkout')}
                            </Paragraph>
                            <Logotype
                                $style={{
                                    display: ['inline-flex', null, null, null, null, 'none'],
                                    right: 0,
                                    left: 'unset',
                                    transform: 'translate(0%, -45%)',
                                    paddingRight: 'var(--margin-content)',
                                }}
                                width="80px"
                            />
                        </>
                    )}
                    {!isCheckout && (
                        <div className={css({ display: 'flex', justifyContent: 'center', alignItems: 'center' })}>
                            <SecondaryLevelOneDesktop
                                items={secondaryMenu}
                                menuValue={menuValue}
                                resetNavigation={resetNavigation}
                                setMenuValue={setMenuValue}
                            />
                            <SearchInput
                                $style={{
                                    display: ['none', null, null, null, null, 'flex'],
                                    margin: '0 16px',
                                }}
                                previewDataOverride={previewDataOverride}
                            />
                            <IconWrapper>
                                <AuthLink />
                                <OverlayToggler overlayId="basket">
                                    <BasketCounterIcon />
                                </OverlayToggler>
                            </IconWrapper>
                        </div>
                    )}
                </HeaderElement>
            </div>
            <AnimatedOverlay
                lockScroll
                $style={{
                    top: ['0', null, null, null, null, 'var(--height-header)'],
                    height: ['100vh', null, null, null, null, '100%'],
                }}
                component={BasketOverlay}
                id="basket"
            />
            <AnimatedOverlay lockScroll component={SearchOverlay} id="search" />
        </StateProvider>
    );
};

Header.propTypes = {
    alternateHrefs: PropTypes.array,
    backToSiteLabel: PropTypes.string,
    hasBanner: PropTypes.bool,
    isCheckout: PropTypes.bool,
    previewDataOverride: PropTypes.object,
    theme: PropTypes.string.isRequired,
};

export default Header;
