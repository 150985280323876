/* eslint-disable react/require-default-props */
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import useScrollLock from '@activebrands/core-web/libs/scroll';
import { scrollTo } from '@grebban/utils/function/scroll';
import { Link as GatsbyLink } from 'gatsby';

/**
 * @params {string} [to] - Internal or extranal href.
 * @param {boolean} [isVolatile=false] - If true and to is undefined, render a 'a' tag without href attr.
 */

const Link = forwardRef(({ isVolatile = false, to, onClick = () => null, ...rest }, ref) => {
    if (!to && !isVolatile) {
        return rest.children;
    }

    const { unlockScroll } = useScrollLock();
    // If the link begins with /, ? or #
    const isInternal = /^[/?#](?!\/)/.test(to);

    // Use Gatsby Link for internal links, and <a> for others
    if (isInternal) {
        return (
            <GatsbyLink
                to={to}
                {...rest}
                onClick={e => {
                    onClick(e);

                    // Handle anchor tags on the same page
                    // Note: Anchor tag links on new pages gets handled inside gatsby-browser
                    const hash = to.match(/#([\w-])+/)?.[0];
                    const element = document.querySelector(hash);

                    // Unlock scroll in case overlay is open when changeing route
                    unlockScroll();

                    if (element) {
                        e.preventDefault();
                        const topOffset = element.getBoundingClientRect().top;
                        scrollTo({
                            top: document.documentElement.scrollTop + topOffset,
                            eventDetails: { hash },
                        });
                    }
                }}
            />
        );
    }

    return (
        <a
            href={to}
            ref={ref}
            rel={rest.rel || 'noopener'}
            target={rest.target || '_blank'}
            onClick={onClick}
            {...rest}
        />
    );
});

Link.displayName = 'Link';

Link.propTypes = {
    isVolatile: PropTypes.bool,
    onClick: PropTypes.func,
    to: PropTypes.string,
};

export default Link;
