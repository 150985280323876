import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const CheckIcon = ({ color = 'currentColor', width = '16px', height = 'auto', $style = {}, ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox="0 0 16 16" {...rest}>
            <path d="m1 7 6 6.5L15 1" stroke={color} strokeLinecap="round" />
        </SVG>
    );
};

CheckIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default CheckIcon;
