import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const ArrowIcon = ({ color = 'currentColor', width = '16px', height = 'auto', $style = {}, ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox="0 0 12 12" {...rest}>
            <path d="M12 5.50003L1.27157e-06 5.5L-1.27157e-06 6.5L12 6.50003L12 5.50003Z" fill={color} />
            <path
                d="M11.5 6.24915L11.8239 6.63006L12 6.48031V6.24915H11.5ZM11.5 5.74915H12V5.51792L11.8238 5.36817L11.5 5.74915ZM6.82389 10.8815L11.8239 6.63006L11.1761 5.86823L6.17611 10.1197L6.82389 10.8815ZM12 6.24915V5.74915H11V6.24915H12ZM11.8238 5.36817L6.82381 1.11841L6.17619 1.88037L11.1762 6.13012L11.8238 5.36817Z"
                fill={color}
            />
        </SVG>
    );
};

ArrowIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default ArrowIcon;
