import fm from 'format-message';
import PropTypes from 'prop-types';
import CheckIcon from 'components/icons/CheckIcon';
import Paragraph from 'components/text/Paragraph';

const SuccessMessage = ({ $style = {} }) => (
    <Paragraph
        $style={{ display: 'flex', alignItems: 'center', gap: '8px', ...$style }}
        fontKeys="Miscellaneous/12_100_500"
    >
        {fm('Added')} <CheckIcon />
    </Paragraph>
);

SuccessMessage.propTypes = {
    $style: PropTypes.object,
};

export default SuccessMessage;
