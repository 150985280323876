import fm from 'format-message';
import PropTypes from 'prop-types';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';
import LogotypeIcon from 'components/icons/LogotypeIcon';
import StateIcon from 'components/icons/StateIcon';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    position: 'relative',
    padding: '8px 20px 8px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px',

    [media.min['desktop.sm']]: {
        marginBottom: '16px',
        padding: 'unset',
    },
});

const DesktopMenuHeading = styled('div', {
    display: 'none',

    [media.min['desktop.sm']]: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '20px 20px',
    },
});

const MobileMenuHeading = styled('div', {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '0px 20px 16px 20px',

    [media.min['desktop.sm']]: {
        display: 'none',
    },
});

const OverlayHeading = ({ firstLevel, menuValue, resetNavigation = () => null, setMenuValue, text = '' }) => {
    const [css] = useStyletron();

    return (
        <>
            <Wrapper>
                <ThemeButton
                    $style={{
                        display: ['flex', null, null, null, null, 'none'],
                        marginRight: '8px',
                        transitionDuration: null,
                        transitionProperty: null,
                        transitionTimingFunction: null,
                    }}
                    fontKeys="Miscellaneous/14_100"
                    theme="default"
                    onClick={() =>
                        setMenuValue({ current: firstLevel ? null : menuValue.prev, prev: menuValue.current })
                    }
                >
                    <StateIcon
                        duration={null}
                        timingFunction={null}
                        $style={{ marginRight: '8px' }}
                        size="16px"
                        state="arrow-left"
                    />
                    {fm('Back')}
                </ThemeButton>

                <DesktopMenuHeading>
                    <Paragraph
                        $style={{ color: 'var(--color-text-subtle)' }}
                        fontKeys={['Miscellaneous/16_100_-1', null, 'Miscellaneous/12_100']}
                    >
                        {text}
                    </Paragraph>
                </DesktopMenuHeading>

                <div
                    className={css({
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    })}
                >
                    <LogotypeIcon $style={{ display: ['block', null, 'none'] }} width="80px" />
                </div>

                <StateIcon
                    duration={null}
                    timingFunction={null}
                    $style={{ display: ['block', null, 'none'] }}
                    size="16px"
                    state="reset"
                    onClick={() => resetNavigation()}
                />
            </Wrapper>
            <MobileMenuHeading>
                <Paragraph $style={{ color: 'var(--color-text-subtle)' }} fontKeys="Miscellaneous/12_100">
                    {text}
                </Paragraph>
            </MobileMenuHeading>
        </>
    );
};

OverlayHeading.propTypes = {
    firstLevel: PropTypes.bool,

    menuValue: PropTypes.object,
    resetNavigation: PropTypes.func,
    setMenuValue: PropTypes.func,
    text: PropTypes.string,
};

export default OverlayHeading;
