import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const MailIcon = ({ color = 'currentColor', width = '16px', height = 'auto', $style, ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox="0 0 16 16" {...rest}>
            <path
                d="M0.5 12.5V3.5C0.5 2.94772 0.947715 2.5 1.5 2.5H14.5C15.0523 2.5 15.5 2.94771 15.5 3.5V12.5C15.5 13.0523 15.0523 13.5 14.5 13.5H1.5C0.947715 13.5 0.5 13.0523 0.5 12.5Z"
                stroke={color}
            />
            <path d="M1 3L7.29289 9.29289C7.68342 9.68342 8.31658 9.68342 8.70711 9.29289L15 3" stroke={color} />
            <path d="M1 13L5.5 7.5M10.5 7.5L15 13" stroke={color} />
        </SVG>
    );
};

MailIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default MailIcon;
