import PropTypes from 'prop-types';
import extractBackgroundOverlays from '@activebrands/core-web/libs/storyblok/utils/extract-background-overlays';
import transformFlexibleBackgrounds from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds';
import transformFlexibleBackgroundsFactors from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds-factors';
import transformFlexibleLink from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-link';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import transformLayout from '@activebrands/core-web/libs/storyblok/utils/transform-layout';
import Flexible from 'components/content-components/Flexible';

const contentAlignmentOptions = {
    'left-bottom': { x: 'left', y: 'bottom' },
    'left-top': { x: 'left', y: 'top' },
    'center-center': { x: 'center', y: 'center' },
};

const CallToActionDataTransformerBlock = ({
    content = {},
    isFourColumns = false,
    isHero = false,
    isThreeColumns = false,
    isTwoColumns = false,
}) => {
    const {
        anchorTag = [],
        backgroundDesktop = [],
        backgroundMobile = [],
        contentAlignment = 'left-bottom',
        heading = [],
        headingStyle = 'md',
        link = [],
        paragraph = [],
        tag = [],
        textColor,
    } = content;

    const { buttons, wrapperLink } = link?.length > 0 ? transformFlexibleLink(link[0]) : {};
    const alignment = contentAlignmentOptions[contentAlignment];

    const backgroundOverlays = extractBackgroundOverlays([backgroundMobile[0], null, null, null, backgroundDesktop[0]]);

    const layout = transformLayout({ isTwoColumns, isThreeColumns, isFourColumns });

    const transformedBackgrounds = transformFlexibleBackgrounds(
        [backgroundMobile[0], null, null, null, backgroundDesktop[0]],
        transformFlexibleBackgroundsFactors(layout)
    );

    const headingSettings = transformHeading({
        type: heading?.[0]?.type,
        defaultType: isHero ? 'h1' : isTwoColumns || isThreeColumns ? 'h4' : 'h3',
    });

    return (
        <Flexible
            background={transformedBackgrounds}
            backgroundLoading={isHero ? 'eager' : 'lazy'}
            backgroundOverlay={backgroundOverlays}
            buttons={buttons}
            contentHorizontalAlignment={alignment?.x}
            contentVerticalAlignment={alignment?.y}
            heading={heading?.[0]?.text}
            headingStyle={headingStyle}
            headingType={headingSettings.type}
            isHero={isHero}
            id={anchorTag?.[0]?.anchorTag}
            layout={layout}
            tag={tag?.[0]?.tag}
            text={paragraph?.[0]?.richText}
            textColor={textColor}
            wrapperLink={wrapperLink}
        />
    );
};

CallToActionDataTransformerBlock.propTypes = {
    content: PropTypes.object.isRequired,
    isFourColumns: PropTypes.bool,
    isHero: PropTypes.bool,
    isThreeColumns: PropTypes.bool,
    isTwoColumns: PropTypes.bool,
};

export default CallToActionDataTransformerBlock;
