import PropTypes from 'prop-types';
import { transformButton } from '@activebrands/core-web/libs/storyblok/utils/transform-buttons';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import transformArticleCardData from '@activebrands/core-web/utils/transform-article-card-data';
import ArticlePromotion from 'components/content-components/Promotional/ArticlePromotion';

const ArticlePromotionDataTransformerBlock = ({ content = {} }) => {
    const {
        anchorTag = [],
        articles = [],
        button = [],
        heading = [],
        paragraph = [],
        layout = 'slider',
        tag = [],
    } = content;

    const headingSettings = transformHeading({ type: heading?.[0]?.type, defaultType: 'h3' });

    if (!articles.length) {
        return null;
    }

    return (
        <ArticlePromotion
            articles={articles.map(article => transformArticleCardData(article))}
            button={button?.[0] ? transformButton(button[0]) : {}}
            columnSizes={[6, null, null, null, null, 3]}
            heading={heading?.[0]?.text}
            headingType={headingSettings.type}
            imageSettings={{ width: [200, 300, 400, 500, 600], sizes: ['48vw', null, null, '33vw'] }}
            layout={layout}
            id={anchorTag?.[0]?.anchorTag}
            slidesPerView={[1.3, null, 3, null, null, 3]}
            tag={tag[0]?.tag}
            text={paragraph?.[0]?.richText}
        />
    );
};

ArticlePromotionDataTransformerBlock.propTypes = {
    content: PropTypes.object.isRequired,
};

export default ArticlePromotionDataTransformerBlock;
