import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const AccountIcon = ({
    $style = {},
    color = 'currentColor',
    fill = 'none',
    height = 'auto',
    width = '16px',
    ...rest
}) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill={fill} viewBox="0 0 24 24" {...rest}>
            <circle cx="12" cy="6" r="5.5" stroke={color} />
            <path d="M23 24v-5a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v5" stroke={color} />
        </SVG>
    );
};

AccountIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    fill: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default AccountIcon;
