import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const BellIcon = ({ color = 'currentColor', width = '13px', height = 'auto', $style, ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox="0 0 13 12" {...rest}>
            <path
                d="M2.95 4.625a3.25 3.25 0 1 1 6.5 0v.085c0 1.513.475 2.987 1.359 4.214l.407.565a.036.036 0 0 1 .002.003.018.018 0 0 1-.004.008H1.186l-.003-.004a.017.017 0 0 1-.001-.004s0-.002.002-.003l.407-.565A7.212 7.212 0 0 0 2.95 4.71v-.085Z"
                stroke={color}
            />
            <path d="M5.825 1.25V.875a.375.375 0 0 1 .75 0v.375" stroke={color} strokeLinejoin="round" />
            <path d="M4.7 11h3a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1Z" fill={color} />
            <path d="M.7 4v-.45A3.11 3.11 0 0 1 3.2.5M11.7 4v-.45A3.11 3.11 0 0 0 9.2.5" stroke={color} />
        </SVG>
    );
};

BellIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default BellIcon;
