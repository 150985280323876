import { graphql, useStaticQuery } from 'gatsby';

const useFooterQuery = () => {
    const { application } = useStaticQuery(
        graphql`
            {
                application {
                    data {
                        config {
                            footerConfig {
                                items {
                                    label
                                    menuItems {
                                        ...LinkItem
                                    }
                                }
                                informationParagraphs {
                                    ...Paragraph
                                }
                                informationHeading {
                                    ...Heading
                                }
                                footerContent
                                featuredLinks {
                                    ...LinkItem
                                }
                                featuredLinksLabel
                                copywriteText
                            }
                        }
                    }
                }
            }
        `
    );

    const content = application.data.config.footerConfig || {};

    return {
        copywriteText: content.copywriteText,
        featuredLinks: content.featuredLinks,
        featuredLinksLabel: content.featuredLinksLabel,
        footerContent: content.footerContent,
        informationHeading: content.informationHeading,
        informationParagraphs: content.informationParagraphs,
        items: content.items,
    };
};

export default useFooterQuery;
