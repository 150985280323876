import PropTypes from 'prop-types';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import Button from 'components/buttons/Button';
import { getButtonTheme, sizes, themes } from './theme-button-config';

const ThemeButton = ({
    $style = {},
    size = 'md',
    theme = 'default',
    fontKeys = 'Miscellaneous/18_100_-1',
    ...rest
}) => {
    const textStyles = responsiveTextStyles(fontKeys);

    return (
        <Button
            $style={{
                borderRadius: '100px',
                borderStyle: 'solid',
                borderWidth: '1px',
                display: 'inline-block',
                textAlign: 'center',
                transitionDuration: 'var(--duration-fast)',
                transitionProperty: 'all',
                transitionTimingFunction: 'var(--ease)',
                ...textStyles,
                ...$style,
            }}
            availableSizes={sizes}
            size={size}
            theme={themes[getButtonTheme(theme)]}
            {...rest}
        />
    );
};

ThemeButton.propTypes = {
    $style: PropTypes.object,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    theme: PropTypes.oneOf(['default', 'inverted', 'beige', 'link', 'black']),
};

export default ThemeButton;
