import PropTypes from 'prop-types';
import extractBackgroundOverlays from '@activebrands/core-web/libs/storyblok/utils/extract-background-overlays';
import { transformButton, transformButtons } from '@activebrands/core-web/libs/storyblok/utils/transform-buttons';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import Category from 'components/content-components/Category';

const CategoryDataTransformerBlock = ({ content = {} }) => {
    const {
        anchorTag = [],
        backgroundDesktop = [],
        backgroundMobile = [],
        button = [],
        buttons = [],
        heading = [],
        headingStyle = 'md',
        subHeadingLabel = '',
        tag = [],
        textLabel = '',
        textColor = [],
    } = content;

    const backgroundOverlays = extractBackgroundOverlays([backgroundMobile[0], null, null, null, backgroundDesktop[0]]);

    const transformedButton = button?.[0] ? transformButton(button?.[0]) : {};
    const transformedButtons = buttons.length > 0 ? transformButtons(buttons) : [];

    const headingSettings = transformHeading({
        type: heading[0]?.type,
        defaultType: 'h3',
    });

    return (
        <Category
            backgroundDesktop={backgroundDesktop}
            backgroundMobile={backgroundMobile}
            backgroundOverlay={backgroundOverlays}
            button={transformedButton}
            buttons={transformedButtons}
            heading={heading[0]?.text}
            headingType={headingSettings.type}
            headingStyle={headingStyle}
            id={anchorTag?.[0]?.anchorTag}
            subHeading={subHeadingLabel}
            tag={tag[0]?.tag}
            text={textLabel}
            textColor={textColor}
        />
    );
};

CategoryDataTransformerBlock.propTypes = {
    content: PropTypes.object.isRequired,
};

export default CategoryDataTransformerBlock;
