import PropTypes from 'prop-types';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import StateIcon from 'components/icons/StateIcon';
import Heading from 'components/text/Heading';

const Wrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '56px',

    [media.min['desktop.sm']]: {
        height: '64px',
    },
});

const OverlayHeader = ({ $style = {}, headingText = '', overlayId = '' }) => {
    const [css] = useStyletron();

    if (!headingText && !overlayId) {
        return null;
    }

    return (
        <Wrapper $style={$style}>
            {headingText && (
                <Heading $style={{ marginLeft: '8px' }} fontKeys="Miscellaneous/14_100">
                    {headingText}
                </Heading>
            )}
            {overlayId && (
                <button
                    className={css({
                        alignItems: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        height: '40px',
                        justifyContent: 'center',
                        marginRight: '-4px',
                        width: '40px',
                    })}
                    type="button"
                    onClick={() => overlay.close(overlayId)}
                >
                    <StateIcon size="16px" state="close" />
                </button>
            )}
        </Wrapper>
    );
};

OverlayHeader.propTypes = {
    $style: PropTypes.object,
    headingText: PropTypes.string,
    overlayId: PropTypes.string,
};

export default OverlayHeader;
